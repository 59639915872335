// React
import React from "react";
import PropTypes from "prop-types";

// Components
import HireContractCard from "./HireContractCard";
import HireContractTerms from "./HireContractTerms";

// Material ui
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerText: {
    marginBottom: theme.spacing(0.5),
    opacity: 0.65,
  }
}));

export default function HireContractVersions(props) {
  const { hireContracts, contract } = props; 
  // business, client

  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      {hireContracts
        .map(hc => 
          <HireContractCard key={hc.uid} {...hc}>
            <Typography className={classes.headerText}>
              Terms
            </Typography>
            <HireContractTerms 
              currency={contract.currency}
              {...hc} 
            />
          </HireContractCard>
        )
      }
    </Box>
  );
}

HireContractVersions.propTypes = {
  contract: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};
