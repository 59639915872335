// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  textField: {
    "& input::-webkit-clear-button": {
      display: "none",
    },
    marginRight: theme.spacing(1)
  },
}));

function getValueWithZero(value) {
  if (value < 10) {
    return "0" + String(value);
  }
  return String(value);
}

function formatTime(date) {
  const day = getValueWithZero(date.getDate());
  const monthIndex = getValueWithZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = getValueWithZero(date.getHours());
  const minutes = getValueWithZero(date.getMinutes());
  return `${year}-${monthIndex}-${day}T${hours}:${minutes}`;
}

export default function SessionEditor(props) {
  const {uid, startedDate, endedDate, minutesBreak, description, onChange, isBillOwner} = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <TextField
            id="startTime-field"
            label="Start time"
            type="datetime-local"
            value={formatTime(startedDate)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => onChange("startedDate", event.target.value)}
            variant="outlined"
            disabled={Boolean(uid)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            id="endTime-field"
            label="End time"
            type="datetime-local"
            value={formatTime(endedDate)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => onChange("endedDate", event.target.value)}
            variant="outlined"
            disabled={Boolean(uid)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="break-field"
            label="Break in minutes"
            type="number"
            value={minutesBreak}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => onChange("minutesBreak", event.target.value)}
            variant="outlined"
            disabled={Boolean(uid)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description-field"
            label=""
            className={classes.textField}
            value={description || ""}
            placeholder="Briefly describe what you worked on"
            onChange={(event) => onChange("description", event.target.value)}
            variant="outlined"
            rows={5}
            multiline
            helperText={`${description ? 500 - description.length : 500} characters remaining`}
            disabled={!isBillOwner}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  )
}

SessionEditor.propTypes = {
  startedDate: PropTypes.instanceOf(Date).isRequired,
  endedDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};
