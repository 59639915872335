// React
import React from "react";
import PropTypes from "prop-types";

// Components
import TeamHiresField from "./TeamHiresField";
import DetailsField from "./DetailsField";
import FieldBlock from "./FieldBlock";
import ContractVersionCard from "./ContractVersionCard";
import SimpleDialog from "../../SimpleDialog";
import { FirebaseContext } from "../../Firebase";
import { AuthUserContext } from "../../Session";

// Material ui
// import {Box} from "@material-ui/core";
import {formatPriceToCents} from "../../../utils/formatting";

function sumArray(total, num) {
  return total + num;
}

export default function ContractVersionEditor(props) {

  const { 
    contract, 
    business, 
    team, 
    hireContracts, 
    details, 
    status, 
    versionNumber, 
    versionId, 
    handleStartEditing, 
    handleResetDraft, 
    handleStopContract, 
    disableActions, 
    becameStatusPendingBy
  } = props;

  const [newHireContracts, setNewHireContracts] = React.useState([...hireContracts]);
  const [newDetails, setNewDetails] = React.useState("");
  const [submittingDraft, setSubmittingDraft] = React.useState(false);
  
  const [errorMessage, setErrorMessage] = React.useState(null);
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  console.log('lll hireContracts', hireContracts);
  console.log('lll newHireContracts', newHireContracts);

  const handleCancel = () => {
    setNewHireContracts(hireContracts)
    setNewDetails("")
    handleResetDraft && handleResetDraft()
  };

  const handleSave = () => {

    setSubmittingDraft(true)

    console.log('lll handleSave newHireContracts', newHireContracts);


    const editedHireContracts = newHireContracts
      .filter(({ freelancerId, hourlyRate, monthlyBudget, salesTax }) => { 
        // Check if prev contractual terms for freelancerId got edited
        const prevValues = hireContracts
          .filter(pv => pv.freelancerId === freelancerId)[0];

        console.log("lll handleSave newHireContract", { freelancerId, hourlyRate, monthlyBudget, salesTax })
        console.log('lll handleSave prevValues', prevValues);


        if (prevValues) {
          const hourlyRateEdited = Boolean(hourlyRate !== prevValues.hourlyRate);
          const monthlyBudgetEdited = Boolean(monthlyBudget !== prevValues.monthlyBudget);
          const salesTaxEdited = Boolean(
            (salesTax.option !== prevValues.salesTax.option) ||
            (salesTax.rate !== prevValues.salesTax.rate) ||
            (salesTax.type !== prevValues.salesTax.type)
          );
          // const statusEdited = Boolean(status !== prevValues.status);
          return Boolean(hourlyRateEdited || monthlyBudgetEdited || salesTaxEdited);
        };

        // New: This is the first HireContract for the freelancer 
        return true;      
      });

    console.log('lll editedHireContracts', editedHireContracts);

    // Make contractVersions accesible via freelancerId key
    const hireContractsByFreelancer = {};
    const existingContracts = newHireContracts
      .filter(({ uid }) => Boolean(uid))
    for (let i = 0; i < existingContracts.length; i++) {
      const existingContract = existingContracts[i];
      hireContractsByFreelancer[existingContract.freelancerId] = existingContract.uid;
    };

    console.log("editedHireContracts lll", hireContractsByFreelancer)
    console.log("hireContractsByFreelancer lll", hireContractsByFreelancer)

    // Batch new edited HireContracts 
    var batch = firebase.firestore.batch();
    for (let i = 0; i < editedHireContracts.length; i++) {
      const ts = firebase.createTimestamp();
      const hireContract = editedHireContracts[i];
      const hireContractRef = firebase.hireContract();
      const hireContractId = hireContractRef.id;
      hireContractsByFreelancer[hireContract.freelancerId] = hireContractId;
      console.log("hireContractsByFreelancer 2 lll", hireContractsByFreelancer)

      const payload = {
        createdAt: ts,
        status: 'pending',
        freelancerId: hireContract.freelancerId,
        salesTax: hireContract.salesTax,
        serviceFee: hireContract.serviceFee,
        transactionFee: hireContract.transactionFee,
        contractId: contract.uid,
        teamId: contract.teamId,
        businessId: contract.businessId,
        currency: contract.currency,
        monthlyBudget: formatPriceToCents(hireContract.monthlyBudget),
        hourlyRate: formatPriceToCents(hireContract.hourlyRate),
      };
      batch.set(hireContractRef, payload);
    }

    // Commit batch
    batch.commit()
      .then((res) => {
        const ts = firebase.createTimestamp();
        const contractVersionPayload = {
          // If a contract is pending then any new version immediately is the applicable one
          // Else the active or stopped version remains applicable (until this verion is accepted)
          applicable: Boolean(contract.status === "pending"),
          ownerId: authUser.uid,
          createdAt: ts,
          lastUpdatedAt: ts,
          versionNumber,
          contractId: contract.uid,
          businessId: contract.businessId,
          teamId: contract.teamId,
          status: "pending",
          becameStatusPendingAt: ts,
          becameStatusPendingBy: authUser.uid,
          monthlyBudget: formatPriceToCents(
            newHireContracts
              .filter(({ status }) => status !== "stopped")
              .map(({ monthlyBudget }) => monthlyBudget || 0)
              .reduce(sumArray)
          ),
          hireContracts: hireContractsByFreelancer,
          details: newDetails || "",
        }
        handleResetDraft()  
        return firebase.contractVersions()
          .add(contractVersionPayload)
      })
      .then(() => {
        setSubmittingDraft(false)
        handleResetDraft()  
      })
  }

  const handleRevoke = () => {
    console.log("handleRevoke");
    firebase.contractVersion(versionId)
      .update({
        status: "revoked",
        becameStatusRevokedAt: firebase.createTimestamp(),
        becameStatusRevokedBy: authUser.uid,
      })
  };

  const hasValidPaymentMethod = () => {
    return new Promise((resolve, reject) => {
      if (business) {
        if (business.stripeCustomerId) {
          // Todo: Create a test payment to check if the default PM (still) works
          resolve()
        } else {
          const errorDesc = authUser.role === "manager"
            ? "In order to accept this contract you first have to setup a payment method in your business settings"
            : "The client has to add a payment method first in order for you to accept this contract"
          reject(errorDesc);
        }
      } else {
        resolve()
      }
    });
  };

  const handleActivate = () => {
    hasValidPaymentMethod()
      .then(() =>
        firebase.contractVersion(versionId)
          .update({
            lastUpdatedAt: firebase.createTimestamp(),
            becameStatusActiveAt: firebase.createTimestamp(),
            becameStatusActiveBy: authUser.uid,
            status: "active",
            applicable: true,
          })
      ).catch(errMessage => {
        setErrorMessage(errMessage)
      })
  }

  const handleReject = () => {
    firebase.contractVersion(versionId)
      .update({
        lastUpdatedAt: firebase.createTimestamp(),
        becameStatusRejectedAt: firebase.createTimestamp(),
        becameStatusRejectedBy: authUser.uid,
        status: "rejected",
      })
  }

  const handleResetErrorMessage = () => setErrorMessage(null);

  const handleModifyHireContracts = (editedIdx, editedHireContract) => {
    const updatedHireContracts = newHireContracts;
    updatedHireContracts[editedIdx] = editedHireContract;
    setNewHireContracts(updatedHireContracts);
  };
  
  const handleModifyVersion = (prop, value) => {
    setNewDetails(value);
  };

  const handleRemoveHireContract = removedContract => {
    const newHires = newHireContracts
      .filter(({ freelancerId }) => freelancerId !== removedContract.freelancerId);
    setNewHireContracts(newHires);
  };

  const isEditing = Boolean(status === "draft");
  const isApplicableVersion = Boolean(contract.applicableVersionId === versionId);
  return (
    <ContractVersionCard 
      isApplicableVersion={isApplicableVersion}
      submittingDraft={submittingDraft}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleStartEditing={handleStartEditing}
      handleRevoke={handleRevoke}
      handleReject={handleReject}
      handleActivate={handleActivate}
      handleStopContract={handleStopContract}
      disableActions={disableActions}
      versionNumber={versionNumber}
      status={status}
      becameStatusPendingBy={becameStatusPendingBy}
    >
      {Boolean(errorMessage) && (
        <SimpleDialog
          id="contract-version-error-dialog"
          toggleDialog={handleResetErrorMessage}
          open={true}
          title="Business is missing a valid payment method"
          description={errorMessage}
          primaryActionText="OK"
          handlePrimaryAction={handleResetErrorMessage}
        />
      )}
      <FieldBlock title="Team hires" topGutterSpacing={3}>
        <TeamHiresField
          team={team}
          hireContracts={newHireContracts} 
          handleRemoveContract={handleRemoveHireContract}
          handleChange={handleModifyHireContracts}
          currency={contract.currency}
          isEditing={isEditing}
        />
      </FieldBlock>
      {(isEditing || details) && (
        <FieldBlock title="Details">
          <DetailsField
            value={isEditing ? newDetails : details}
            handleChange={handleModifyVersion}
            isEditing={isEditing}
          />
        </FieldBlock>
      )}
      {/* <div className={classes.checkboxContainer}>
        <Checkbox
          id="checkbox"
          checked={true}
          // onClick={c}
          value="termsAccepted"
          color="primary"
          className={classes.checkbox}
        />
        <Typography variant="body2" className={classes.checkboxText}>
          {`I accept the `}
          <Link onClick={() => console.log("")} color="primary">
            terms and conditions
          </Link>
          {' that apply to this contract'}
        </Typography>
      </div> */}
    </ContractVersionCard>
  ); 
}

ContractVersionEditor.propTypes = {
  team: PropTypes.object.isRequired,
};
