// React
import React from "react";

// Context
import {AuthUserContext} from "../../components/Session";
import {FirebaseContext} from "../../components/Firebase";

// Components
import SettingsPanelTextfield from "../../components/SettingsPanelTextfield";
import {CountryPanel, ProfilePhotoPanel} from "../../components/SettingsPanels";

export default function PersonalInfoSettings(props) {
  const [expanded, setExpanded] = React.useState({
    firstName: false,
    lastName: false,
    country: false,
    profilePhoto: false,
  });
  const authUser = React.useContext(AuthUserContext);
  const firebase = React.useContext(FirebaseContext);

  const handlePanelChange = (panel) => (event, value) => {
    setExpanded({
      ...expanded,
      [panel]: value,
    });
  };

  const firstNameId = "firstName";
  const lastNameId = "lastName";
  return (
    <div>
      <SettingsPanelTextfield
        id="first-name-panel"
        lastSavedValue={authUser[firstNameId]}
        firebaseRef={firebase.user(authUser.uid)}
        firebaseFieldKey={firstNameId}
        characterLimit={30}
        label="First name"
        expandedText="Enter your first name"
        isExpanded={Boolean(expanded[firstNameId])}
        handlePanelChange={handlePanelChange(firstNameId)}
        multiline={false}
        rowsMax={1}
        nrOfRows={1}
      />
      {/* Last name*/}
      <SettingsPanelTextfield
        id="last-name-panel"
        lastSavedValue={authUser[lastNameId]}
        firebaseRef={firebase.user(authUser.uid)}
        firebaseFieldKey={lastNameId}
        characterLimit={50}
        label="Last name"
        expandedText="Enter your last name"
        isExpanded={Boolean(expanded[lastNameId])}
        handlePanelChange={handlePanelChange(lastNameId)}
        multiline={false}
        rowsMax={1}
        nrOfRows={1}
      />
      <CountryPanel
        firebaseRef={firebase.user(authUser.uid)}
        value={authUser.country}
        isExpanded={Boolean(expanded.country)}
        handlePanelChange={handlePanelChange("country")}
      />
      <ProfilePhotoPanel
        isExpanded={Boolean(expanded.profilePhoto)}
        handlePanelChange={handlePanelChange("profilePhoto")}
        firebaseRef={firebase.user(authUser.uid)}
        firebaseStorageRef={firebase.userProfilePhoto(authUser.uid)}
        lastSavedPhoto={authUser.profilePhoto}
        altText={`${authUser.firstName}'s avatar`}
        helperCaption="Your face must be clearly recognizable."
      />
    </div>
  );
}