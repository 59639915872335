// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Components
import {AuthUserContext} from "../../components/Session";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import StatusLabel from "../../components/StatusLabel";

// MUI
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  CardHeader,
} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Build} from "@material-ui/icons";

// Utils
import {convertUnixTsToDate} from "../../utils/time";
import {stableSort, getSorting} from "../../utils/sorting";

// Material UI
import grey from "@material-ui/core/colors/grey";

// constants
import * as routes from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    width: '100%',
    marginTop: topGutterSpacing ? theme.spacing(topGutterSpacing) : 0,
    marginBottom: bottomGutterSpacing ? theme.spacing(bottomGutterSpacing) : 0,
  }),
  table: {
    minWidth: 750,
  },
  tableRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardHeaderRoot: {
    padding: 0
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}));

const headCells = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "yourRole",
    label: "Membership type",
  },
  {
    id: "yourStatus",
    label: "Membership status",
  },
];

function createTeamRows({teams, teamApplications, authUser}) {
  return teams
    .map(({uid, slug, profilePhoto, createdAt, name, members, status, teamLeadId}) => {

      const teamApplication = teamApplications
        .filter(({teamId}) => teamId === uid)[0];
      const teamMembership = members[authUser.uid];
      const isTeamLead = Boolean(authUser.uid === teamLeadId);
      const membershipType = isTeamLead ? "Team lead" : (teamMembership ? "Team member" : "Applicant");
      const membershipStatus = teamMembership
        ? teamMembership.status
        : (teamApplication ? teamApplication.status : "pending");

      // Build and return the row
      return ({
        uid,
        dateCreated: convertUnixTsToDate(createdAt.seconds),
        profilePhoto,
        slug,
        members,
        isTeamLead,
        teamStatus: status,
        membershipType,
        membershipStatus,
        name,
      });
    });
}

export default function TeamsTable(props) {
  const {teams, teamApplications} = props;
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');

  const authUser = React.useContext(AuthUserContext);
  const classes = useStyles(props);
  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRowClick = ({uid, isTeamLead}) => {
    const teamPageRoute = isTeamLead ? (
      routes.freelancerTeamTab
        .replace(":teamId", uid)
        .replace(":tab", "profile")
    ) : (
      routes.freelancerTeam
        .replace(":teamId", uid)
    )
    return navigate(teamPageRoute)
  };

  const rows = createTeamRows({teams, teamApplications, authUser});

  return (
    <TableContainer>
      <Table
        className={classes.table}
        size='medium'
      >
        <EnhancedTableHead
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(rows, getSorting(order, orderBy))
            .map(row => {
              const {uid, name, membershipType, membershipStatus, profilePhoto, members} = row;
              const activeMembers = Object.keys(members)
                .map(userId => members[userId])
                .filter(({status}) => status === "active");


              return (
                <TableRow
                  hover
                  onClick={() => handleRowClick(row)}
                  key={uid}
                  className={classes.tableRow}
                >
                  <TableCell component="th" id="name" scope="row">
                    <CardHeader
                      classes={{
                        root: classes.cardHeaderRoot,
                      }}
                      avatar={
                        (profilePhoto) ? (
                          <Avatar
                            src={profilePhoto}
                            className={classes.avatar}
                            variant="rounded"
                          />
                        ) : (
                          <Avatar className={classes.avatar} variant="rounded">
                            {name ? name.substring(0, 1) : <Build/>}
                          </Avatar>
                        )
                      }
                      title={name || "Your team"}
                      titleTypographyProps={{variant: "body2", component: "h1"}}
                      subheader={`${activeMembers.length} active member${activeMembers.length !== 1 ? "s" : ""}`}
                    />
                  </TableCell>
                  <TableCell id="yourRole">
                    <StatusLabel
                      color={grey[500]}
                      value={membershipType}
                      size="small"
                      shape="square"
                      clickable
                    />
                  </TableCell>
                  <TableCell id="yourStatus">
                    <StatusLabel
                      color={theme.palette.statusColors[membershipStatus]}
                      value={membershipStatus}
                      size="small"
                      shape="square"
                      clickable
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TeamsTable.propTypes = {
  teams: PropTypes.array.isRequired,
  teamApplications: PropTypes.array.isRequired,
};
