// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {FirebaseContext, useFetchFirestore} from "../../../Firebase";
import {ExternalLink} from "../../../Link";

// Material UI
import {
  List, ListItem, ListItemAvatar, Avatar,
  ListItemText, LinearProgress, IconButton, Box, Divider
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {Edit, RemoveCircleOutline} from "@material-ui/icons";
import {getCurrencyFormat, countryToFlag} from "../../../../utils/formatting";
import queryString from "query-string";

// Constants
import * as routes from "../../../../constants/routes";

const menuItemStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0.5),
  },
  hoverPointer: {
    "&:hover": {
      cursor: "pointer",
    },
  }
}));

function getSalesTaxText({option, rate, type}) {
  if (option === "setTax") {
    return `+${rate}% ${type}`
  } else if (option === "reverseCharge") {
    return `(${type} reverse charged)`
  }
  return "(no sales tax)"
}

function MemberListItem(props) {
  const {hireContract, teamProfile, onEditHire, onRemoveHire, currency, isEditing, teamId} = props;
  const classes = menuItemStyles();
  const firebase = React.useContext(FirebaseContext);
  const [userProfile, loadingUserProfile] = useFetchFirestore(firebase.user(hireContract.freelancerId));

  if (loadingUserProfile) {
    return (
      <ListItem className={classes.root}>
        <LinearProgress/>
      </ListItem>
    )
  } else if (!userProfile) {
    return (
      <ListItem className={classes.root}>
        <ListItemText primary="Something went wrong"/>
      </ListItem>
    )
  }

  const memberProfileUrl = queryString.stringifyUrl({
    url: routes.teamProfile,
    query: {
      uid: teamId,
      member: userProfile.uid,
    }
  });

  console.log("kkk userProfile", userProfile)

  const hourlyRateText = `${getCurrencyFormat(hireContract.hourlyRate, currency)}/hour`;
  const salesTaxText = getSalesTaxText(hireContract.salesTax);
  const monthlyBudgetText = `${getCurrencyFormat(hireContract.monthlyBudget, currency)} monthly budget`;
  return (
    <ListItem key={userProfile.uid} classes={{container: classes.root}}>
      <ListItemAvatar>
        <ExternalLink to={memberProfileUrl}>
          <Avatar src={userProfile.profilePhoto}/>
        </ExternalLink>
      </ListItemAvatar>
      <ListItemText
        primary={
          <ExternalLink to={memberProfileUrl}>
            {`${userProfile.firstName} ${userProfile.lastName} ${countryToFlag(userProfile.country)}`}
          </ExternalLink>
        }
        secondary={`${hourlyRateText} ${salesTaxText} — ${monthlyBudgetText}`}
      />
      {isEditing && (
        <IconButton onClick={onEditHire}>
          <Edit/>
        </IconButton>
      )}
      {isEditing && (
        <IconButton disabled={teamProfile.isTeamLead} onClick={onRemoveHire}>
          <RemoveCircleOutline/>
        </IconButton>
      )}
    </ListItem>
  )
}


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  fieldContainer: {
    marginBottom: theme.spacing(1),
  },
  fieldLabel: {
    marginBottom: theme.spacing(1),
  },
  list: {
    padding: 0,
  }
}));

export default function SelectedHiresList(props) {
  const {teamId, hiresContracts, teamProfiles, onRemoveHires, currency, isEditing, handleOpenHireDialog} = props;
  const classes = useStyles(props);

  console.log("in list teamProfiles", teamProfiles)
  console.log("in list hiresContracts", hiresContracts)

  let listItems = hiresContracts
    .map(hireContract => {
      console.log('www hireContract.freelancerId', hireContract.freelancerId)
      console.log('www teamProfiles', teamProfiles)

      const teamProfile = teamProfiles
        .filter(({userId}) => userId === hireContract.freelancerId)[0];
      console.log('www teamProfile', teamProfile)
      return (
        <MemberListItem
          key={hireContract.freelancerId}
          hireContract={hireContract}
          teamProfile={teamProfile}
          onEditHire={() => handleOpenHireDialog(hireContract.freelancerId)}
          onRemoveHire={() => onRemoveHires(hireContract)}
          currency={currency}
          isEditing={isEditing}
          teamId={teamId}
        />
      )
    });

  // Intertwine Divider components between each ListItem
  const dividedListItems = [];
  for (let i = 0; i < listItems.length; i++) {
    dividedListItems.push(listItems[i]);
    const isLastListItem = (listItems.length - 1) === i;
    if (!isLastListItem) {
      dividedListItems.push(<Divider key={i}/>)
    }
  }

  return (
    <Box className={classes.root}>
      <Divider />
      <List dense className={classes.list}>
        {dividedListItems}
      </List>
      <Divider />
    </Box>
  );
}

SelectedHiresList.propTypes = {
  hiresContracts: PropTypes.array.isRequired,
  onRemoveHires: PropTypes.func.isRequired,
};
