// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 800,
    marginTop: 5,
    marginBottom: 5,
  },
  topGutter: {
    marginTop: theme.spacing(4),
  },
  leftContainer: {
    width: "100%",
    marginTop: "auto",
    marginBottom: "auto",
  },
  rightContainer: {
    minWidth: "fit-content",
    marginTop: "auto",
    marginBottom: "auto",
  },
  mainCtaButton: {
    minWidth: "fit-content",
    //height: 49,
    // [theme.breakpoints.down('sm')]: {
    //   textAlign: "right",
    // },
    // [theme.breakpoints.up('md')]: {
    //   textAlign: "right",
    //   // width: '95%',
    //   // marginLeft: '5%',
    // },
  },
  loadingButton: {
    marginRight: 5,
  },
}));

export default function CtaBlock(props) {
  const {
    leftComponent,
    buttonDisabled,
    buttonText,
    buttonColor,
    buttonVariant,
    handleButtonClick,
    buttonSize,
    rightComponent,
    isLoading,
    topGutter,
  } = props;
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.root, topGutter ? classes.topGutter : null)}
    >
      <div className={classes.leftContainer}>{leftComponent}</div>
      <div className={classes.rightContainer}>
        {rightComponent || (
          <Button
            id="cta-button"
            variant={buttonVariant || "contained"}
            size={buttonSize || "medium"}
            disabled={buttonDisabled || isLoading}
            color={buttonColor}
            className={classes.mainCtaButton}
            onClick={handleButtonClick}
          >
            {isLoading && (
              <CircularProgress size={20} className={classes.loadingButton}/>
            )}
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}

// App bar support three background colors
const buttonVariants = {
  contained: "contained",
  outlined: "outlined",
};

// App bar support three background colors
const buttonSizes = {
  small: "small",
  medium: "medium",
  large: "large",
};

// App bar support three background colors
const buttonColors = {
  primary: "primary",
  secondary: "secondary",
};

CtaBlock.propTypes = {
  leftComponent: PropTypes.any.isRequired,
  buttonDisabled: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.oneOf(Object.keys(buttonVariants)),
  buttonColor: PropTypes.oneOf(Object.keys(buttonColors)),
  buttonSize: PropTypes.oneOf(Object.keys(buttonSizes)),
  handleButtonClick: PropTypes.func,
  isLoading: PropTypes.bool,
  addGutters: PropTypes.bool,
};
