// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StatusLabel from "../../../components/StatusLabel";

// Material ui
import {makeStyles, useTheme} from "@material-ui/core/styles";
import { Box, Card, CardHeader, CardContent, Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  cardHeaderTitleBox: {
    display: 'flex',
  },
  cardHeaderTitleTypo: {
    marginRight: theme.spacing(2),
  }
}));

export default function HireContractCard(props) {
  const { versionNumber, status, children } = props;
  const classes = useStyles(props);
  const theme = useTheme()

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Box className={classes.cardHeaderTitleBox}>
            <Typography className={classes.cardHeaderTitleTypo} variant="h6">
              {`Version #${versionNumber}`}
            </Typography>
            <StatusLabel
              value={status}
              shape="square"
              color={theme.palette.statusColors[status]}
              // popoverText={getStatusText(billStatus, bill)}
            />
          </Box>
        }
        disableTypography
      />
      <Divider />
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
}

HireContractCard.propTypes = {
  versionNumber: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
};
