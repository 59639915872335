// React
import React from "react";
import {navigate} from "gatsby";
//import PropTypes from 'prop-types';

// Context
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";

// Components
import BodyContainer from "../../components/BodyContainer";
import TeamsTable from "./TeamsTable";
import TeamsToolbar from "./TeamsToolbar";
import EmptyStateView from "../../components/EmptyStateView";
import LoadingIndicator from "../../components/LoadingIndicator";

// constants
import * as routes from "../../constants/routes";
import {People} from "@material-ui/icons";

const statusOptions = [
  {
    value: "all",
    text: "All",
  },
  {
    value: "active",
    text: "Active",
  },
  {
    value: "canceled",
    text: "Canceled",
  },
];

export default function FreelancerTeamsPage() {

  const authUser = React.useContext(AuthUserContext);
  const firebase = React.useContext(FirebaseContext);
  const [memberTeams, loadingMemberTeams] = useFetchFirestore(
    firebase
      .teams()
      .where(`members.${authUser.uid}.userId`, "==", authUser.uid),
    {type: "collection"}
  );
  const [teamApplications, loadingTeamApplications] = useFetchFirestore(
    firebase
      .teamApplications()
      .where("ownerId", "==", authUser.uid),
    {type: "collection"}
  );
  const nonMemberApplications = loadingMemberTeams
    ? []
    : teamApplications
      .filter(({teamId}) => !memberTeams.map(({uid}) => uid).includes(teamId))
      .slice(0, 10);
  const nonMemberTeamIds = nonMemberApplications.map(({teamId}) => teamId);
  const nonMemberTeamsRef = nonMemberTeamIds.length > 0 ? (
    firebase
      .teams()
      .where("uid", "in", nonMemberTeamIds)
  ) : null;
  const [nonMemberTeams, loadingNonMemberTeams] = useFetchFirestore(
    nonMemberTeamsRef,
    {
      type: "collection",
      stopLoading: !loadingTeamApplications && nonMemberTeamIds.length === 0
    }
  );

  console.log("ccc memberTeams", memberTeams);
  console.log("ccc teamApplications", teamApplications);
  console.log("ccc nonMemberTeams", nonMemberTeams);


  const [filters, setFilters] = React.useState({
    queryFilter: "",
    statusFilter: "all",
  });

  const handleSearch = (prop) => (event) => {
    const query = event.target.value;
    setFilters((f) => ({...f, queryFilter: query}));
  };

  const handleSelectFilter = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  const handleApplyToTeams = () => navigate(routes.teams);

  const handleStartTeam = () => {
    const timestamp = firebase.createTimestamp();
    firebase.teams()
      .add({
        createdAt: timestamp,
        status: "draft",
        teamLeadId: authUser.uid,
        members: {
          [authUser.uid]: {
            userId: authUser.uid,
            createdAt: timestamp,
            becameStatusActiveAt: timestamp,
            status: "active",
          },
        },
        noActiveMembers: 1,
      })
      .then(docRef =>
        firebase.team(docRef.id)
          .update({
            uid: docRef.id,
            slug: docRef.id,
          })
          .then(() =>
            navigate(routes.freelancerTeam.replace(":teamId", docRef.id))
          )
      )
  };

  if (loadingTeamApplications || loadingMemberTeams || loadingNonMemberTeams) {
    return (
      <LoadingIndicator
        message="Loading teams..."
        inheritHeight
        topSpacing={5}
      />
    )
  }

  const teams = [...memberTeams, ...nonMemberTeams];
  const ownTeam = memberTeams.filter(({teamLeadId}) => teamLeadId === authUser.uid)[0];
  return (
    <BodyContainer topGutter>
      <TeamsToolbar
        handleSearch={handleSearch}
        filterMenuItems={statusOptions}
        filters={filters}
        onSelectFilter={handleSelectFilter}
        onApplyToTeams={handleApplyToTeams}
        onStartTeam={handleStartTeam}
        hasOwnTeam={Boolean(ownTeam && ownTeam.uid)}
      />
      {teams.length > 0 ? (
        <TeamsTable
          teams={teams}
          teamApplications={teamApplications}
        />
      ) : (
        <EmptyStateView
          text="Apply to teams or lead your own"
          icon={<People/>}
          topGutterSpacing={3}
        />
      )}

    </BodyContainer>
  );
}

FreelancerTeamsPage.propTypes = {};
