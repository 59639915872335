// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";

// Context
import {Typography, Card, CardHeader, CardActionArea, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RadioButtonChecked, RadioButtonUnchecked} from "@material-ui/icons";

// Utils
import {getCurrencyFormat} from "../../utils/formatting";

const useStyles = makeStyles((theme) => ({
  root: ({isSelected}) => ({
    minWidth: 240,
    border: `1px solid ${isSelected ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.12)'}`,
  }),
  cardContentContainer: {
    display: 'flex',
    justifyContent: "space-between",
    padding: theme.spacing(1)
  },
  cardHeaderRoot: {
    padding: 0
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  selectIcon: ({isSelected}) => ({
    color: isSelected ? theme.palette.primary.main : "inherit",
    marginRight: theme.spacing(1),
    marginTop: "auto",
    marginBottom: "auto",
  })
}));

export default function ContractCardItem(props) {
  const {uid, businessId, monthlyBudget, currency, handleClick, isSelected, disabled} = props;
  const classes = useStyles(props);
  const firebase = React.useContext(FirebaseContext);
  const [business, loadingBusiness] = useFetchFirestore(firebase.business(businessId));

  if (loadingBusiness) {
    return (
      <Card id={uid} key={uid} className={classes.root}>
        <CircularProgress
          className={classes.progressIndicator}
          size={30}
        />
      </Card>
    )
  } else if (!business) {
    return (
      <Card id={uid} key={uid} className={classes.root}>
        <Typography>Something went wrong</Typography>
      </Card>
    )
  }

  return (
    <Card id={uid} key={uid} className={classes.root}>
      <CardActionArea
        classes={{
          root: classes.actionArea,
          focusHighlight: classes.focusHighlight,
        }}
        onClick={handleClick}
      >
        <div className={classes.cardContentContainer}>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
            }}
            title={business.name}
            titleTypographyProps={{variant: "subtitle1"}}
            subheader={`${getCurrencyFormat(monthlyBudget, currency, { convertToDecimals: true })} monthly budget`}
          />
          {isSelected ? (
            <RadioButtonChecked className={classes.selectIcon} disabled={disabled} />
          ) : (
            <RadioButtonUnchecked className={classes.selectIcon} disabled={disabled}/>
          )}
        </div>
      </CardActionArea>
    </Card>
  )
};

ContractCardItem.propTypes = {
  contract: PropTypes.object.isRequired,
};


