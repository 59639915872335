// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {convertMinToTimeString} from "../../../utils/time";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    height: 36,
  },
  sessionsHeader: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  sessionTime: ({minutes, minutesBreak}) => ({
    textAlign: "right",
    opacity: (minutes - minutesBreak) < 0 ? "inherit" : 0.6,
    color: (minutes - minutesBreak) < 0 ? theme.palette.error.main : "inherit",
    marginTop: "auto",
    marginBottom: "auto",
  }),
}));

export default function SessionHeader(props) {
  const {minutes, minutesBreak, uid} = props;
  const classes = useStyles(props);

  const minutesBilled = minutes - (minutesBreak || 0);
  const timeSpendText = convertMinToTimeString(minutesBilled);

  return (
    <div className={classes.root}>
      <Typography className={classes.sessionsHeader} variant="subtitle1">
        {uid ? `Session #${uid}` : `New session`}
      </Typography>
      <Typography className={classes.sessionTime}>
        {`${minutesBilled < 0 ? "-" : ""}${timeSpendText}`}
      </Typography>
    </div>
  );
}

SessionHeader.propTypes = {
  selectedSession: PropTypes.object.isRequired,
};
