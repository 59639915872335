// React
import React from "react";
//import PropTypes from 'prop-types';

// Components
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";
import BodyContainer from "../../components/BodyContainer";
import InvoicesTable from "../../components/InvoicesTable";
import LoadingIndicator from "../../components/LoadingIndicator";

// Utils
import {convertUnixTsToDate} from "../../utils/time";
import {Typography} from "@material-ui/core";
import {formatCentsToDisplayPrice} from "../../utils/formatting";

function createInvoicesRows(bills, sessions, type) {
  return bills
    .map(bill => {
      const {uid, title, becameStatusPaymentRequestedAt, businessId, currency, totalAmount, serviceFee, transactionFee} = bill;
      // Build and return the row
      const billSessions = sessions
        .filter(({ billId }) => billId === uid)
      return ({
        bill,
        sessions: billSessions,
        billId: uid,
        title,
        paymentRequestedDate: convertUnixTsToDate(becameStatusPaymentRequestedAt.seconds),
        type,
        client: businessId,
        currency,
        totalAmount: type === "fees" ? (serviceFee.totalAmount + transactionFee.totalAmount) : totalAmount,
      });
    });
}

export default function FreelancerInvoicesPage() {
  const authUser = React.useContext(AuthUserContext);
  const firebase = React.useContext(FirebaseContext);
  const [bills, loadingBills] = useFetchFirestore(
    firebase
      .bills()
      .where("ownerId", "==", authUser.uid)
      .where("status", "==", "paid")
      .limit(10),
    {
      type: "collection",
      listen: true,
      transformResult: r => r
        .map(b => ({
          ...b,
          amountExcl: formatCentsToDisplayPrice(b.amountExcl),
          totalAmount: formatCentsToDisplayPrice(b.totalAmount),
          monthlyBudget: formatCentsToDisplayPrice(b.monthlyBudget),
          hourlyRate: formatCentsToDisplayPrice(b.hourlyRate),
          salesTax: {
            ...b.salesTax,
            amount: formatCentsToDisplayPrice(b.salesTax.amount),
          },
          serviceFee: {
            ...b.serviceFee,
            amountExcl: formatCentsToDisplayPrice(b.serviceFee.amountExcl),
            totalAmount: formatCentsToDisplayPrice(b.serviceFee.totalAmount)
          },
          transactionFee: {
            ...b.transactionFee,
            amountExcl: formatCentsToDisplayPrice(b.transactionFee.amountExcl),
            totalAmount: formatCentsToDisplayPrice(b.transactionFee.totalAmount),
          }
        }))
    }
  );

  const billIds = bills.map(({ uid }) => uid);
  const sessionsRef = billIds.length > 0 ? (
    firebase
      .sessions()
      .where("ownerId", "==", authUser.uid)
      .where("billId", "in", billIds)
      .where("excluded", "==", false)
  ) : null;
  const [sessions, loadingSessions] = useFetchFirestore(
    sessionsRef,
    {
      type: "collection",
      listen: true,
      stopLoading: !loadingBills && bills.length === 0,
      transformResult: r => r.map(s => ({
        ...s,
        startedDate: convertUnixTsToDate(s.startedAt.seconds),
        endedDate: convertUnixTsToDate(s.endedAt.seconds),
        amountExcl: formatCentsToDisplayPrice(s.amountExcl),
      }))
    }
  );

  if (loadingBills || loadingSessions) {
    return (
      <LoadingIndicator
        message="Loading bills..."
        topSpacing={10}
        bottomSpacing={10}
        inheritHeight
      />
    )
  }

  const earningsInvoicesRows = createInvoicesRows(bills, sessions, "earnings");
  const feesInvoicesRows = createInvoicesRows(bills, sessions, "fees");

  return (
    <BodyContainer topGutterSpacing={4} bottomGutter>
      <Typography variant="h6" component="h1" style={{marginBottom: 16}}>Invoices</Typography>
      <InvoicesTable rows={[...earningsInvoicesRows, ...feesInvoicesRows]}/>
    </BodyContainer>
  );
}

FreelancerInvoicesPage.propTypes = {};
