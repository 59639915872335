// React
import React from "react";
// import PropTypes from "prop-types";

// Material ui
import queryString from "query-string";
import {AuthUserContext} from "../../../components/Session";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     marginBottom: 40,
//   },
// }));

export default function CalendlyScheduler(props) {

  const authUser = React.useContext(AuthUserContext);

  React.useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
  }, []);

  const hiddenFields = {
    email: authUser.email,
    name: `${authUser.firstName} ${authUser.lastName}`
  };
  const calendlyUrl = queryString.stringifyUrl({
    url: "https://calendly.com/stijn-from-maxer/intake",
    query: hiddenFields,
  });

  return (
    <div
      className="calendly-inline-widget"
      data-url={calendlyUrl}
      style={{
        minWidth: 320,
        height: 1200,
      }}
    />
  )
}


