// React
import React from "react";
import {navigate} from "gatsby";

// Context
import {AuthUserContext, BusinessContext} from "../../components/Session";

// Components
import DraftRequest from "./DraftRequest";
import OpenRequest from "./OpenRequest";
import {FirebaseContext} from "../../components/Firebase";
import queryString from "query-string";
import PageNotFound from "../../components/PageNotFound";
import LoadingIndicator from "../../components/LoadingIndicator";
import BodyContainer from "../../components/BodyContainer";
import StatusHeader from "./StatusHeader";
import {useLocation} from "@reach/router";

function getContentByStatus(request) {
  switch (request.status) {
    case "draft":
      return <DraftRequest request={request}/>;
    case "open":
      return <OpenRequest request={request}/>;
    default:
      return null;
  }
}

function getHeaderText(status) {
  if (status === "draft") {
    return "Build your team request";
  }
  return "Invite teams to receive contract proposals";
}

export default function RequestPage(props) {
  const [fetching, setFetching] = React.useState(true);
  const [request, setRequest] = React.useState(false);

  const {selectedBusiness} = React.useContext(BusinessContext);
  const authUser = React.useContext(AuthUserContext);
  const firebase = React.useContext(FirebaseContext);
  const location = useLocation();
  const {match} = props;

  React.useEffect(() => {
    if (match.requestId === "new") {
      const ts = firebase.createTimestamp();
      firebase.requests()
        .add({
          createdAt: ts,
          becameStatusOpenAt: ts,
          businessId: selectedBusiness.uid,
          ownerId: authUser.uid,
          status: "draft",
          currency: selectedBusiness.currency,
          teamOptions: {},
        })
        .then((docRef) => {
          const requestUrl = queryString.stringifyUrl({
            url: location.pathname.replace("new", docRef.id),
            query: {business: selectedBusiness.uid},
          });
          return navigate(requestUrl)
        })
    } else {
      const unsubscribe = firebase.request(match.requestId)
        .onSnapshot(snap => {
          setRequest(firebase.formatSnapshot(snap));
          setFetching(false);
        });
      return () => unsubscribe();
    }
  }, [firebase, match.requestId, authUser.uid, location.pathname, selectedBusiness.currency, selectedBusiness.uid]);

  if (fetching) {
    return (
      <LoadingIndicator
        message="Loading request..."
        inheritHeight
        topSpacing={10}
      />
    )
  } else if (!request) {
    return <PageNotFound/>
  }

  return (
    <BodyContainer topGutterSpacing={4} bottomGutter>
      {request.status !== "draft" && (
        <StatusHeader
          headerText={getHeaderText(request.status)}
          status={request.status}
        />
      )}
      {getContentByStatus(request)}
    </BodyContainer>
  );
}

