// React
import React from "react";
import StatusLabel from "../../../components/StatusLabel";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Divider
} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import SettingsPanelTextfield from "../../../components/SettingsPanelTextfield";
import {FirebaseContext} from "../../../components/Firebase";
import {ProfilePhotoPanel} from "../../../components/SettingsPanels";

//import PropTypes from 'prop-types';

function getStatusText(teamStatus) {
  if (teamStatus === "draft") {
    return "Apply to lead your own team by filling out the below fields. Intake of team leads is limited. Only applicants with plenty of experience get accepted."
  } else if (teamStatus === "pending") {
    return "Your team request is pending approval. You will be notified as soon as it has been reviewed."
  } else if (teamStatus === "rejected") {
    return "We decided to reject your team. Please don't take it personally. We carefully review dozens of applications every week and - to keep supply and demand in balance - can only approve a limited number. The good news: You can still apply to join other teams."
  } else if (teamStatus === "approved") {
    return "Your team request has been approved. You can now invite team members to apply to your team."
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  cardContent: {
    display: "flex"
  },
  statusText: {
    margin: `auto ${theme.spacing(2)}px auto ${theme.spacing(2)}px`
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  panels: {
    marginTop: theme.spacing(4),
  }
}));

export default function TeamProfile(props) {
  const {team} = props;
  const theme = useTheme();
  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);
  const [expanded, setExpanded] = React.useState({
    name: false,
    about: false,
    profilePhoto: false,
  });
  const [unsavedChanges, setUnsavedChanges] = React.useState({
    name: false,
    about: false,
    profilePhoto: false,
  });
  const [errorMessages, setErrorMessages] = React.useState({
    name: null,
    about: null,
    profilePhoto: null,
  });

  const handlePanelChange = (panel) => (event, value) => {
    setExpanded({
      ...expanded,
      [panel]: value,
    });
    if (errorMessages[panel]) {
      setErrorMessages(em => ({
        ...em,
        [panel]: null,
      }))
    }
  };

  const handleUnsavedChanges = (panel, hasUnsavedChanges) => {
    if (errorMessages[panel] && !hasUnsavedChanges) {
      setErrorMessages(em => ({
        ...em,
        [panel]: null,
      }))
    }
    setUnsavedChanges({
      ...unsavedChanges,
      [panel]: hasUnsavedChanges,
    });
  };

  const handleSubmitForApproval = () => {
    const timestamp = firebase.createTimestamp();
    let hasErrors = !team.name || !team.about;
    setErrorMessages(em => ({
      ...em,
      name: team.name ? null : "Enter a team name",
      about: team.about ? null : "Write a team about",
      profilePhoto: null,
    }));
    for (let i = 0; i < Object.keys(unsavedChanges).length; i++) {
      const key = Object.keys(unsavedChanges)[i];
      const hasUnsavedChanges = unsavedChanges[key];
      if (hasUnsavedChanges) {
        hasErrors = true;
        setErrorMessages(em => ({
          ...em,
          [key]: "Unsaved changes made",
        }))
      }
    }
    if (!hasErrors) {
      firebase.team(team.uid)
        .update({
          lastUpdatedAt: timestamp,
          becameStatusPendingAt: timestamp,
          status: "pending",
        })
    }
  };

  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <CardContent className={classes.cardContent}>
          <StatusLabel
            color={theme.palette.statusColors[team.status]}
            value={team.status}
            shape="square"
          />
          <Typography
            variant="body2"
            className={classes.statusText}
          >
            {getStatusText(team.status)}
          </Typography>
        </CardContent>
        {team.status === "draft" && <Divider/>}
        {team.status === "draft" && (
          <CardActions className={classes.cardActions}>
            <Button
              className={classes.actionBtn}
              color="primary"
              onClick={handleSubmitForApproval}
            >
              Submit for approval
            </Button>
          </CardActions>
        )}
      </Card>
      <div className={classes.panels}>
        <SettingsPanelTextfield
          id="team-name-panel"
          startingTextValue={team.name}
          firebaseRef={firebase.team(team.uid)}
          lastSavedValue={team.name}
          firebaseFieldKey="name"
          characterLimit={15}
          label="Name"
          expandedText="Enter your team name"
          isExpanded={Boolean(expanded.name)}
          handlePanelChange={handlePanelChange('name')}
          handleUnsavedChanges={(value) => handleUnsavedChanges('name', value)}
          error={Boolean(errorMessages.name)}
          errorMessage={errorMessages.name}
          multiline={false}
          rowsMax={1}
          nrOfRows={1}
        />
        <SettingsPanelTextfield
          id="team-name-panel"
          startingTextValue={team.about}
          firebaseRef={firebase.team(team.uid)}
          lastSavedValue={team.about}
          firebaseFieldKey="about"
          characterLimit={1000}
          label="About"
          expandedText="Write about what characterizes your team"
          formHelperText="What does your team specialize in? Why should clients hire your team?"
          isExpanded={Boolean(expanded.about)}
          handlePanelChange={handlePanelChange('about')}
          handleUnsavedChanges={(value) => handleUnsavedChanges('about', value)}
          error={Boolean(errorMessages.about)}
          errorMessage={errorMessages.about}
          multiline
          rowsMax={10}
          nrOfRows={10}
        />
        <ProfilePhotoPanel
          isExpanded={Boolean(expanded.profilePhoto)}
          handlePanelChange={handlePanelChange("profilePhoto")}
          handleUnsavedChanges={(value) => handleUnsavedChanges('profilePhoto', value)}
          firebaseRef={firebase.team(team.uid)}
          firebaseStorageRef={firebase.teamProfilePhoto(team.uid)}
          lastSavedPhoto={team.profilePhoto}
          altText={`${team.name || team.uid}'s avatar`}
          label="Logo"
          expandedText="Upload a team logo (optional)"
          errorMessage={errorMessages.profilePhoto}
          avatarBorderRadius={5}
        />
      </div>
    </div>
  );
}

TeamProfile.propTypes = {};
