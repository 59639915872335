// React
import React from "react";
import PropTypes from "prop-types";

// Components
import Link from "../Link";
import * as routes from "../../constants/routes";
import queryString from "query-string";
import {useLocation} from "@reach/router";

// Material ui
import {Box, Avatar, CardHeader, Divider, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { AuthUserContext } from "../Session";


function getSourceLinkText(contract) {
  if (contract.sourceColId === "requests") {
    return `Request #${contract.sourceDocId.substring(0, 8)}...`
  }
  return "Request"
}

function getSourceLinkDestination({ sourceColId, sourceDocId, authUser, parsedQueries, team }) {

  let url = "";
  let newQueries = parsedQueries;
  if (sourceColId === "requests") {

    let route = routes.clientTeamsRequest;
    if (authUser.role === "freelancer" ) {
      route = routes.freelancerRequest;
      newQueries.team = team.uid;
    }
    url = route.replace(":requestId", sourceDocId)
  }

  return queryString
    .stringifyUrl({ url, query: newQueries })
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  root: ({bottomGutterSpacing}) => ({
    marginBottom: theme.spacing(bottomGutterSpacing || 0),
    display: 'flex',
  }),
  cardHeaderRoot: {
    padding: 0,
  },
  verticalDivider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  label: {
    opacity: 0.65,
    marginBottom: theme.spacing(1),
  },
  linkBox: {
    height: theme.spacing(4),
    display: 'flex',
  },
  linkTypography: {
    marginTop: 'auto',
    marginBottom: 'auto',
  }
}));

export default function ContractHeader(props) {
  const {contract, team, client, business} = props;
  const classes = useStyles(props);
  const authUser = React.useContext(AuthUserContext);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  const sourceLinkDestination = getSourceLinkDestination({ 
    ...contract,
    authUser, 
    parsedQueries,
    team
  });
  const sourceLinkText = getSourceLinkText(contract) 

  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="body2" className={classes.label}>
          Source
        </Typography>
        <Box className={classes.linkBox}>
          <Typography variant="caption" className={classes.linkTypography}>
            <Link
              to={sourceLinkDestination}
              color="primary"
              weight="bold"
            >
              {sourceLinkText}
            </Link>
          </Typography>
        </Box>
      </Box>    
      {client && business && (
        <Divider 
          orientation="vertical" 
          flexItem 
          className={classes.verticalDivider} 
        />
      )}
      {client && business && (
        <Box>
          <Typography variant="body2" className={classes.label}>
            Client
          </Typography>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
            }}
            avatar={
              client.profilePhoto ? (
                <Avatar
                  src={client.profilePhoto}
                  className={classes.avatar}
                />
              ) : (
                <Avatar
                  src={client.profilePhoto}
                  className={classes.avatar}
                >
                  {client.firstName.substring(0, 1)}
                </Avatar>
              )
            }
            title={`${client.firstName} ${client.lastName} (${business.name})`}
            titleTypographyProps={{variant: "body2", component: "h1"}}
          />
        </Box>
      )}
      <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
      <Box>
        <Typography variant="body2" className={classes.label}>
          Team
        </Typography>
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
          }}
          avatar={
            <Avatar
              src={team.profilePhoto}
              className={classes.avatar}
              variant="rounded"
            />
          }
          title={team.name}
          titleTypographyProps={{variant: "body2", component: "h1"}}
          // subheader={`${activeMembers.length} active member${activeMembers.length !== 1 ? "s" : ""}`}
        />
      </Box>
    </Box>
  );
}

ContractHeader.propTypes = {
  team: PropTypes.object.isRequired,
  client: PropTypes.object,
  business: PropTypes.object,
  activeTab: PropTypes.string,
  toggleActiveTab: PropTypes.func,
};
