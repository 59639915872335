// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Context
import {FirebaseContext, useFetchFirestore} from "../../../components/Firebase";

// Components
import LoadingIndicator from "../../../components/LoadingIndicator";
import TeamCard from "../../../components/TeamCard";
import EmptyStateView from "../../../components/EmptyStateView";

// Mui
import {Button} from "@material-ui/core";
import {Add, HourglassEmpty, ArrowForward} from "@material-ui/icons";

// Constants
import * as routes from "../../../constants/routes";
import {BusinessContext} from "../../../components/Session";

function PrimaryButton(props) {
  const {request, team, contract} = props;
  const firebase = React.useContext(FirebaseContext);
  const {selectedBusiness} = React.useContext(BusinessContext);

  const handleViewProposal = contractId => {
    const url = routes.clientTeamsContract
      .replace(":contractId", contractId);
    return navigate(url + "?business=" + selectedBusiness.uid);
  };

  const handleInviteTeam = (teamId) => {
    let payload = {
      teamOptions: {
        ...request.teamOptions,
        [teamId]: {
          ...request.teamOptions[teamId],
          becameStatusInvitedAt: firebase.createTimestamp(),
          status: "invited",
        },
      },
      lastUpdatedAt: firebase.createTimestamp(),
    };
    return firebase.request(request.uid)
      .update(payload)
  };

  const teamStatus = request.teamOptions[team.uid].status;
  if (teamStatus === "proposed" || teamStatus === "hired") {
    return (
      <Button color="primary" onClick={() => handleViewProposal(contract.uid)} endIcon={<ArrowForward/>}>
        View proposed contract
      </Button>
    )
  } else if (teamStatus === "invited") {
    return (
      <Button
        color="primary"
        variant="contained"
        startIcon={<Add/>}
        disabled
      >
        Invited to propose
      </Button>
    )
  }
  return (
    <Button
      color="primary"
      onClick={() => handleInviteTeam(team.uid)}
      startIcon={<Add/>}
    >
      Invite team
    </Button>
  )
}

export default function OpenRequest(props) {

  // const [expandedFreelancers, setExpandedFreelancers] = React.useState({});
  const {request} = props;
  const firebase = React.useContext(FirebaseContext);

  const teamIdsSuggested = Object.keys(request.teamOptions).map(teamId => teamId);
  const teamsRef = teamIdsSuggested.length > 0 ? firebase.teams().where("uid", "in", teamIdsSuggested) : null;
  const [teams, loadingTeams] = useFetchFirestore(
    teamsRef,
    {type: "collection", listen: true, stopLoading: teamIdsSuggested.length === 0}
  );

  const [contracts, loadingContracts] = useFetchFirestore(
    firebase.contracts()
      .where("businessId", "==", request.businessId)
      .where("sourceColId", "==", "requests")
      .where("sourceDocId", "==", request.uid)
  );

  if (loadingTeams || loadingContracts) {
    return (
      <LoadingIndicator inheritHeight message="Loading suggested teams..."/>
    )
  } else if (teams.length === 0) {
    return (
      <EmptyStateView
        text="We are making a selection of suggested teams. You will get notified once we are done."
        icon={<HourglassEmpty/>}
        topSpacing={5}
      />
    )
  }

  return (
    <div id="freelancers-list">
      {teams.map((team) => {
        const contract = contracts
          .filter(({teamId}) => teamId === team.uid)[0];
        return (
          <TeamCard
            {...team}
            key={team.uid}
            cardActions={[
              <PrimaryButton
                request={request}
                team={team}
                contract={contract}
              />
            ]}
          />
        )
      })}
    </div>
  );
}

OpenRequest.propTypes = {
  request: PropTypes.object.isRequired,
};
