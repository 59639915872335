// React
import React from "react";

// Components
import BodyContainer from "../../components/BodyContainer";
import {Typography} from "@material-ui/core";

export default function FreelancerLeadInvitePage() {

  return (
    <BodyContainer bottomGutter topGutter>
      <Typography>Invite</Typography>
    </BodyContainer>
  );
}
