// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Components
import EnhancedTableHead from "../../../../components/EnhancedTableHead";
import {FirebaseContext, useFetchFirestore} from "../../../../components/Firebase";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import {AuthUserContext} from "../../../../components/Session";
import EmptyStateView from "../../../../components/EmptyStateView";
import MyContractRow from "./MyContractRow";

// MUI
import {Box, TableContainer,Table,TableBody,TablePagination} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {Gavel} from "@material-ui/icons";

// Utils
import {convertUnixTsToDate} from "../../../../utils/time";
import {stableSort, getSorting} from "../../../../utils/sorting";
import {formatCentsToDisplayPrice} from "../../../../utils/formatting";
import * as routes from "../../../../constants/routes";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    width: '100%',
    marginTop: topGutterSpacing ? theme.spacing(topGutterSpacing) : 0,
    marginBottom: bottomGutterSpacing ? theme.spacing(bottomGutterSpacing) : 0,
  }),
}));

const headCells = [
  {
    id: "clientName",
    label: "Client",
  },
  {
    id: "teamName",
    label: "Team",
  },
  {
    id: "status",
    label: "Contract status",
  },
  {
    id: "monthlyBudget",
    label: "Monthly budget",
  },
];

function createContractsRows({hireContracts, businesses, clients, teams, handleRowClick}) {
  return hireContracts
    .map(({uid, contractId, businessId, teamId, createdAt, status, currency, monthlyBudget}) => {
      const business = businesses
        .filter(b => b.uid === businessId)[0];
      const client = business 
        ? clients.filter(c => c.uid === business.ownerId)[0] 
        : null;
      const team = teams
        .filter(c => c.uid === teamId)[0];
      return ({
        uid,
        hireContractId: uid,
        status,
        clientName: client ? `${client.firstName} ${client.lastName}` : "",
        clientProfilePhoto: client ? client.profilePhoto : null,
        teamName: team ? (team.name || team.uid) : null,
        teamProfilePhoto: team ? team.profilePhoto : null,
        businessName: business ? business.name : "",
        dateCreated: convertUnixTsToDate(createdAt.seconds),
        currency,
        monthlyBudget,
        onClick: () => handleRowClick(contractId),
      });
    })
    .filter(({ teamName }) => Boolean(teamName));
}

export default function MyContractsTab(props) {
  const {teams, loadingTeams} = props;
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const classes = useStyles(props);
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  const [hireContracts, loadingHireContracts] = useFetchFirestore(
    firebase
      .hireContracts()
      .where("freelancerId", "==", authUser.uid)
      .where("applicable", "==", true)
      .limit(10),
    {
      type: "collection", 
      transformResult: r => r
        .map(hcr => ({
          ...hcr,
          monthlyBudget: formatCentsToDisplayPrice(hcr.monthlyBudget),
        })) 
    }
  );

  const businessIds = hireContracts.map(({businessId}) => businessId);
  const businessRef = businessIds.length > 0 ? firebase.businesses().where("uid", "in", businessIds) : null;
  const [businesses, loadingBusinesses] = useFetchFirestore(
    businessRef,
    {
      type: "collection", 
      stopLoading: !loadingHireContracts && hireContracts.length === 0
    }
  );

  const clientIds = businesses.map(({ownerId}) => ownerId);
  const clientsRef = clientIds.length > 0 ? firebase.users().where("uid", "in", clientIds) : null;
  const [clients, loadingClients] = useFetchFirestore(
    clientsRef,
    {type: "collection", stopLoading: !loadingBusinesses && businesses.length === 0}
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = contractId =>
    navigate(routes.freelancerHireContract.replace(":contractId", contractId));

  console.log("hireContracts", hireContracts)
  console.log("teams", teams)

  if (loadingHireContracts || loadingTeams || loadingBusinesses || loadingClients) {
    return (
      <LoadingIndicator
        message="Loading my contracts..."
        topSpacing={10}
        inheritHeight
      />
    )
  } else if (hireContracts.length === 0) {
    return (
      <EmptyStateView
        text="No team contracts found"
        icon={<Gavel/>}
        topSpacing={5}
      />
    )
  }

  const rows = createContractsRows({hireContracts, authUser, teams, businesses, clients, handleRowClick});
  console.log("jjj rows", rows)
  return (
    <Box>
      <TableContainer>
        <Table
          className={classes.table}
          size='medium'
        >
          {/*<caption>{`Invite freelancers to apply through `}{<Link to={publicUrl} color="primary">your team*/}
          {/*  profile</Link>}</caption>*/}
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <MyContractRow 
                  idx={idx}
                  key={idx}
                  {...row} 
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}

MyContractsTab.propTypes = {
  teams: PropTypes.array.isRequired,
  loadingTeams: PropTypes.bool.isRequired,
};
