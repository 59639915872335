// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {getCurrencyFormat} from "../../../../../utils/formatting";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 'auto',
    marginBottom: 'auto',
    // minWidth: 120,
    // minHeight: 50,
  },
  label: {
    opacity: 0.65,
  }
}));

export default function TotalBudget(props) {
  const {totalBudget, currency} = props;

  const classes = useStyles(props);

  return (
    <Typography className={classes.root}>
      <span className={classes.label}>Total monthly budget: </span>{getCurrencyFormat(totalBudget, currency)}
    </Typography>
  );
}

TotalBudget.propTypes = {
  totalBudget: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};
