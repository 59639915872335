// React
import React from "react";
import {navigate} from "gatsby";
// Context
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
// utils
import {convertUnixTsToDate} from "../../utils/time";
// Components
import LoadingIndicator from "../../components/LoadingIndicator";
import MainTable from "../../components/MainTable";
// Constants
import * as routes from "../../constants/routes";
// Services
import {createAuthToken} from "../../services/admin";
// Material UI
import MuiLink from "@material-ui/core/Link";

function getHeadCells() {
  return [
    {
      id: "name",
      isNumeric: false,
      disablePadding: true,
      label: "User id",
      class: "firstCell",
    },
    {id: "email", isNumeric: false, label: "Email", class: "middleCell"},
    {
      id: "createdAt",
      isDate: true,
      isNumeric: false,
      label: "Last updated",
      class: "lastCell",
    },
  ];
}

function createRows(users, handleViewUser) {
  return users.map(({uid, email, createdAt}) => ({
    id: uid,
    name: (
      <MuiLink onClick={() => handleViewUser(uid)} color="primary">
        {uid}
      </MuiLink>
    ),
    email,
    createdAt: convertUnixTsToDate(createdAt.seconds),
  }));
}

export default function AdminUsersPage() {
  const firebase = React.useContext(FirebaseContext);
  const [users, loadingUsers] = useFetchFirestore(
    firebase
      .users(),
    {type: "collection"}
  );

  const handleViewUser = (ownerId) => {
    firebase.auth.currentUser
      .getIdToken(true)
      .then((idToken) => createAuthToken(ownerId, idToken))
      .then(({token}) => {
        return firebase.auth.signInWithCustomToken(token)
      })
      .then(() => navigate(routes.app));
  };

  return loadingUsers ? (
    <LoadingIndicator/>
  ) : (
    <div style={{margin: "90px 30px 30px 30px"}}>
      <MainTable
        headCells={getHeadCells()}
        rows={createRows(users, handleViewUser)}
        defaultRowsPerPage={10}
        emptyStateText="No data to show yet"
        defaultOrderBy="cost"
        defaultOrder="desc"
        hasTopBorder
      />
    </div>
  );
}
