// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Add} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {getCurrencyFormat} from "../../../utils/formatting";
import {convertUnixTsToDate} from "../../../utils/time";
import ConfirmToolbar from "../../../components/ConfirmToolbar";

const useHeaderStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  submitButton: {
    marginLeft: theme.spacing(1)
  },
  greyText: {
    opacity: 0.6,
  }
}));

function HeaderRight(props) {
  const {status, processing, disableAddSessions, becameStatusPaymentRequestedAt, onAddSession, onSubmitBill, totalAmount, currency} = props;
  const classes = useHeaderStyles(props);
  const [confirmSubmit, setConfirmSubmit] = React.useState(false);

  const handleToggleConfirmSubmit = () => setConfirmSubmit(cs => !cs);

  if (status === "draft") {
    return (
      confirmSubmit ? (
        <ConfirmToolbar
          onConfirm={onSubmitBill}
          confirmButtonText={`Request ${getCurrencyFormat(totalAmount, "eur", {convertToDecimals: true})} payout`}
          onCancel={handleToggleConfirmSubmit}
          variant="dense"
          disableGutters
        />
      ) : (
        <div className={classes.flexContainer}>
          <Button
            startIcon={<Add/>}
            color="primary"
            variant="outlined"
            onClick={onAddSession}
            disabled={disableAddSessions}
          >
            Session
          </Button>
          <Button
            className={classes.submitButton}
            onClick={handleToggleConfirmSubmit}
            color="primary"
            variant="contained"
            disabled={totalAmount === 0 || processing}
          >
            {`Submit ${getCurrencyFormat(totalAmount, currency, {convertToDecimals: true})} for payout`}
          </Button>
        </div>
      )
    )
  } else if (status === "paymentRequested") {
    return (
      <div>
        <Typography
          align="right">{`Total amount: ${getCurrencyFormat(totalAmount, "eur", {convertToDecimals: true})}`}</Typography>
        <Typography
          variant="caption"
          component="p"
          className={classes.greyText}
          align="right"
        >
          {`Submitted on ${convertUnixTsToDate(becameStatusPaymentRequestedAt.seconds).toLocaleDateString()}`}
        </Typography>
      </div>
    )
  } else if (status === "paymentFailed") {
    return (
      <div>
        <Typography align="right">Payment failed</Typography>
        <Typography
          variant="caption"
          component="p"
          className={classes.greyText}
          align="right"
        >
          X Y has been instructed to retry payment
        </Typography>
      </div>
    )
  } else if (status === "paid") {
    return (
      <div>
        <Typography align="right">Paid on xx/xx/xxxx</Typography>
        {/*<Typography>X Y has been notified to retry payment</Typography>*/}
      </div>
    )
  }
  return <div>x</div>
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  sessionsHeader: {
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export default function SessionsHeader(props) {
  const {status, isBillOwner} = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" component="h2" className={classes.sessionsHeader}>
        Sessions
      </Typography>
      {isBillOwner && status !== "removed" && status !== "paid" && (
        <HeaderRight {...props} />
      )}
    </div>
  );
}

SessionsHeader.propTypes = {
  onAddSession: PropTypes.func.isRequired,
};
