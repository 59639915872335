// React
import React from "react";
import PropTypes from "prop-types";

// other libs
import SessionsHeader from "./SessionsHeader";
import BillSessionsTable from "../../../components/BillSessionsTable";
import {Timer} from "@material-ui/icons";
import EmptyStateView from "../../../components/EmptyStateView";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {hexToRgb} from "../../../utils/colors";
import {makeStyles} from "@material-ui/core/styles";
import * as routes from "../../../constants/routes";
import Link from "../../../components/Link";

const useStyles = makeStyles((theme) => ({
  calloutPaper: {
    padding: theme.spacing(2),
    background: `rgba(${hexToRgb(theme.palette.error.main)}, 0.1)`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderColor: theme.palette.error.main,
  },
  calloutTitle: {
    color: theme.palette.error.main,
    fontWeight: 700,
    marginBottom: theme.spacing(1)
  }
}));

export default function SessionsList(props) {
  const {sessions, bill, activeHireContract, handleAddSession, handleSelectSession, handleSubmitBill, isBillOwner} = props;
  const classes = useStyles(props);

  return (
    <div>
      <SessionsHeader
        {...bill}
        addingSessionsDisabled={!activeHireContract}
        onAddSession={handleAddSession}
        onSubmitBill={handleSubmitBill}
        isBillOwner={isBillOwner}
      />
      {!activeHireContract && bill.status === "draft" && (
        <Paper className={classes.calloutPaper}>
          <Typography className={classes.calloutTitle}>Adding sessions is disabled</Typography>
          <Typography>
            {'The '}
            {/* Todo: Link to hireContract */}
            <Link
              color="primary"
              to={routes.freelancerHireContract.replace(":contractId", bill.contractId)}
            >
              contract linked to this bill
            </Link>
            {' has been stopped. Proceed by submitting your existing sessions for payout.'}
          </Typography>
        </Paper>
      )}

      {sessions.length > 0 ? (
        <BillSessionsTable
          sessions={sessions}
          onRowClick={handleSelectSession}
          bill={bill}
        />
      ) : (
        <EmptyStateView
          text="Add your first session"
          icon={<Timer/>}
          topSpacing={5}
        />
      )}
    </div>
  );
}

SessionsList.propTypes = {
  sessions: PropTypes.array.isRequired,
};
