// React
import React from "react";
import {navigate} from "gatsby";

// Components
import BodyContainer from "../../components/BodyContainer";
import ClientContracts from "./ClientContracts";
import ClientRequests from "./ClientRequests";
import TeamsTabs from "./TeamsTabs";
import * as routes from "../../constants/routes";
import queryString from "query-string";
import {useLocation} from "@reach/router";

// Material UI
import { Typography } from "@material-ui/core";

function TeamTabContent(props) {
  const { activeTab } = props;
  if (activeTab === "contracts") {
    return <ClientContracts />;
  } else if (activeTab === "requests") {
    return <ClientRequests />;
  }
  return (
    <Typography>
      Oops... something went wrong. Try refreshing your browser. Contact support if the issue persists.
    </Typography>
  )
}

export default function ClientTeamsPage(props) {

  const { match } = props;
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  const handleToggleTab = (event, value) =>
    navigate(queryString.stringifyUrl({
      url: routes.clientTeamsTab.replace(":tab", value),
      query: parsedQueries,
    }));

  return (
    <BodyContainer topGutter bottomGutter>
      <TeamsTabs 
        activeTab={match.tab} 
        onToggleTab={handleToggleTab}
      />
      <TeamTabContent 
        activeTab={match.tab}
      />
    </BodyContainer>
  );
}

