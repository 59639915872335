// React
import React from "react";
import {navigate} from "gatsby";
//import PropTypes from 'prop-types';

// Components
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";
import BodyContainer from "../../components/BodyContainer";
import PageNotFound from "../../components/PageNotFound";
import LoadingIndicator from "../../components/LoadingIndicator";
import TeamHeader from "./TeamHeader";
import TeamLeadTabs from "./TeamLeadTabs";
import {TeamProfile, TeamApplicants, TeamMembers} from "./TeamLeadTabsContent";
import TeamMemberView from "./TeamMemberView";
import EmptyStateView from "../../components/EmptyStateView";

// Material UI
import {VisibilityOff} from "@material-ui/icons";

// Constant
import * as routes from "../../constants/routes";

function getTabContent({activeTab, team}) {
  const teamApproved = team.status === "approved";
  if (activeTab === "profile") {
    return <TeamProfile team={team}/>;
  }
  if (teamApproved && activeTab === "members") {
    return <TeamMembers team={team}/>;
  }
  if (teamApproved && activeTab === "applicants") {
    return <TeamApplicants team={team}/>;
  }
  return (
    <EmptyStateView
      text="We could not find that page or you do not have access."
      icon={<VisibilityOff/>}
      topSpacing={5}
    />
  );
}

export default function FreelancerTeamPage(props) {
  const {match} = props;
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);
  const teamRef = (match.teamId) ? firebase.team(match.teamId) : null;
  const [team, loadingTeam] = useFetchFirestore(
    teamRef, {listen: true, stopLoading: !match.teamId}
  );
  const [activeTab, setActiveTab] = React.useState(null);

  const isTeamLead = team ? team.teamLeadId === authUser.uid : false;
  React.useEffect(() => {
    if (team) {
      if (match.tab) {
        // Only team leads can see tabbed content
        if (isTeamLead) {
          setActiveTab(match.tab)
        } else  {
          return navigate(
            routes.freelancerTeam
              .replace(":teamId", team.uid)
          )
        }
      } else if (isTeamLead) {
        return navigate(
          routes.freelancerTeamTab
            .replace(":teamId", team.uid)
            .replace(":tab", "profile")
        )
      }
    }
  }, [match.tab, team, isTeamLead]);

  const handleToggleTab = (event, value) => navigate(
    routes.freelancerTeamTab
      .replace(":teamId", team.uid)
      .replace(":tab", value)
  );

  if (loadingTeam) {
    return (
      <LoadingIndicator
        message="Loading team..."
        topSpacing={5}
        inheritHeight
      />
    )
  } else if (!team) {
    return (
      <PageNotFound/>
    )
  }

  if (isTeamLead) {
    return (
      <BodyContainer topGutter bottomGutter>
        <TeamHeader team={team}/>
        <TeamLeadTabs
          team={team}
          activeTab={activeTab}
          onToggleTab={handleToggleTab}
        />
        {getTabContent({activeTab, team})}
      </BodyContainer>
    )
  }

  return (
    <BodyContainer topGutter bottomGutter>
      <TeamHeader team={team} />
      <TeamMemberView team={team} />
    </BodyContainer>
  );
}

FreelancerTeamPage.propTypes = {};
