// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //minWidth: '100%',
    //maxWidth: '100%',
    borderBottom: `2px solid ${theme.palette.borders}`,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  firstCell: {
    paddingLeft: 30,
  },
  lastCell: {
    paddingRight: 30,
  },
}));

export default function EnhancedHead(props) {
  const classes = useStyles();

  const {cells, order, orderBy, onRequestSort, rowCount, numSelected} = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.root}>
      <TableRow style={{background: "white", minWidth: "100%"}}>
        {cells.map((headCell, idx) =>
          headCell.isCheckbox && headCell.onSelectAll ? (
            <TableCell
              key={headCell.id}
              align="left"
              padding="default"
              className={classes.firstCell}
            >
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={(e) => headCell.onSelectAll(e)}
              />
            </TableCell>
          ) : (
            <TableCell
              className={classes[headCell.class]}
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
              // colSpan={headCell.colspan}
            >
              {headCell.isCheckbox && headCell.onSelectAll ? (
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={(e) => headCell.onSelectAll(e)}
                />
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {" "}
                      {/* style={{maxWidth: headCell.maxWidth || 0}} */}
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedHead.propTypes = {
  cells: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};
