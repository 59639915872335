// React
import React from "react";
import PropTypes from "prop-types";

// Components
import DraftHeader from "./DraftHeader";
import CalendlyScheduler from "./CalendlyScheduler";
import SkillsPanel from "../../../components/SettingsPanels/SkillsPanel";
import AdditionalRequirements from "../../../components/SettingsPanels/AdditionalRequirements";
import {FirebaseContext} from "../../../components/Firebase";

// Material UI
import {Box} from "@material-ui/core";

function getStepIdx(request) {
  const {skills, additionalRequirements} = request;
  if (!skills) {
    return 0;
  }
  if (!additionalRequirements) {
    return 1;
  }
  return 2;
}

export default function DraftRequest(props) {
  const {request} = props;
  const firebase = React.useContext(FirebaseContext);
  const [stepIdx, setStepIdx] = React.useState(getStepIdx(request));

  React.useEffect(() => {
    // const requestFields = {
    //   skills: request.skills,
    //   additionalRequirements: request.additionalRequirements,
    // };
    setStepIdx(getStepIdx(request))
  }, [request]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SkillsPanel
            firebaseRef={firebase.request(request.uid)}
            value={request.skills}
            request={request}
            isExpanded
            draftMode
          />
        );
      case 1:
        return (
          <AdditionalRequirements
            firebaseRef={firebase.request(request.uid)}
            value={request.additionalRequirements}
            request={request}
            isExpanded
            draftMode
          />
        );
      case 2:
        return (
          <CalendlyScheduler/>
        );
      default:
        return "Unknown step";
    }
  };

  let nrOfSteps = 3;
  const isFinalStep = stepIdx === (nrOfSteps - 1);
  return (
    <Box>
      <DraftHeader
        stepIdx={stepIdx}
        nrOfSteps={nrOfSteps}
        hideTopPadding={isFinalStep}
      />
      {getStepContent(stepIdx)}
    </Box>
  );
}

DraftRequest.propTypes = {
  uid: PropTypes.string,
};
