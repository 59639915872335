// React
import React from "react";

// Components
import BodyContainer from "../../../components/BodyContainer";
import RequestTableToolbar from "./RequestTableToolbar";
import RequestsTable from "./RequestsTable";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {BusinessContext} from "../../../components/Session";
import {FirebaseContext, useFetchFirestore} from "../../../components/Firebase";

export default function ClientRequests() {

  const {selectedBusiness} = React.useContext(BusinessContext);
  const firebase = React.useContext(FirebaseContext);

  const [requests, loadingRequests] = useFetchFirestore(
    firebase
      .requests()
      .where("businessId", "==", selectedBusiness.uid)
      .orderBy("createdAt", "desc")
      .limit(10),
    {type: "collection", listen: true}
  );

  if (loadingRequests) {
    return (
      <BodyContainer topGutterSpacing={10} bottomGutter>
        <LoadingIndicator message="Loading requests..." inheritHeight/>
      </BodyContainer>
    )
  }

  return (
    <BodyContainer topGutter BottomGutter>
      <RequestTableToolbar/>
      <RequestsTable 
        requests={requests}
        loading={loadingRequests}
      />
    </BodyContainer>
  );
}

