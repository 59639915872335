// React
import React from "react";
import PropTypes from "prop-types";

// Components
import { FirebaseContext } from "../Firebase";
import ContractVersionEditor from "./ContractVersionEditor";
import LoadingIndicator from "../LoadingIndicator";

// Utils
import { serviceFeeRate } from "../../constants/settings";
import {formatCentsToDisplayPrice} from "../../utils/formatting";

function getInitialHireContracts(teamLead) {
  return [
    {
      freelancerId: teamLead.uid,
      hourlyRate: formatCentsToDisplayPrice(teamLead.hourlyRate),
      monthlyBudget: 0,
      salesTax: {
        option: "none",
        rate: 0,
        type: null
      },
      serviceFee: {
        rate: serviceFeeRate,
        salesTax: {
          option: teamLead.country === "NL" ? "setTax" : "none",
          rate: teamLead.country === "NL" ? 21 : 0,
          type: "VAT",
        }
      },
      transactionFee: {
        type: "passedOn",
        rate: null,
        salesTax: {
          option: teamLead.country === "NL" ? "setTax" : "none",
          rate: teamLead.country === "NL" ? 21 : 0,
          type: "VAT",
        }
      }
    }
  ]
};

function getHireContractIdsStr(hireContracts) {
  return hireContracts 
    ? Object.keys(hireContracts)
        .map(userId => hireContracts[userId])
        .join('_')
    : "";
}

export function useExpandedHireContracts(hireContracts, teamLead) {
  const [expandHireContracts, setExpandedHireContracts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const firebase = React.useContext(FirebaseContext);
  const hireContractsIdsStr = getHireContractIdsStr(hireContracts);

  React.useEffect(() => {

    const hireContractIdsArr = hireContractsIdsStr
      .split('_')
      .filter(f => f !== "")

    console.log("hireContractIdsArr heeeeerrrrrrr", hireContractIdsArr)
    const hireContractQueries = hireContractIdsArr.length > 0 
      ? hireContractIdsArr
          .map(hireContractId => firebase.hireContract(hireContractId).get())
      : [];

    console.log("hireContractQueries heeeeerrrrrrr", hireContractQueries)
    if (hireContractQueries.length > 0) {
      console.log('loading hireContracts heeeeerrrrrrr')
      setLoading(true);
      Promise.all(hireContractQueries)
        .then((res) => {
          const formattedHireContracts = res
            .map(snap => { 
              const hc = firebase.formatSnapshot(snap);
              return {
                ...hc,
                hourlyRate: formatCentsToDisplayPrice(hc.hourlyRate),
                monthlyBudget: formatCentsToDisplayPrice(hc.monthlyBudget),
              }
            });
          setExpandedHireContracts(formattedHireContracts);
          setLoading(false);
        })
    } else {
      setLoading(false);
    }
  }, [firebase, hireContractsIdsStr])

  const result = expandHireContracts.length > 0 
    ? expandHireContracts 
    : getInitialHireContracts(teamLead)
  return [result, loading];
}

export default function ContractVersion(props) {

  const {
    team, 
    teamLead,
    business, 
    handleResetDraft, 
    contract, 
    contractVersion,
    handleStartEditing, 
    handleStopContract, 
    disableActions
  } = props;

  const [expandedHireContracts, loadingExpandedHireContracts] = useExpandedHireContracts(contractVersion.hireContracts, teamLead);

  return loadingExpandedHireContracts ? (
    <LoadingIndicator 
      message="Loading contracts..." 
      inheritHeight 
      topSpacing={10}
      bottomSpacing={10}
    />
  ) : (
    <ContractVersionEditor 
      contract={contract}
      business={business}
      team={team}
      handleResetDraft={handleResetDraft}
      handleStartEditing={handleStartEditing}
      handleStopContract={handleStopContract}
      disableActions={disableActions}
      versionId={contractVersion.uid}
      {...contractVersion}
      hireContracts={expandedHireContracts}
    />
  );
}

ContractVersion.propTypes = {
  team: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  hideHireBudgets: PropTypes.bool,
  contractVersion: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
};
