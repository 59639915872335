// React
import React from "react";
import PropTypes from "prop-types";

// Components
import HeaderEditor from "./HeaderEditor";
import HeaderView from "./HeaderView";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: ({gutterTop, gutterBottom, alignVertically}) => ({
    width: "100%",
    maxWidth: 500,
    paddingTop: theme.spacing(gutterTop || 0),
    paddingBottom: theme.spacing(gutterBottom || 0),
    marginTop: alignVertically ? "auto" : 0,
    marginBottom: alignVertically ? "auto" : 0,
  }),
}));

export default function EditableHeader(props) {
  const {
    defaultIsEditing,
    title,
    titlePath,
    titleMaxChar,
    editTitleRef,
    fontVariant,
    fontComponent,
  } = props;
  const [isEditing, setIsEditing] = React.useState(Boolean(defaultIsEditing));
  const handleToggleIsEditing = () => setIsEditing((v) => !v);
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {isEditing ? (
        <HeaderEditor
          defaultTitle={title}
          titleMaxChar={titleMaxChar}
          editTitleRef={editTitleRef}
          titlePath={titlePath}
          onEditTitleClick={handleToggleIsEditing}
        />
      ) : (
        <HeaderView
          title={title}
          fontVariant={fontVariant}
          fontComponent={fontComponent}
          handleClick={editTitleRef && handleToggleIsEditing}
          isEditable={Boolean(editTitleRef)}
        />
      )}
    </div>
  );
}

EditableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleMaxChar: PropTypes.number,
};
