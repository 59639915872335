// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StatusLabel from "../../StatusLabel";
import ConfirmToolbar from "../../ConfirmToolbar";
import { AuthUserContext } from "../../Session";

// Material UI
import { Box, Card, CardHeader, CardContent, CardActions, Button, 
  Typography, Divider, CircularProgress } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";

const useActionsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  leftButton: {
    marginRight: theme.spacing(1)
  },
  revokeButton: {
    color: theme.palette.error.main,
  }
}));

function CardActionsContainer(props) {
  const {
    status, 
    becameStatusPendingBy, 
    submittingDraft,
    handleSave, 
    handleCancel, 
    handleRevoke, 
    handleReject,
    handleActivate,
    handleStopContract, 
    handleStartEditing, 
    disableActions
  } = props;
  const authUser = React.useContext(AuthUserContext);
  const classes = useActionsStyles(props);
  const [confirmStop, setConfirmStop] = React.useState(false);
  const handleToggleConfirmStop = () => setConfirmStop(v => !v);

  console.log("qqq status", status);

  if (status === "pending") {
    if (becameStatusPendingBy === authUser.uid) {
      return (
        <CardActions className={classes.root}>
          <Button className={classes.revokeButton} onClick={handleRevoke}>Revoke proposal</Button>
        </CardActions>
      )
    } else {
      return (
        <CardActions className={classes.root}>
          <Button className={classes.leftButton} onClick={handleReject}>Reject</Button>
          <Button color="primary" onClick={handleActivate}>Accept and activate</Button>
        </CardActions>
      )
    }
  }

  if (status === "draft") {
    return (
      <CardActions className={classes.root}>
        <Button 
          className={classes.leftButton} 
          onClick={handleCancel}
          disabled={submittingDraft}
        >
          Cancel
        </Button>
        <Button 
          color="primary" 
          onClick={handleSave}
          startIcon={
            submittingDraft 
              ? <CircularProgress size={15} /> 
              : null
          }
          disabled={submittingDraft}
        >
          Submit
        </Button>
      </CardActions>
    )
  }

  if (status === "revoked" || status === "rejected" || status === "stopped") {
    return (
      <CardActions className={classes.root}>
        <Button 
          color="primary" 
          onClick={handleStartEditing} 
          disabled={disableActions}
        >
          Edit contract
        </Button>
      </CardActions>
    )
  }

  if (status === "active" && confirmStop) {
    return (
      <CardActions className={classes.root}>
        <ConfirmToolbar
          onConfirm={handleStopContract}
          confirmButtonText="Stop contract"
          onCancel={handleToggleConfirmStop}
          variant="dense"
          disableGutters
        />
      </CardActions>
    )
  }

  return (
    <CardActions className={classes.root}>
      <Button 
        className={classes.leftButton} 
        onClick={handleToggleConfirmStop} 
        disabled={disableActions}
      >
        Stop contract
      </Button>
      <Button 
        color="primary" 
        onClick={handleStartEditing} 
        disabled={disableActions}
      >
        Edit contract
      </Button>
    </CardActions>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  cardHeaderTitleBox: {
    display: 'flex',
  },
  cardHeaderTitleTypo: {
    marginRight: theme.spacing(2),
  }
}));

export default function ContractVersionCard(props) {

  const {children, versionNumber, status, isApplicableVersion} = props;
  const theme = useTheme()
  const classes = useStyles(props);

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Box className={classes.cardHeaderTitleBox}>
            <Typography className={classes.cardHeaderTitleTypo} variant="h6">
              {`Version #${versionNumber}`}
            </Typography>
            <StatusLabel
              value={status}
              shape="square"
              color={theme.palette.statusColors[status]}
              // popoverText={getStatusText(billStatus, bill)}
            />
          </Box>
        }
        disableTypography
      />
      <Divider />
      <CardContent>
        {children}
      </CardContent>

      {/* 
      Only show actions if:
        1. It's the currently applicable version; or 
        2. The version is draft/pending (read: ahead of applicable version)  
      */}
      {(isApplicableVersion || status === "draft" || status === "pending") && (
        <Box>
          <Divider />
          <CardActionsContainer {...props} />
        </Box>
      )}
    </Card>
  );
}

ContractVersionCard.propTypes = {
  version: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
};