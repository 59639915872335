// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BodyContainer from "../../components/BodyContainer";
import Contract from "../../components/Contract";

// constants
import { AuthUserContext, BusinessContext } from "../../components/Session";

export default function ClientTeamsContractPage(props) {
  const {match} = props;
  const {selectedBusiness} = React.useContext(BusinessContext);
  const authUser = React.useContext(AuthUserContext);

  return (
    <BodyContainer topGutter bottomGutter>
      <Contract
        contractId={match.contractId}
        business={selectedBusiness}
        client={authUser}
        isEditing={false}
      />
    </BodyContainer>
  );
}

ClientTeamsContractPage.propTypes = {
  match: PropTypes.object.isRequired,
};
