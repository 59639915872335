// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {Box, Toolbar, Button} from "@material-ui/core";
import {ToggleButtonGroup, ToggleButton} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import FilterBar from "../../components/FilterBar";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  leftContainer: {
    display: "flex",
  },
  submitBtn: {
    minWidth: 90
  },
  typeToggleGroup: {
    height: theme.spacing(4.5),
    marginRight: theme.spacing(2),
    minWidth: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  toggleButton: {
    fontSize: 11,
  }
}));

export default function FreelancerBillsToolbar(props) {
  const {handleCreateBill, statusOptions, handleSearch, filters, handleSelectFilter, leadingTeam} = props;
  const classes = useStyles();

  return (
    <Toolbar className={classes.root}>
      <Box className={classes.leftContainer}>
        {leadingTeam && (
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={filters.type}
            exclusive
            onChange={(prop, value) => handleSelectFilter("type", value)}
            className={classes.typeToggleGroup}
          >
            <ToggleButton value="myBills" classes={{ sizeSmall: classes.toggleButton }}>My bills</ToggleButton>
            <ToggleButton value="teamBills" classes={{ sizeSmall: classes.toggleButton }}>Team bills</ToggleButton>
          </ToggleButtonGroup>
        )}
        <FilterBar
          searchQueryPlaceholder="Search for bills"
          onSearch={handleSearch}
          filterOneLabel="Status"
          filterOneOptions={statusOptions}
          filterOneValue={filters.status}
          onFilterOneChange={(value) => handleSelectFilter("status", value)}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon className={classes.addBtnIcon}/>}
        className={classes.button}
        onClick={handleCreateBill}
      >
        New bill
      </Button>
    </Toolbar>
  );
};

FreelancerBillsToolbar.propTypes = {
  minutesSelected: PropTypes.number,
};