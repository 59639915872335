// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Components
import EnhancedTableHead from "../../../../components/EnhancedTableHead";
import {FirebaseContext, useFetchFirestore} from "../../../../components/Firebase";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import {AuthUserContext} from "../../../../components/Session";
import EmptyStateView from "../../../../components/EmptyStateView";
import TeamContractRow from "./TeamContractRow";
import Link from "../../../../components/Link";

// MUI
import {Box, Typography, TableContainer,Table,TableBody,TablePagination} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {Gavel} from "@material-ui/icons";

// Utils
import {convertUnixTsToDate} from "../../../../utils/time";
import {stableSort, getSorting} from "../../../../utils/sorting";
import {formatCentsToDisplayPrice} from "../../../../utils/formatting";
import * as routes from "../../../../constants/routes";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    width: '100%',
    marginTop: topGutterSpacing ? theme.spacing(topGutterSpacing) : 0,
    marginBottom: bottomGutterSpacing ? theme.spacing(bottomGutterSpacing) : 0,
  }),
  leadingTeamText: {
    marginBottom: theme.spacing(2),
  }
}));

const headCells = [
  {
    id: "clientName",
    label: "Client",
  },
  {
    id: "status",
    label: "Contract status",
  },
  {
    id: "noHireContracts",
    label: "# Hire contracts",
  },
  {
    id: "monthlyBudget",
    label: "Monthly budget",
  },
];

function createContractsRows({contracts, applicableVersions, authUser, businesses, clients, handleRowClick}) {
  return contracts
    .map(({uid, createdAt, businessId, status, currency}) => {
      const business = businesses.filter(b => b.uid === businessId)[0];
      const client = clients.filter(c => c.uid === business.ownerId)[0];
      const applicableVersion = applicableVersions.filter(av => av.contractId === uid)[0];
      return ({
        uid,
        contractId: uid,
        status,
        clientName: client ? `${client.firstName} ${client.lastName}` : "",
        clientProfilePhoto: client ? client.profilePhoto : null,
        businessName: business ? business.name : "",
        dateCreated: convertUnixTsToDate(createdAt.seconds),
        currency,
        monthlyBudget: applicableVersion ? applicableVersion.monthlyBudget : 0,
        noHireContracts: applicableVersion ? Object.keys(applicableVersion.hireContracts).length : 0,
        onClick: () => handleRowClick(uid),
      });
    });
}

export default function TeamContractsTab(props) {
  const {leadingTeam, loadingTeams} = props;
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const classes = useStyles(props);
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  const contractsRef = leadingTeam ? (
    firebase
      .contracts()
      .where(`freelancers.${authUser.uid}`, "==", true)
      .where(`teamId`, "==", leadingTeam.uid)
      .limit(10)
  ) : null;
  const [contracts, loadingContracts] = useFetchFirestore(
    contractsRef,
    {type: "collection", stopLoading: !loadingTeams && !leadingTeam}
  );

  const [applicableVersions, setApplicableVersions] = React.useState([]);
  const [loadingApplicableVersions, setLoadingApplicableVersions] = React.useState(true);

  const applicableVersionIdsStr = contracts
    .map(({ applicableVersionId }) => applicableVersionId)
    .join("_");

  React.useEffect(() => {

    const applicableVersionIds = applicableVersionIdsStr
      .split('_')
      .filter(f => f !== "")
    
    if (applicableVersionIds.length > 0) {
      const versionQueries = applicableVersionIds
        .map(vid => firebase.contractVersion(vid).get())
      Promise.all(versionQueries)
        .then(res => {
          const formattedVersions = res
            .map(snap => { 
              const fv = firebase.formatSnapshot(snap);
              return {
                ...fv,
                monthlyBudget: formatCentsToDisplayPrice(fv.monthlyBudget),
              }
            });
          setApplicableVersions(formattedVersions)
          setLoadingApplicableVersions(false)
        })
    } else {
      setApplicableVersions([])
      !loadingContracts && setLoadingApplicableVersions(false)
    }
  }, [firebase, applicableVersionIdsStr, loadingContracts]);

  const businessIds = contracts.map(({businessId}) => businessId);
  const businessRef = businessIds.length > 0 ? firebase.businesses().where("uid", "in", businessIds) : null;
  const [businesses, loadingBusinesses] = useFetchFirestore(
    businessRef,
    {
      type: "collection", 
      stopLoading: !loadingContracts && contracts.length === 0
    }
  );

  const clientIds = businesses.map(({ownerId}) => ownerId);
  const clientsRef = clientIds.length > 0 ? firebase.users().where("uid", "in", clientIds) : null;
  const [clients, loadingClients] = useFetchFirestore(
    clientsRef,
    {type: "collection", stopLoading: !loadingBusinesses && businesses.length === 0}
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = contractId =>
    navigate(routes.freelancerTeamContract.replace(":contractId", contractId));

  if (loadingContracts || loadingApplicableVersions || loadingClients) {
    return (
      <LoadingIndicator
        message="Loading team contracts..."
        topSpacing={10}
        inheritHeight
      />
    )
  } else if (contracts.length === 0) {
    console.log("leadingTeam", leadingTeam)
    console.log("Boolean(leadingTeam)", Boolean(leadingTeam))
    return (
      <EmptyStateView
        text={
          Boolean(leadingTeam)
            ? `No contracts found for your team ${leadingTeam.name}`
            : "You are not leading a team yet"
        }
        icon={<Gavel/>}
        topSpacing={5}
      />
    )
  }

  const rows = createContractsRows({contracts, applicableVersions, authUser, businesses, clients, handleRowClick});

  return (
    <Box>
      {leadingTeam && (
        <Typography variant="body2" className={classes.leadingTeamText}>
          {`Manage client contracts for `}
          <Link 
            to={routes.freelancerTeam.replace(":teamId", leadingTeam.uid)}
            color="primary"
            weight="bold"
          >
            {`team ${leadingTeam.name}`}
          </Link>
        </Typography>
      )}
      <TableContainer>
        <Table
          className={classes.table}
          size='medium'
        >
          {/*<caption>{`Invite freelancers to apply through `}{<Link to={publicUrl} color="primary">your team*/}
          {/*  profile</Link>}</caption>*/}
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => <TeamContractRow {...row} idx={idx}/>)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}

TeamContractsTab.propTypes = {
  teams: PropTypes.array.isRequired,
  loadingTeams: PropTypes.bool.isRequired,
};
