// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

// Utils
import SelectorTextfield from "../../SelectorTextfield";

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    marginBottom: theme.spacing(1),
  },
}));

export default function DetailsField(props) {
  const {value, handleChange, isEditing} = props;
  const classes = useStyles(props);

  return (
    <div>
      {isEditing ? (
        <SelectorTextfield
          id="details"
          value={value}
          handleChange={handleChange}
          rowsMax={10}
          nrOfRows={3}
          characterLimit={1000}
          multiline
        />
      ) : (
        <Typography className={classes.fieldLabel} variant="caption">{value}</Typography>
      )}
    </div>
  );
}

DetailsField.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
};
