import React from "react";
import {Redirect, Router, useLocation} from "@reach/router";

// Context
import {withAuthentication, withBusinessContext} from "../components/Session";

// Route types
import AppRoute from "../components/AppRoute";

// Pages
import ClientBillsPage from "../app/ClientBills";
import ClientBillPage from "../app/ClientBill";
import ClientSettingsPage from "../app/ClientSettings";
import ClientTeamsPage from "../app/ClientTeams";
import ClientTeamsContractPage from "../app/ClientTeamsContract";
import ClientTeamsRequestPage from "../app/ClientTeamsRequest";
import ClientCreateBusiness from "../app/ClientCreateBusiness";

// Freelancer Pages
import FreelancerProfileSetupPage from "../app/FreelancerProfileSetup";
import FreelancerContractsPage from "../app/FreelancerContracts";
import FreelancerContractTeamPage from "../app/FreelancerContractTeam";
import FreelancerContractHirePage from "../app/FreelancerContractHire";
import FreelancerLeadsPage from "../app/FreelancerLeads";
import FreelancerLeadRequestPage from "../app/FreelancerLeadRequest";
import FreelancerLeadInvitePage from "../app/FreelancerLeadInvite";


import FreelancerTeamsPage from "../app/FreelancerTeams";
import FreelancerTeamPage from "../app/FreelancerTeam";
import FreelancerInvoicesPage from "../app/FreelancerInvoices";
import FreelancerSettingsPage from "../app/FreelancerSettings";
import FreelancerBillsPage from "../app/FreelancerBills";
import FreelancerBillPage from "../app/FreelancerBill";
import FreelancerBillCreateNewPage from "../app/FreelancerBillCreateNew";
// Shared pages
import Start from "../app/Start";

// Admin Pages
import AdminUsers from "../app/AdminUsers";

// Components
import PageNotFound from "../components/PageNotFound";
import {ManagerNavbar, FreelancerNavbar, AdminNavbar} from "../components/Navbars";

// constants
import * as routes from "../constants/routes";
import queryString from "query-string";

function makeDestination(pathname, parsedQueries) {
  return queryString.stringifyUrl({
    url: pathname,
    query: parsedQueries,
  });
}

function getNavbar(location) {
  if (location.pathname.includes("/freelancer/")) {
    return <FreelancerNavbar/>;
  } else if (location.pathname.includes("/admin/")) {
    return <AdminNavbar/>;
  } 
  const showBusinessSelector = Boolean(
    (location.pathname !== routes.clientCreateBusiness) &&
    (location.pathname !== routes.app) && 
    (location.pathname !== routes.clientSettings)
  )
  return <ManagerNavbar showBusinessSelector={showBusinessSelector}/>;
}

function AppPages() {
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const navbar = getNavbar(location);

  return (
    <div>
      {navbar}
      <Router>
        <AppRoute
          path={routes.app}
          component={Start}
          rolesAllowed={["manager", "freelancer", "admin"]}
        />
        <AppRoute
          path={routes.clientCreateBusiness}
          component={ClientCreateBusiness}
          rolesAllowed={["manager"]}
        />
        <Redirect
          from={routes.clientTeams}
          to={routes.clientTeamsTab.replace(":tab", "contracts")}
          noThrow
        />
        <AppRoute
          path={routes.clientTeamsTab}
          component={ClientTeamsPage}
          rolesAllowed={["manager"]}
          businessRequired
          showBusinessSelector
        />
        <AppRoute
          path={routes.clientTeamsRequest}
          component={ClientTeamsRequestPage}
          rolesAllowed={["manager"]}
          businessRequired
          showBusinessSelector
          navCrumbs={[
            {
              getLabel: () => "Requests",
              getDestination: () => makeDestination(routes.clientTeamsTab.replace(":tab", "requests"), parsedQueries),
            },
            {
              getLabel: (match) => match.requestId,
            }
          ]}
        />
        <AppRoute
          path={routes.clientTeamsContract}
          component={ClientTeamsContractPage}
          rolesAllowed={["manager"]}
          businessRequired
          showBusinessSelector
          navCrumbs={[
            {
              getLabel: () => "Contracts",
              getDestination: () => makeDestination(routes.clientTeamsTab.replace(":tab", "contracts"), parsedQueries),
            },
            {
              getLabel: (match) => match.contractId,
            }
          ]}
        />
        <AppRoute
          path={routes.clientBills}
          component={ClientBillsPage}
          rolesAllowed={["manager"]}
          businessRequired
          showBusinessSelector
        />
        <AppRoute
          path={routes.clientBill}
          component={ClientBillPage}
          rolesAllowed={["manager"]}
          businessRequired
          showBusinessSelector
          navCrumbs={[
            {
              getLabel: () => "Bills",
              getDestination: () => makeDestination(routes.clientBills, parsedQueries),
            },
            {
              getLabel: (match) => match.billId,
            }
          ]}
        />
        <Redirect
          from={routes.clientSettings}
          to={routes.clientSettingsTab.replace(":tab", "business")}
          noThrow
        />
        <AppRoute
          path={routes.clientSettingsTab}
          component={ClientSettingsPage}
          rolesAllowed={["manager"]}
          businessRequired
          showBusinessSelector
        />
        <AppRoute
          path={routes.freelancerBills}
          component={FreelancerBillsPage}
          rolesAllowed={["freelancer"]}
        />
        <AppRoute
          path={routes.freelancerBill}
          component={FreelancerBillPage}
          rolesAllowed={["freelancer"]}
          navCrumbs={[
            {
              getLabel: () => "Bills",
              getDestination: () => makeDestination(routes.freelancerBills, {}),
            },
            {
              getLabel: (match) => match.billId,
            }
          ]}
        />
        <AppRoute
          path={routes.freelancerBillCreateNew}
          component={FreelancerBillCreateNewPage}
          rolesAllowed={["freelancer"]}
          navCrumbs={[
            {
              getLabel: () => "Bills",
              getDestination: () => makeDestination(routes.freelancerBills, {}),
            },
            {
              getLabel: () => "New",
            }
          ]}
        />
        <AppRoute
          path={routes.freelancerProfileSetup}
          component={FreelancerProfileSetupPage}
          rolesAllowed={["freelancer"]}
        />
        <Redirect
          from={routes.freelancerContracts}
          to={routes.freelancerContractsTab.replace(":tab", "hire")}
          noThrow
        />
        <AppRoute
          path={routes.freelancerContractsTab}
          component={FreelancerContractsPage}
          rolesAllowed={["freelancer"]}
        />
        <AppRoute
          path={routes.freelancerTeamContract}
          component={FreelancerContractTeamPage}
          rolesAllowed={["freelancer"]}
          navCrumbs={[
            {
              getLabel: () => "Team contracts",
              getDestination: () => makeDestination(routes.freelancerContractsTab.replace(":tab", "team"), {}),
            },
            {
              getLabel: (match) => `#${match.contractId}`,
            }
          ]}
        />
        <AppRoute
          path={routes.freelancerHireContract}
          component={FreelancerContractHirePage}
          rolesAllowed={["freelancer"]}
          navCrumbs={[
            {
              getLabel: () => "My contracts",
              getDestination: () => makeDestination(routes.freelancerContractsTab.replace(":tab", "hire"), {}),
            },
            {
              getLabel: (match) => `#${match.contractId}`,
            }
          ]}
        />
        <Redirect
          from={routes.freelancerLeads}
          to={routes.freelancerLeadsTab.replace(":tab", "requests")}
          noThrow
        />
        <AppRoute
          path={routes.freelancerLeadsTab}
          component={FreelancerLeadsPage}
          rolesAllowed={["freelancer"]}
        />
        <AppRoute
          path={routes.freelancerRequest}
          component={FreelancerLeadRequestPage}
          rolesAllowed={["freelancer"]}
          navCrumbs={[
            {
              getLabel: () => "Requests",
              getDestination: () => makeDestination(routes.freelancerLeadsTab.replace(":tab", "requests"), {}),
            },
            {
              getLabel: (match) => match.requestId,
            }
          ]}
        />
        <AppRoute
          path={routes.freelancerInvite}
          component={FreelancerLeadInvitePage}
          rolesAllowed={["freelancer"]}
          navCrumbs={[
            {
              getLabel: () => "Invites",
              getDestination: () => makeDestination(routes.freelancerLeadsTab.replace(":tab", "invites"), {}),
            },
            {
              getLabel: (match) => match.inviteId,
            }
          ]}
        />
        <AppRoute
          path={routes.freelancerTeams}
          component={FreelancerTeamsPage}
          rolesAllowed={["freelancer"]}
        />
        <AppRoute
          path={routes.freelancerTeam}
          component={FreelancerTeamPage}
          rolesAllowed={["freelancer"]}
          navCrumbs={[
            {
              getLabel: () => "Teams",
              getDestination: () => makeDestination(routes.freelancerTeams, {}),
            },
            {
              getLabel: (match) => match.teamId,
            }
          ]}
        />
        <AppRoute
          path={routes.freelancerTeamTab}
          component={FreelancerTeamPage}
          rolesAllowed={["freelancer"]}
          navCrumbs={[
            {
              getLabel: () => "Teams",
              getDestination: () => makeDestination(routes.freelancerTeams, {}),
            },
            {
              getLabel: (match) => match.teamId,
            }
          ]}
        />
        <AppRoute
          path={routes.freelancerInvoices}
          component={FreelancerInvoicesPage}
          rolesAllowed={["freelancer"]}
        />
        <Redirect
          from={routes.freelancerSettings}
          to={routes.freelancerSettingsTab.replace(":tab", "personal")}
          noThrow
        />
        <AppRoute
          path={routes.freelancerSettingsTab}
          component={FreelancerSettingsPage}
          rolesAllowed={["freelancer"]}
        />
        <AppRoute
          path={routes.adminUsers}
          component={AdminUsers}
          rolesAllowed={["admin"]}
        />
        <PageNotFound default/>
      </Router>
    </div>  
  );
}

export default withAuthentication(withBusinessContext(AppPages))


