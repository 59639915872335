// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {Tab, Tabs} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import * as routes from "../../constants/routes";
import {AuthUserContext} from "../../components/Session";

function getTabs(team, authUser) {
  return [
    {
      label: "Profile",
      value: "profile",
      route: routes.freelancerTeamTab
        .replace(":teamId", team.uid)
        .replace(":tab", "profile"),
    },
    {
      label: "Members",
      value: "members",
      route: routes.freelancerTeamTab
        .replace(":teamId", team.uid)
        .replace(":tab", "members"),
      disabled: team.status !== "approved",
    },
    {
      label: "Applicants",
      value: "applicants",
      route: routes.freelancerTeamTab
        .replace(":teamId", team.uid)
        .replace(":tab", "applicants"),
      disabled: team.status !== "approved",
    }
  ]
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  tabs: {
    // width: "100%",
    // display: "flex",
    // justifyContent: "space-between",
    // padding: "0px 30px 0px 30px",
    // borderBottom: `1px solid ${theme.palette.borders}`,
  },
  tabRoot: {
    width: 120,
    minWidth: 120,
  },
  wrapper: {
    // height: 45,
    width: 100,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function TeamLeadTabs(props) {
  const {team, activeTab, onToggleTab} = props;
  const classes = useStyles(props);
  const authUser = React.useContext(AuthUserContext);

  return (
    <Tabs
      value={activeTab}
      onChange={onToggleTab}
      classes={{
        root: classes.root,
        indicator: classes.indicator,
        tabs: classes.tabs,
      }}
    >
      {getTabs(team, authUser)
        .map(({label, value, disabled}) =>
          <Tab
            key={value}
            value={value}
            label={label}
            color="primary"
            classes={{
              root: classes.tabRoot,
              wrapper: classes.wrapper,
            }}
            className={classes.tab}
            disabled={disabled}
          />
        )}
    </Tabs>
  );
}

TeamLeadTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onToggleTab: PropTypes.func.isRequired,
};
