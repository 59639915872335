// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {FirebaseContext, useFetchFirestore} from "../Firebase";
import LoadingIndicator from "../LoadingIndicator";
import ContractHeader from "./ContractHeader";
import PageNotFound from "../PageNotFound";
import SimpleDialog from "../SimpleDialog";
import ContractVersion from "../ContractVersion"

// Material UI
import { Box, Button } from "@material-ui/core";

// constants
import { convertUnixTsToDate } from "../../utils/time";
import { AuthUserContext } from "../Session";

export default function Contract(props) {
  const {contractId, client, business} = props;
  const firebase = React.useContext(FirebaseContext);

  const [contract, loadingContract] = useFetchFirestore(
    firebase.contract(contractId),
    {
      listen: true,
      stopLoading: !contractId,
    }
  );

  const [draftVersion, setDraftVersion] = React.useState(null);
  const [contractVersions, loadingContractVersions] = useFetchFirestore(
    firebase.contractVersions()
      .where("contractId", "==", contractId)
      .orderBy("versionNumber", "desc")
      .limit(10),
    {
      type: 'collection',
      listen: true,
    }
  );

  const teamRef = contract ? firebase.team(contract.teamId) : null;
  const [team, loadingTeam] = useFetchFirestore(
    teamRef, 
    { stopLoading: !loadingContract && !contract }
  );
  const teamLeadRef = team ? firebase.user(team.teamLeadId) : null;
  const [teamLead, loadingTeamLead] = useFetchFirestore(teamLeadRef);
  const [requestEditsDialogOpen, setRequestsEditsDialogOpen] = React.useState(false);
  const authUser = React.useContext(AuthUserContext);

  const handleToggleRequestEditsDialog = () => setRequestsEditsDialogOpen(v => !v);

  const handleEdit = (selectedVersion) => {
    const initDraftVersion = {
      uid: 'draft',
      status: 'draft',
      contractId,
      hireContracts: selectedVersion ? selectedVersion.hireContracts : {},
      details: "",
      versionNumber: contract.versionCount + 1,
    }
    console.log("initDraftVersion", initDraftVersion)
    setDraftVersion(initDraftVersion)
  }

  const handleResetDraft = () => {
    setDraftVersion(null)
  }  

  const handleStopContract = (versionId) => {
    firebase.contractVersion(versionId)
      .update({
        status: 'stopped',
        becameStatusStoppedAt: firebase.createTimestamp(),
        becameStatusStoppedBy: authUser.uid,
      })
  }

  if (loadingContract || loadingTeam || loadingTeamLead || loadingContractVersions) {
    return (
      <LoadingIndicator 
        message="Loading contract..." 
        inheritHeight 
        topSpacing={10}
      />
    )
  } else if (!contract || !team || !teamLead) {
    return <PageNotFound/>
  } 

  const createdDate = convertUnixTsToDate(contract.createdAt.seconds);
  let contractVersionsList = contractVersions;
  if (draftVersion) {
    contractVersionsList = [draftVersion, ...contractVersionsList];
  }

  console.log("jjj contractVersionsList", contractVersionsList)
  const nonActiveVersionExists = Boolean(
    draftVersion || 
    contractVersions
      .filter(({ status }) => status === "pending")[0]
  )
  return (
    <Box>
      {requestEditsDialogOpen && teamLead && (
        <SimpleDialog
          open={true}
          id="request-edits-dialog"
          toggleDialog={handleToggleRequestEditsDialog}
          title="Request contract edits"
          description={`Suggest edits to team lead ${teamLead.firstName} ${teamLead.lastName} (${teamLead.email}) who will propopse a new version based on your input.`}
          primaryActionText="OK"
          handlePrimaryAction={handleToggleRequestEditsDialog}
        />
      )}
      <ContractHeader
        contract={contract}
        client={client}
        business={business}
        team={team}
        bottomGutterSpacing={4}
        createdDate={createdDate}
      />
      <Box>
        {contractVersionsList.length > 0 ? (
          // stableSort([contractVersionsList, getSorting("desc", "version"))
          contractVersionsList
            .map(cv => 
              <ContractVersion 
                key={cv.uid} 
                contract={contract}
                team={team}
                teamLead={teamLead}
                business={business}
                handleStartEditing={() => handleEdit(cv)}
                handleStopContract={() => handleStopContract(cv.uid)}
                disableActions={nonActiveVersionExists}
                handleResetDraft={handleResetDraft}
                contractVersion={cv}
              />
            )
        ) : (
          <Button 
            onClick={() => handleEdit()} 
            color="primary" 
            variant="contained"
          >
            Create contract version
          </Button>
        )}
        
      </Box>
    </Box>
  );
}

Contract.propTypes = {
  contractId: PropTypes.string.isRequired,
};
