// React
import React from "react";
import PropTypes from 'prop-types';
import {navigate} from "gatsby";

// Components
import {AuthUserContext} from "../../../components/Session";
import {FirebaseContext, useFetchFirestore} from "../../../components/Firebase";
import FreelancerCard from "../../../components/FreelancerCard";
import FreelancerDialog from "../../../components/FreelancerDialog";
import MemberStatusPaper from "./MemberStatusPaper";
import TeamMemberApply from "./TeamMemberApply";

// Material UI
import {Button} from "@material-ui/core";
import {Edit} from "@material-ui/icons";

// Constants
import * as routes from "../../../constants/routes";
import LoadingIndicator from "../../../components/LoadingIndicator";
import FreelancerProfile from "../../../components/FreelancerProfile";

export default function TeamMemberView(props) {
  const {team} = props;
  const authUser = React.useContext(AuthUserContext);
  const teamMembership = team.members[authUser.uid];
  const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);

  const firebase = React.useContext(FirebaseContext);
  const teamApplicationsRef = firebase.teamApplications()
    .where("teamId", "==", team.uid)
    .where("ownerId", "==", authUser.uid);
  const [application, loadingApplication] = useFetchFirestore(
    teamApplicationsRef,
    {
      type: "collection",
      listen: true,
      transformResult: (res) => res[0],
    }
  );

  const handleToggleProfileDialog = () => setProfileDialogOpen(v => !v);

  const handleEditProfileClick = () => navigate(routes.freelancerSettingsTab.replace(":tab", "experience"));

  if (loadingApplication) {
    return (
      <LoadingIndicator
        message="Loading..."
        inheritHeight
        topSpacing={5}
        bottomSpacing={5}
      />
    )
  }

  return (
    <div>
      {profileDialogOpen && (
        <FreelancerDialog
          freelancer={authUser}
          handleClose={handleToggleProfileDialog}
          DialogBodyContent={
            <FreelancerProfile
              freelancer={authUser}
              team={team}
            />
          }
          DialogActionsContent={
            <Button endIcon={<Edit/>} onClick={handleEditProfileClick}>Edit profile</Button>
          }
        />
      )}
      <MemberStatusPaper 
        teamMembership={teamMembership} 
        application={application} 
      />
      <FreelancerCard
        freelancer={authUser}
        CardActionsContent={
          <Button endIcon={<Edit/>} onClick={handleEditProfileClick}>Edit profile</Button>
        }
        handleActionAreaClick={handleToggleProfileDialog}
        topGutterSpacing={3}
        bottomGutterSpacing={3}
      />
      {!application && (
        <TeamMemberApply team={team} />
      )}
    </div>
  );
}

TeamMemberView.propTypes = {
  team: PropTypes.object.isRequired,
};
