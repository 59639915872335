// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import StatusLabel from "../../components/StatusLabel";
import EditableHeader from "../../components/EditableHeader";
import ConfirmToolbar from "../../components/ConfirmToolbar";
import InvoiceDownloadButton from "../../components/Invoice/InvoiceDownloadButton";

// Material UI
import {
  Avatar,
  CardHeader,
  LinearProgress,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Delete} from "@material-ui/icons";


// Utils/services
import {convertUnixTsToDate} from "../../utils/time";
import { AuthUserContext } from "../../components/Session";

const useStyles = makeStyles((theme) => ({
  root: ({isSelected}) => ({
    minWidth: 240,
    display: 'flex',
    justifyContent: "space-between",
  }),
  rightContainer: {
    display: 'flex',
    justifyContent: "space-between"
  },
  cardContentContainer: {
    display: 'flex',
    justifyContent: "space-between",
    padding: theme.spacing(1)
  },
  cardHeaderRoot: {
    padding: 0,
    minWidth: 180,
    // marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    float: "right",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  selectIcon: ({isSelected}) => ({
    color: isSelected ? theme.palette.primary.main : "inherit",
    marginRight: theme.spacing(1),
    marginTop: "auto",
    marginBottom: "auto",
  })
}));

export default function BillHeader(props) {
  const {bill, sessions, hideRemoveBtn, isBillOwner} = props;
  const firebase = React.useContext(FirebaseContext);
  const [business, loadingBusiness] = useFetchFirestore(firebase.business(bill.businessId));
  const clientId = business ? business.ownerId : null;
  const [clientProfile, loadingClientProfile] = useFetchFirestore(
    clientId ? firebase.user(clientId) : null,
    {stopLoading: !clientId && !loadingBusiness}
  );
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const classes = useStyles(props);
  const theme = useTheme();
  const authUser = React.useContext(AuthUserContext);

  const handleRemoveBill = () => {
    const ts = firebase.createTimestamp();
    // Firstly, exclude all sessions in bill
    firebase.sessions()
      .where("billId", "==", bill.uid)
      .where("excluded", "==", false)
      .get()
      .then((snap) => {
        const sessions = firebase.formatSnapshot();
        let removeQueries = sessions
          .map((s) => 
            firebase.sessions(s.uid)
              .update({ 
                excluded: true, 
                excludedAt: ts,
                excludedReason: 'removed',
                excludedBy: authUser.uid, 
              })
          )
        return Promise.all(removeQueries);
      })
      // Secondly, set the bill status to 'removed'
      .then(() => {
        const payload = {
          lastUpdatedAt: ts,
          becameStatusRemovedAt: ts,
          status: "removed",
        };
        firebase.bill(bill.uid)
          .update(payload)
      })
  };

  const handleToggleConfirmDelete = () => setConfirmDelete(cd => !cd);

  if (loadingBusiness || loadingClientProfile) {
    return <LinearProgress/>
  } else if (!business || !clientProfile) {
    return <Typography>Something went wrong</Typography>
  }

  return (
    <div className={classes.root}>
      <div>
        {isBillOwner ? (
          <EditableHeader
            title={bill.title || `#${bill.uid}`}
            titleMaxChar={50}
            editTitleRef={firebase.bill(bill.uid)}
            fontVariant="h6"
            fontComponent="h1"
            alignVertically
          />
        ) : (
          <Typography 
            variant="h6" 
            component="h1" 
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          >
            {bill.title || `#${bill.uid}`}
          </Typography>
        )}
        
        <div style={{display: "flex", marginTop: 8}}>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
            }}
            avatar={
              (clientProfile.profilePhoto) ? (
                <Avatar src={clientProfile.profilePhoto} className={classes.avatar}/>
              ) : (
                <Avatar className={classes.avatar}>
                  {clientProfile.firstName.substring(0, 1)}
                </Avatar>
              )
            }
            title={`${clientProfile.firstName} ${clientProfile.lastName}`}
            subheader={business.name}
          />
        </div>
      </div>
      <div className={classes.rightContainer}>
        <StatusLabel
          shape="square"
          color={theme.palette.statusColors[bill.status]}
          value={bill.status}
          label={bill.status === "paid" ? convertUnixTsToDate(bill.becameStatusPaidAt.seconds).toLocaleDateString() : null}
          leftGutterSpacing={3}
        />
        {isBillOwner && bill.status === "draft" && confirmDelete && !hideRemoveBtn && (
          <ConfirmToolbar
            onConfirm={handleRemoveBill}
            confirmButtonText="Confirm deletion"
            onCancel={handleToggleConfirmDelete}
            variant="dense"
            maxHeight={48}
            gutterSpacingLeft={1}
          />
        )}
        {isBillOwner && bill.status === "draft" && !confirmDelete && !hideRemoveBtn && (
          <Tooltip title="Delete bill">
            <IconButton
              onClick={handleToggleConfirmDelete}
              style={{height: 48, marginLeft: 8, marginTop: "auto", marginBottom: "auto"}}
            >
              <Delete/>
            </IconButton>
          </Tooltip>
        )}
        {isBillOwner && bill.status === "paid" && (
          <div style={{marginLeft: 8, marginTop: "auto", marginBottom: "auto"}}>
            <InvoiceDownloadButton
              issuedFromDetails={bill.freelancerBillingDetails}
              issuedToDetails={bill.clientBillingDetails}
              bill={bill}
              sessions={sessions}
              feeType="freelancerFees"
            />
          </div>
        )}
      </div>
    </div>
  )
};

BillHeader.propTypes = {
  bill: PropTypes.object.isRequired,
};


