// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StatusLabel from "../../components/StatusLabel";

// MUI
import {Avatar, CardHeader} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  cardHeaderRoot: {
    padding: 0,
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  statusLabelContainer: {
    marginTop: "auto",
    marginBottom: "auto",
  }
}));

function getPopoverText(status) {
  if (status === "invited") {
    return "This client has invited your team to sent a contract proposal"
  }
  return "asdf"
}

export default function RequestHeader(props) {
  const {request, client, teamId} = props;
  const classes = useStyles(props);
  const theme = useTheme();

  const teamStatus = request.teamOptions[teamId].status;
  return (
    <div className={classes.root}>
      <CardHeader
        classes={{
          root: classes.cardHeaderRoot,
        }}
        avatar={
          client.profilePhoto ? (
            <Avatar
              src={client.profilePhoto}
              className={classes.avatar}
            />
          ) : (
            <Avatar className={classes.avatar}>
              {client.firstName.substring(0, 1)}
            </Avatar>
          )
        }
        title={`${client.firstName} ${client.lastName}`}
        titleTypographyProps={{variant: "h6", component: "h1"}}
        subheader={request.businessName}
      />
      <div className={classes.statusLabelContainer}>
        <StatusLabel
          color={theme.palette.statusColors[teamStatus]}
          value={teamStatus}
          popoverText={getPopoverText(teamStatus)}
          shape="square"
        />
      </div>
    </div>
  );
}

RequestHeader.propTypes = {
  request: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};
