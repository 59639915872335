// React
import React from "react";
import {navigate} from "gatsby";

// Context
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";

// Constants
import * as routes from "../../constants/routes";

// Components
import BodyContainer from "../../components/BodyContainer";
import LeadsTabs from "./LeadsTabs";
import {ClientRequestsTab, ClientInvitesTab} from "./TabContent";

// Other libs
import EmptyStateView from "../../components/EmptyStateView";
import {BrokenImage} from "@material-ui/icons";

function getTabContent({activeTab, teams, fetching, authUser}) {
  if (activeTab === "requests") {
    return (
      <ClientRequestsTab
        leadingTeam={
          teams
            .filter(({ teamLeadId }) => teamLeadId === authUser.uid)[0]
        }
        loadingTeams={fetching}
      />
    )
  } else if (activeTab === "invites") {
    return (
      <ClientInvitesTab/>
    )
  }
  return <EmptyStateView text="We could not find that tab" icon={<BrokenImage/>}/>
}

export default function FreelancerLeadsPage(props) {
  const {match} = props;
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);
  const [teams, loadingTeams] = useFetchFirestore(
    firebase
      .teams()
      .where(`members.${authUser.uid}.userId`, "==", authUser.uid),
    {type: "collection"}
  );
  const [activeTab, setActiveTab] = React.useState(match.tab);

  React.useEffect(() => setActiveTab(match.tab), [match.tab]);

  const handleToggleTab = (event, value) => navigate(
    routes.freelancerLeadsTab
      .replace(":tab", value)
  );

  return (
    <BodyContainer bottomGutter topGutter>
      <LeadsTabs
        activeTab={activeTab}
        onToggleTab={handleToggleTab}
      />
      {getTabContent({ activeTab, teams, fetching: loadingTeams, authUser })}
    </BodyContainer>
  );
}
