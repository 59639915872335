// React
import React from "react";
import PropTypes from "prop-types";

// Components
import Link from "../../components/Link";
import * as routes from "../../constants/routes";
import { AuthUserContext } from "../../components/Session";

// Material ui
import {Box, Avatar, CardHeader, Divider, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

function getSourceLinkText(contract) {
  return `#${contract.uid}`
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  root: ({bottomGutterSpacing}) => ({
    marginBottom: theme.spacing(bottomGutterSpacing || 0),
    display: 'flex',
  }),
  cardHeaderRoot: {
    padding: 0,
  },
  verticalDivider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  label: {
    opacity: 0.65,
    marginBottom: theme.spacing(1),
  },
  linkBox: {
    height: theme.spacing(4),
    display: 'flex',
  },
  linkTypography: {
    marginTop: 'auto',
    marginBottom: 'auto',
  }
}));

export default function ContractHeader(props) {
  const {contract, team, client, business} = props;
  const classes = useStyles(props);
  const authUser = React.useContext(AuthUserContext);

  const sourceLinkText = getSourceLinkText(contract) 
  const isTeamLead = Boolean(authUser.uid === team.teamLeadId)
  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="body2" className={classes.label}>
          Team contract
        </Typography>
        <Box className={classes.linkBox}>
          <Typography variant="caption" className={classes.linkTypography}>
            {isTeamLead ? (
              <Link
                to={
                  routes.freelancerTeamContract
                    .replace(":contractId", contract.uid)
                }
                color="primary"
                weight="bold"
              >
                {sourceLinkText}
              </Link>
            ) : (
              sourceLinkText
            )}
          </Typography>
        </Box>
      </Box>    
      {client && business && (
        <Divider 
          orientation="vertical" 
          flexItem 
          className={classes.verticalDivider} 
        />
      )}
      {client && business && (
        <Box>
          <Typography variant="body2" className={classes.label}>
            Client
          </Typography>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
            }}
            avatar={
              client.profilePhoto ? (
                <Avatar
                  src={client.profilePhoto}
                  className={classes.avatar}
                />
              ) : (
                <Avatar
                  src={client.profilePhoto}
                  className={classes.avatar}
                >
                  {client.firstName.substring(0, 1)}
                </Avatar>
              )
            }
            title={`${client.firstName} ${client.lastName}`}
            titleTypographyProps={{variant: "body2", component: "h1"}}
            subheader={business.name}
          />
        </Box>
      )}
      <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
      <Box>
        <Typography variant="body2" className={classes.label}>
          Freelancer
        </Typography>
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
          }}
          avatar={
            <Avatar
              src={authUser.profilePhoto}
              className={classes.avatar}
            />
          }
          title={`${authUser.firstName} ${authUser.lastName}`}
          titleTypographyProps={{variant: "body2", component: "h1"}}
          subheader={`Team ${team.name}`}
        />
      </Box>
    </Box>
  );
}

ContractHeader.propTypes = {
  team: PropTypes.object.isRequired,
  client: PropTypes.object,
  business: PropTypes.object,
  freelancer: PropTypes.object,
};
