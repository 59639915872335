// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StatusLabel from "../../../../components/StatusLabel";

// MUI
import {TableRow, TableCell } from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Skeleton} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardHeaderRoot: {
    padding: 0
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}));

export default function RequestTableRow(props) {
  const {requestId, loading, createdDate, status, teamsInvited, onClick} = props;
  const classes = useStyles(props);
  const theme = useTheme();

  if (loading) {
    return (
      <TableRow
        key={requestId}
      >
        <TableCell component="th" id="clientName" scope="row">
          <Skeleton/>
        </TableCell>
        <TableCell id="teamName">
          <Skeleton/>
        </TableCell>
        {/* <TableCell id="contractStatus">
          <Skeleton/>
        </TableCell>
        <TableCell id="cycleBillings">
          <Skeleton/>
        </TableCell> */}
      </TableRow>
    )
  }

  return (
    <TableRow
      hover
      onClick={() => onClick(requestId)}
      tabIndex={-1}
      key={requestId}
      className={classes.tableRow}
    >
      <TableCell component="th" id="createdAt" scope="row">
        {createdDate.toLocaleDateString()}
      </TableCell>
      <TableCell id="status">{
        status ? (
          <StatusLabel
            color={theme.palette.statusColors[status]}
            value={status}
            size="small"
            shape="square"
            clickable
          />
        ) : "--"
      }
      </TableCell>
      <TableCell id="teamsInvited">
        {teamsInvited}
      </TableCell>
    </TableRow>
  );
}

RequestTableRow.propTypes = {
  uid: PropTypes.string.isRequired,
};



