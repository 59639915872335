// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";
import queryString from "query-string";
import {useLocation} from "@reach/router";

// Components
import EnhancedTableHead from "../../../../components/EnhancedTableHead";
import EmptyStateView from "../../../../components/EmptyStateView";

// MUI
import {TableContainer, Table, TableBody, TablePagination} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {Group} from "@material-ui/icons";

// Utils
import {stableSort, getSorting} from "../../../../utils/sorting";
import * as routes from "../../../../constants/routes";
import ContractTableRow from "./ContractTableRow";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    width: '100%',
    marginTop: topGutterSpacing ? theme.spacing(topGutterSpacing) : 0,
    marginBottom: bottomGutterSpacing ? theme.spacing(bottomGutterSpacing) : 0,
  }),
}));

const headCells = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "contractStatus",
    label: "Status",
  },
  {
    id: "noHireContracts",
    label: "# Hire contracts",
  },
  {
    id: "monthlyBudget",
    label: "Monthly budget",
  },
];

function createRows({teams, contracts, applicableContractVersions}) {
  return contracts
    .map(({uid, status, teamId, monthlyBudget, currency, businessId }) => {
      const team = teams
        .filter(t => t.uid === teamId)[0];
      const applicableContractVersion = applicableContractVersions
        .filter(acv => acv.contractId === uid)[0];

      return ({
        uid,
        name: team.name,
        profilePhoto: team.profilePhoto,
        role: "team",
        status,
        businessId,
        currency,
        monthlyBudget: applicableContractVersion ? applicableContractVersion.monthlyBudget : 0,
        noHireContracts: applicableContractVersion ? Object.keys(applicableContractVersion.hireContracts).length : 0,
        teamId,
        contractId: uid,
        // nrOfActiveHires: Object.keys(contract.hires)
        //   .map(uid => contract.hires[uid])
        //   .filter(({active}) => active)
        //   .length
        // ,
      })
    })
}

export default function ContractsTable(props) {
  const {teams, contracts, applicableContractVersions} = props;

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  const classes = useStyles(props);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickRow = contractId =>
    navigate(
      queryString.stringifyUrl({
        url: routes.clientTeamsContract
          .replace(":contractId", contractId)
        ,
        query: parsedQueries,
      }));

  if (teams.length === 0) {
    return (
      <EmptyStateView
        text="No teams contracted yet"
        icon={<Group/>}
        topSpacing={4}
      />
    )
  }
  const rows = createRows({teams, contracts, applicableContractVersions });
  return (
    <div>
      <TableContainer>
        <Table
          className={classes.table}
          size='medium'
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) =>
                <ContractTableRow {...row} key={index} onClick={handleClickRow}/>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
    </div>
  );
}

ContractsTable.propTypes = {
  teams: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
};



