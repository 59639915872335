// React
import React from "react";
import {navigate} from "gatsby";

// Context
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";

// Constants
import * as routes from "../../constants/routes";

// Components
import BodyContainer from "../../components/BodyContainer";
import ContractsTabs from "./ContractsTabs";
import {TeamContractsTab, MyContractsTab} from "./TabContent";

// Other libs
import EmptyStateView from "../../components/EmptyStateView";
import {BrokenImage} from "@material-ui/icons";

function getTabContent({activeTab, leadingTeam, teams, loadingTeams}) {
  if (activeTab === "team") {
    return (
      <TeamContractsTab
        leadingTeam={leadingTeam}
        teams={teams}
        loadingTeams={loadingTeams}
      />
    )
  } 
  if (activeTab === "hire") {
    return (
      <MyContractsTab
        teams={teams}
        loadingTeams={loadingTeams}
      />
    )
  } 
  return <EmptyStateView text="We could not find that tab" icon={<BrokenImage/>}/>
}

export default function FreelancerContractsPage(props) {
  const {match} = props;
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);
  const [teams, loadingTeams] = useFetchFirestore(
    firebase
      .teams()
      .where(`members.${authUser.uid}.userId`, "==", authUser.uid),
    {type: "collection"}
  );
  const [activeTab, setActiveTab] = React.useState(match.tab);

  React.useEffect(() => setActiveTab(match.tab), [match.tab]);

  const handleToggleTab = (event, value) => navigate(
    routes.freelancerContractsTab
      .replace(":tab", value)
  );

  const leadingTeam =  teams
    .filter(({ teamLeadId }) => teamLeadId === authUser.uid)[0];
  return (
    <BodyContainer bottomGutter topGutter>
      <ContractsTabs
        activeTab={activeTab}
        onToggleTab={handleToggleTab}
      />
      {getTabContent({ activeTab, leadingTeam, teams, loadingTeams })}
    </BodyContainer>
  );
}
