// React
import React from "react";
import {useLocation} from "@reach/router";
import {navigate} from "gatsby";
import queryString from "query-string";

// Components
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";
import BodyContainer from "../../components/BodyContainer";
import PageNotFound from "../../components/PageNotFound";
import LoadingIndicator from "../../components/LoadingIndicator";
import RequestTable from "./RequestTable";
import RequestHeader from "./RequestHeader";

// Material UI
import {Typography, Divider, Button, CircularProgress} from "@material-ui/core";

// Constants
import * as routes from "../../constants/routes";

export default function FreelancerLeadRequestPage(props) {
  const {match} = props;
  const authUser = React.useContext(AuthUserContext);
  const firebase = React.useContext(FirebaseContext);

  const [creatingContract, setCreatingContract] = React.useState(false);

  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  const [request, loadingRequest] = useFetchFirestore(
    match.requestId ? firebase.request(match.requestId) : null, {stopLoading: !match.requestId}
  );

  console.log("request", request)

  const [team, loadingTeam] = useFetchFirestore(
    parsedQueries.team ? firebase.team(parsedQueries.team) : null, {stopLoading: !parsedQueries.team}
  );

  const contractsRef = (match.requestId && authUser.uid) ? (
    firebase.contracts()
      .where("sourceColId", "==", "requests")
      .where("sourceDocId", "==", match.requestId)
      .where(`ownerId`, "==", authUser.uid)
      .where(`freelancers.${authUser.uid}`, "==", true)
  ) : null;
  const [contract, loadingContract] = useFetchFirestore(
    contractsRef,
    {
      type: "collection",
      listen: true,
      stopLoading: !contractsRef,
      transformResult: r => r[0],
    }
  );

  const clientId = request ? request.ownerId : null;
  const [client, loadingClient] = useFetchFirestore(
    clientId ? firebase.user(clientId) : null,
    {stopLoading: !clientId && !loadingRequest}
  );

  const handleViewContract = (contractId) =>
    navigate(
      routes.freelancerTeamContract
        .replace(":contractId", contractId)
    )

  const handleAddContract = () => {
    setCreatingContract(true);
    const contractPayload = {
      ownerId: authUser.uid,
      createdAt: firebase.createTimestamp(),
      lastUpdatedAt: firebase.createTimestamp(),
      sourceColId: "requests",
      sourceDocId: request.uid,
      applicableVersionId: null,
      teamId: team.uid,
      teamLeadId: team.teamLeadId,
      businessId: request.businessId,
      freelancers: {
        [team.teamLeadId]: true,
      },
      status: "draft",
      currency: request.currency,
      versionCount: 0,
    }

    // Add the contract 
    firebase.contracts()
      .add(contractPayload)
      .then(docRef => {
        handleViewContract(docRef.id)
      })
  };

  if (!parsedQueries.team) {
    return <PageNotFound/>
  } else if (loadingRequest || loadingTeam || loadingContract || loadingClient) {
    return <LoadingIndicator message="Loading request..." inheritHeight topSpacing={5}/>
  } else if (!request || !team || !client) {

    return (
      <BodyContainer bottomGutter topGutter>
        <Typography>
          Something went wrong... Try refreshing your browser. Contact info@maxer.io if the issue
          persists.
        </Typography>
      </BodyContainer>
    )
  }

  return (
    <BodyContainer bottomGutter topGutterSpacing={3}>
      <RequestHeader
        request={request}
        client={client}
        teamId={parsedQueries.team}
      />
      <Divider/>
      <RequestTable
        request={request}
        client={client}
      />
      <Divider style={{marginTop: 24, marginBottom: 24}}/>
      {contract ? (
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleViewContract(contract.uid)}
        >
          View proposed contract
        </Button>
      ) : (
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddContract}
          startIcon={creatingContract ? <CircularProgress size={15}/> : null}
          disabled={creatingContract || request.status !== "open"}
        >
          Apply to request
        </Button>
      )}
    </BodyContainer>
  );
}
