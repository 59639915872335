// React
import React from "react";
import {navigate} from "gatsby";

// Context
import {FirebaseContext} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";

// Components
import StepContent from "./StepContent";
import TermsOfService from "../../components/TermsOfService";
import DialogContainer from "../../components/DialogContainer";
import BodyContainer from "../../components/BodyContainer";

// routes
import * as routes from "../../constants/routes";

export default function FreelancerProfileSetupPage() {
  const [termsExpanded, setTermsExpanded] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  const handleSubmitButtonClick = () => {
    firebase.user(authUser.uid)
      .update({
        lastUpdatedAt: firebase.createTimestamp(),
        becameStatusProfileCompleted: firebase.createTimestamp(),
        status: "profileCompleted"
      })
      .then(() => navigate(routes.freelancerTeams));
  };

  const handleToggleTermsExpanded = () => setTermsExpanded((value) => !value);
  const handleToggleTermsAccepted = () => setTermsAccepted((value) => !value);

  return (
    <div>
      <DialogContainer
        open={termsExpanded}
        onClose={handleToggleTermsExpanded}
        title="Terms of Service"
      >
        <TermsOfService/>
      </DialogContainer>
      <BodyContainer topGutter bottomGutter>
        <StepContent
          onSubmit={handleSubmitButtonClick}
          onToggleTermsAccepted={handleToggleTermsAccepted}
          onToggleTermsExpanded={handleToggleTermsExpanded}
          termsAccepted={termsAccepted}
        />
      </BodyContainer>
    </div>
  );
}

FreelancerProfileSetupPage.propTypes = {};
