// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";
import queryString from "query-string";
import {useLocation} from "@reach/router";

// Components
import EnhancedTableHead from "../../../../components/EnhancedTableHead";
import EmptyStateView from "../../../../components/EmptyStateView";

// MUI
import {TableContainer, Table, TableBody, TablePagination} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {Group} from "@material-ui/icons";

// Utils
import {stableSort, getSorting} from "../../../../utils/sorting";
import * as routes from "../../../../constants/routes";
import RequestTableRow from "./RequestTableRow";
import { convertUnixTsToDate } from "../../../../utils/time";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    width: '100%',
    marginTop: topGutterSpacing ? theme.spacing(topGutterSpacing) : 0,
    marginBottom: bottomGutterSpacing ? theme.spacing(bottomGutterSpacing) : 0,
  }),
}));

const headCells = [
  {
    id: "createdAt",
    label: "Created at",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "teamsInvited",
    label: "Teams invited",
  },
  // {
  //   id: "monthlyBillings",
  //   label: `${new Date().toLocaleString('default', {month: 'short'})} ${String(new Date().getFullYear())} billings (excl. tax)`,
  // },
];

function createRows(requests) {
  return requests
    .map(({uid, createdAt, status, teamId, monthlyBudget, currency, businessId, teamOptions }) => {
      return ({
        requestId: uid,
        createdDate: convertUnixTsToDate(createdAt.seconds),
        status,
        businessId,
        currency,
        monthlyBudget,
        teamId,
        teamsInvited: Object.keys(teamOptions)
          .map(teamId => teamOptions[teamId])
          .filter(({ becameStatusInvitedAt }) => Boolean(becameStatusInvitedAt))
          .length
      })
    })
}

export default function RequestsTable(props) {
  const {requests, loading} = props;

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  const classes = useStyles(props);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickRow = requestId =>
    navigate(
      queryString.stringifyUrl({
        url: routes.clientTeamsRequest
          .replace(":requestId", requestId),
        query: parsedQueries,
    }));

  if (requests.length === 0) {
    return (
      <EmptyStateView
        text="No requests created yet"
        icon={<Group/>}
        topGutterSpacing={4}
      />
    )
  }
    
  const rows = createRows(requests);
  return (
    <div>
      <TableContainer>
        <Table
          className={classes.table}
          size='medium'
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) =>
                <RequestTableRow 
                  {...row} 
                  loading={loading}
                  key={index} 
                  onClick={handleClickRow}
                />
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
    </div>
  );
}

RequestsTable.propTypes = {
  teams: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
};



