// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Components
import {Avatar, CardHeader, Typography, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {OpenInNew} from "@material-ui/icons";

// constants
import * as routes from "../../constants/routes";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  cardHeaderRoot: {
    padding: 0,
  },
  avatar: {
    width: 64,
    height: 64,
  },
  actionsContainer: {
    marginTop: "auto",
    marginBottom: "auto",
  }
}));

// function getNrOfActiveMembers(members) {
//   return Object.keys(members)
//     .map(key => members[key])
//     .filter(({status}) => status === "active")
//     .length
// }

// function getPopoverText(team) {
//   if (team.status === "approved") {
//     return "This team is authorized to take on client contracts"
//     // return "Teams must consist of at least 3 active members in order to take on new clients"
//   }
//   return null;
// }

export default function TeamHeader(props) {
  const {team} = props;
  const classes = useStyles(props);
  // const domainShort = process.env.GATSBY_DOMAIN
  //   .replace("https://", "")
  //   .replace("www.", "");

  const publicTeamUrl = queryString.stringifyUrl({
    url: routes.teamProfile,
    query: {
      uid: team.uid,
    }
  });

  // const handleFinishProfileClick = () => {
  //   return navigate(routes.freelancerTeamMembers.replace(":teamId", team.uid));
  // };
  const handlePublicProfileClick = () => navigate(publicTeamUrl);


  return (
    <div className={classes.root}>
      <CardHeader
        avatar={<Avatar className={classes.avatar} src={team.profilePhoto} variant="rounded"/>}
        title={
          <Typography variant="h4" component="h1" className={classes.title}>
            {team.name || `Team #${team.uid}`}
          </Typography>
        }
        classes={{
          root: classes.cardHeaderRoot,
          subheader: classes.subheader,
        }}
        disableTypography
        // onClick={() => onToggleExpand ? onToggleExpand(uid) : console.log("click")}
      />
      <div className={classes.actionsContainer}>
        <Button
          startIcon={<OpenInNew/>}
          color="primary"
          variant="outlined"
          size="small"
          onClick={handlePublicProfileClick}
        >
          Public profile
        </Button>
      </div>
    </div>
  );
}

TeamHeader.propTypes = {
  team: PropTypes.object.isRequired,
};
