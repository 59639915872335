// React
import React from "react";

// Context
import {AuthUserContext} from "../../components/Session";

// Components
import SettingsPanelTextfield from "../../components/SettingsPanelTextfield";
import {LanguagesPanel, SkillsPanel} from "../../components/SettingsPanels";
import {FirebaseContext} from "../../components/Firebase";
// import {useLocation} from "@reach/router";
// const queryString = require("query-string");


export default function ExperienceSettings(props) {
  // const [piDialogOpen, setPiDialogOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState({
    headline: false,
    about: false,
    languages: false,
    skills: false,
    portfolio: false,
  });
  // const location = useLocation();
  // const parsedQueries = queryString.parse(location.search);

  // React.useEffect(() => {
  //   setPiDialogOpen(Boolean(parsedQueries.pi));
  // }, [parsedQueries.pi]);

  const handlePanelChange = (panel) => (event, value) => {
    setExpanded({
      ...expanded,
      [panel]: value,
    });
  };

  const authUser = React.useContext(AuthUserContext);
  const firebase = React.useContext(FirebaseContext);
  const headlineId = "headline";
  const aboutId = "about";
  return (
    <div>
      {/* Headline */}
      <SettingsPanelTextfield
        id="headline-panel"
        lastSavedValue={authUser[headlineId]}
        firebaseRef={firebase.user(authUser.uid)}
        firebaseFieldKey={headlineId}
        characterLimit={120}
        label="Headline"
        expandedText="Write a headline summarizing your professional experience"
        formHelperText="Example: '5+ Years experience in SEA and Display advertising | Worked at Google & Amazon | Msc. Data Science at Harvard'"
        isExpanded={Boolean(expanded[headlineId])}
        handlePanelChange={handlePanelChange(headlineId)}
        multiline={true}
        nrOfRows={2}
        rowsMax={10}
      />
      {/* About */}
      <SettingsPanelTextfield
        id="about-panel"
        lastSavedValue={authUser[aboutId]}
        firebaseRef={firebase.user(authUser.uid)}
        firebaseFieldKey={aboutId}
        characterLimit={1000}
        label="About"
        expandedText="Tell something more about yourself"
        collapsedTextMaxLength={120}
        formHelperText="Why should companies hire you as their freelance marketer?"
        isExpanded={Boolean(expanded[aboutId])}
        handlePanelChange={handlePanelChange(aboutId)}
        multiline={true}
        nrOfRows={5}
        rowsMax={25}
      />
      <SkillsPanel
        firebaseRef={firebase.user(authUser.uid)}
        value={authUser.skills}
        isExpanded={Boolean(expanded.skills)}
        handlePanelChange={handlePanelChange("skills")}
      />
      <LanguagesPanel
        isExpanded={Boolean(expanded.languages)}
        handlePanelChange={handlePanelChange("languages")}
      />
    </div>
  );
}

