// React
import React from "react";
import PropTypes from "prop-types";

// Context
import {AuthUserContext} from "../../components/Session";

// Components
import {
  CountryPanel, CurrencyPanel, HourlyRatePanel, LanguagesPanel,
  ProfilePhotoPanel, SkillsPanel,
} from "../../components/SettingsPanels";
import SettingsPanelTextfield from "../../components/SettingsPanelTextfield";
import CtaBlock from "../../components/CtaBlock";
import TermsCheckbox from "../../components/TermsCheckbox";

// Material UI
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from "@material-ui/core/styles";
import MuiLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

// Constants
import {FirebaseContext} from "../../components/Firebase";

const steps = ["country", "profilePhoto", "headline", "about", "languages", "currency", "hourlyRate", "skills", "terms"];

function getActiveStep(authUser) {
  let activeStep = "country";
  for (let i = 0; i < steps.length; i++) {
    const stepId = steps[i];
    if (!authUser[stepId]) {
      activeStep = stepId;
      break;
    }
  }
  return activeStep;
}

function getStepIndex(activeStep) {
  let activeStepIdx = 0;
  for (let i = 0; i < steps.length; i++) {
    const stepId = steps[i];
    if (stepId === activeStep) {
      activeStepIdx = i;
      break;
    }
  }
  return activeStepIdx;
}

function getStepField({firebase, activeStep, authUser, onSubmit, onToggleTermsAccepted, termsAccepted, onToggleTermsExpanded}) {
  switch (activeStep) {
    case "country":
      return (
        <CountryPanel
          firebaseRef={firebase.user(authUser.uid)}
          isExpanded
          draftMode
          canMakeEdits
        />
      );
    case "profilePhoto":
      return (
        <ProfilePhotoPanel
          isExpanded
          firebaseRef={firebase.user(authUser.uid)}
          firebaseStorageRef={firebase.userProfilePhoto(authUser.uid)}
          lastSavedPhoto={authUser.profilePhoto}
          altText={`${authUser.firstName}'s avatar`}
          helperCaption="Make sure your face is clearly recognizable."
        />
      );
    case "headline":
      return (
        <SettingsPanelTextfield
          id="headline-panel"
          lastSavedValue={authUser.headline}
          uid={authUser.uid}
          firebaseRef={firebase.user(authUser.uid)}
          firebaseFieldKey="headline"
          characterLimit={120}
          label="Headline"
          expandedText="Write a headline summarizing your professional experience"
          formHelperText="Example: '5+ Years experience in SEA and Display advertising | Worked at Google & Amazon | Msc. Data Science at Harvard'"
          isExpanded={true}
          multiline={true}
          nrOfRows={2}
          rowsMax={10}
          draftMode={true}
        />
      );
    case "about":
      return (
        <SettingsPanelTextfield
          id="about-panel"
          lastSavedValue={authUser.about}
          uid={authUser.uid}
          firebaseRef={firebase.user(authUser.uid)}
          firebaseFieldKey="about"
          characterLimit={1000}
          label="About"
          expandedText="Tell something more about yourself"
          collapsedTextMaxLength={120}
          formHelperText="Why should companies hire you as their freelance marketer?"
          isExpanded={true}
          multiline={true}
          nrOfRows={5}
          rowsMax={25}
          draftMode={true}
        />
      );
    case "languages":
      return (
        <LanguagesPanel
          isExpanded
          draftMode
        />
      );
    case "currency":
      return (
        <CurrencyPanel
          firebaseRef={firebase.user(authUser.uid)}
          isExpanded
          canMakeEdits
          draftMode
        />
      );
    case "hourlyRate":
      return (
        <HourlyRatePanel
          isExpanded
          canMakeEdits
          draftMode
        />
      );
    case "skills":
      return (
        <SkillsPanel
          firebaseRef={firebase.user(authUser.uid)}
          isExpanded
          draftMode
        />
      );
    case "terms":
      return (
        <div>
          <CtaBlock
            leftComponent={
              <TermsCheckbox
                termsAccepted={termsAccepted}
                onToggleAcceptTerms={onToggleTermsAccepted}
                text={
                  <Typography>
                    I confirm the information displayed in my profile is correct and agree to all applicable{" "}
                    <MuiLink onClick={onToggleTermsExpanded} color="primary">
                      terms and conditions
                    </MuiLink>
                    .
                  </Typography>
                }
              />
            }
            buttonDisabled={!termsAccepted}
            buttonText="Finish profile"
            buttonColor="primary"
            handleButtonClick={onSubmit}
            topGutter
          />
        </div>
      );
    default:
      return "Unknown step";
  }
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  progressBar: {
    width: theme.spacing(15),
    marginBottom: "auto",
    marginTop: "auto",
    borderRadius: 10,
    height: 10,
  },
}));

export default function StepContent(props) {
  const {onSubmit, onToggleTermsAccepted, termsAccepted, onToggleTermsExpanded} = props;
  const authUser = React.useContext(AuthUserContext);
  const firebase = React.useContext(FirebaseContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(null);

  React.useEffect(() => {
    const newActiveStep = getActiveStep(authUser);
    if (activeStep !== newActiveStep) {
      setActiveStep(newActiveStep)
    }
  }, [authUser, activeStep]);

  return (
    <div>
      <div className={classes.headerContainer}>
        <Typography
          variant="h5"
          component="h1"
        >
          Complete your freelancer profile
        </Typography>
        <LinearProgress
          className={classes.progressBar}
          variant="determinate"
          value={(getStepIndex(activeStep) / steps.length) * 100}
        />
      </div>
      {getStepField({
        firebase,
        activeStep,
        authUser,
        onSubmit,
        onToggleTermsAccepted,
        termsAccepted,
        onToggleTermsExpanded
      })}
    </div>
  );
}

StepContent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onToggleTermsAccepted: PropTypes.func.isRequired,
  onToggleTermsExpanded: PropTypes.func.isRequired,
  termsAccepted: PropTypes.bool.isRequired,
};