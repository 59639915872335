// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Context
import {AuthUserContext} from "../../components/Session";
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";

// Components
import BillsTable from "../../components/BillsTable";
import FreelancerBillsToolbar from "./FreelancerBillsToolbar";
import EmptyStateView from "../../components/EmptyStateView";
import LoadingIndicator from "../../components/LoadingIndicator";
import BodyContainer from "../../components/BodyContainer";

// Constants
import * as routes from "../../constants/routes";
import {Receipt} from "@material-ui/icons";

const statusOptions = [
  {
    value: "all",
    text: "All",
  },
  {
    value: "draft",
    text: "Draft",
  },
  {
    value: "paid",
    text: "Paid",
  },
  {
    value: "paymentRequested",
    text: "Payment requested",
  },
  {
    value: "paymentFailed",
    text: "Payment failed",
  }
];

export default function FreelancerBillsPage(props) {
  const authUser = React.useContext(AuthUserContext);
  const firebase = React.useContext(FirebaseContext);

  // const [billsFilter, setBillsFilter] = React.useState("myBills");
  const [filters, setFilters] = React.useState({
    query: "",
    status: "all",
    type: "myBills"
  });

  // Fetch teams freelancer is involved in (any status)
  const [teams, loadingTeams] = useFetchFirestore(
    firebase
      .teams()
      // .where("status", "==", "approved")
      // .where("noActiveMembers", ">=", minTeamMembers)
      .where(`members.${authUser.uid}.userId`, "==", authUser.uid),
    {type: "collection"}
  );

  // Fetch bills
  const leadingTeam = teams
    .filter(({teamLeadId}) => teamLeadId === authUser.uid)[0];
  const statusFiltered = filters.status === "all"
    ? ["draft", "pending", "paymentRequested", "paymentFailed", "paid"] 
    : [filters.status]
  const billsRef = filters.type === "myBills" ? (
    firebase.bills()
      .where("ownerId", "==", authUser.uid)
      .where("status", "in", statusFiltered)
      .limit(10)
  ) : leadingTeam ? (
    firebase.bills()
      .where("teamId", "==", leadingTeam.uid)
      .where("status", "in", statusFiltered)
      .limit(10)
  ) : null;
  const [bills, loadingBills] = useFetchFirestore(
    billsRef,
    {type: "collection", stopLoading: !loadingTeams && !billsRef}
  );

  // Fetch user profiles for bills
  const freelancerIds = bills
    .map(({ownerId}) => ownerId);
  const [freelancerProfiles, loadingFreelancerProfiles] = useFetchFirestore(
    freelancerIds.length > 0 ? (
      firebase.users()
        .where("uid", "in", freelancerIds)
    ) : null,
    {type: "collection", stopLoading: Boolean(!loadingBills && freelancerIds.length === 0), desc: "freelancers"}
  );

  // Fetch businesses targeted with bills
  const businessIds = bills
    .filter(({businessId}) => businessId)
    .map(({businessId}) => businessId);
  const [businesses, loadingBusinesses] = useFetchFirestore(
    businessIds.length > 0 ? (
      firebase.businesses()
        .where("uid", "in", businessIds)
    ) : null,
    {type: "collection", stopLoading: Boolean(!loadingBills && businessIds.length === 0), desc: "businesses"}
  );

  // Fetch client profiles for businesses
  const clientIds = businesses
    .map(({ownerId}) => ownerId);
  const [clientProfiles, loadingClientProfiles] = useFetchFirestore(
    clientIds.length > 0 ? (
      firebase.users()
        .where("uid", "in", clientIds)
    ) : null,
    {type: "collection", stopLoading: Boolean(!loadingBusinesses && clientIds.length === 0), desc: "clients"}
  );

  const handleNavigateBill = billId =>
    navigate(routes.freelancerBill.replace(":billId", billId));

  const handleCreateNewBill = () => navigate(routes.freelancerBillCreateNew);

  const handleSearch = (prop) => (event) => {
    const query = event.target.value;
    setFilters((f) => ({...f, queryFilter: query}));
  };

  const handleSelectFilter = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  const loading = Boolean(loadingBills || loadingTeams || loadingClientProfiles || loadingFreelancerProfiles || loadingBusinesses);
  return (
    <BodyContainer topGutter bottomGutter maxWidth={1200}>
      <FreelancerBillsToolbar
        handleCreateBill={handleCreateNewBill}
        statusOptions={statusOptions}
        handleSearch={handleSearch}
        filters={filters}
        handleSelectFilter={handleSelectFilter}
        leadingTeam={leadingTeam}
      />
      {loading ? ( 
        <LoadingIndicator
        message="Loading bills..."
        topSpacing={10}
        bottomSpacing={10}
        inheritHeight
      />
      ) : bills.length > 0 ? (
        <BillsTable
          bills={bills}
          clientProfiles={
            clientProfiles
              .map(p => ({
                ...p,
                business: businesses
                  .filter(({ownerId}) => ownerId === p.uid)[0]
              }))
          }
          teamProfiles={teams}
          freelancerProfiles={freelancerProfiles}
          handleRowClick={handleNavigateBill}
        />
      ) : (
        <EmptyStateView
          text="No bills found"
          icon={<Receipt/>}
          topSpacing={10}
        />
      )}
    </BodyContainer>
  );
}

FreelancerBillsPage.propTypes = {
  collaborators: PropTypes.array.isRequired,
  profiles: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
};
