// React
import React from "react";
import PropTypes from 'prop-types';

// Components
import StatusLabel from "../../../components/StatusLabel";

// Material UI
import {Typography, Paper} from "@material-ui/core";
import {useTheme, makeStyles} from "@material-ui/core/styles";

// Utils
import {convertUnixTsToDate} from "../../../utils/time";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2)
  },
}));

function getApplicationStatusText(teamMembership, application) {

  if (teamMembership) {
    const {status} = teamMembership; 
    if (status === "active") {
      return `You will automatically get notified when the team lead assigns you to new contracts.`
    } 
    return `You will no longer be assigned to contracts on behalf of this team.`
  }

  if (application) {
    if (application.status === "pending") {
      return `Your application is pending acceptance from the team lead. This might take some time.`
    } else if (application.status === "rejected") {
      return `Unfortunately your application was rejected on ${convertUnixTsToDate(application.becameStatusRejectedAt.seconds).toLocaleDateString()}. Don't worry, you can try again next year.`
    }
    return "An error occured. Contact support."
  }

  return `Apply to become a member. Make sure your profile is complete before submitting your application.`
}

export default function MemberStatusPaper(props) {
  const {teamMembership, application} = props;
  const membershipStatus = teamMembership 
    ? teamMembership.status 
    : application ? application.status : "draft";
  const statusText = getApplicationStatusText(teamMembership, application);
  const classes = useStyles(props);
  const theme = useTheme();

  const type = teamMembership ? "Membership" : "Application"
  return (
    <Paper className={classes.root}>
      <StatusLabel
        value={`${type} ${membershipStatus}`}
        color={theme.palette.statusColors[membershipStatus]}
        size="small"
        rightGutterSpacing={2}
      />
      <Typography>{statusText}</Typography>
    </Paper>
  )
}

MemberStatusPaper.propTypes = {
  team: PropTypes.object.isRequired,
};
