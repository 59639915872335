// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {FirebaseContext} from "../../../Firebase";
import SelectedHiresList from "./SelectedHiresList";
import BottomToolbar from "./BottomToolbar";
import HireContractDialog from "./HireContractDialog";

// Libs / utils
import {serviceFeeRate} from "../../../../constants/settings";
import {formatCentsToDisplayPrice} from "../../../../utils/formatting";
import * as yup from "yup";

const validationSchema = {
  monthlyBudget: yup.number()
    .integer()
    .positive('Enter a monthly budget'),
  hourlyRate: yup.number()
    .transform(value => (isNaN(value) ? 0 : value))
    .integer()
    .positive('Enter an hourly rate'),
  salesTax: yup.object().shape({
    option: yup
      .string()
      .required("Select an option"),
    rate: yup.number()
      .transform(value => (isNaN(value) ? 0 : value))
      // .nullable(true)
      .when('option', (option, schema) => {
        return option === "setTax"
          ? schema.integer().positive('Enter a positive whole number')
          : schema.nullable();
      }),
    type: yup.string().nullable().when('option', (option, schema) => {
      return option === "setTax" || option === "reverseCharge"
        ? schema.required('Enter a description (e.g. "VAT", "GST" ...)')
        : schema.nullable();
    }),
  }),
};

function sumArray(total, num) {
  return total + num;
}

export default function TeamHiresField(props) {
  const {team, hireContracts, currency, isEditing, handleChange, handleRemoveContract} = props;
  const [addingHire, setAddingHire] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [selectedFreelancerId, setSelectedFreelancerId] = React.useState(null);
  const firebase = React.useContext(FirebaseContext);

  const handleAddHire = ({userId}) => {
    setAddingHire(true);
    firebase.user(userId)
      .get()
      .then((snap) => {
        const freelancer = firebase.formatSnapshot(snap);
        const freelancerSalesTax = {
          option: freelancer.country === "NL" ? "setTax" : "none",
          rate: freelancer.country === "NL" ? 21 : 0,
          type: freelancer.country === "NL" ? "VAT" : null,
        };
        const newHireData = {
          freelancerId: userId,
          hourlyRate: formatCentsToDisplayPrice(freelancer.hourlyRate),
          monthlyBudget: 0,
          salesTax: {
            option: "none",
            rate: 0,
            type: null
          },
          serviceFee: {
            rate: serviceFeeRate,
            salesTax: freelancerSalesTax
          },
          transactionFee: {
            type: "passedOn",
            rate: null,
            salesTax: freelancerSalesTax
          }
        };
        handleChange(hireContracts.length, newHireData);
        setAddingHire(false);
        return handleOpenHireDialog(userId);
      });
  };

  const handleUpdateHires = editedContract => {

    const existingIndex = hireContracts
      .findIndex(({ freelancerId }) => freelancerId === editedContract.freelancerId);

    yup.object(validationSchema)
      .validate(editedContract, {abortEarly: false})
      .then((result) => {
        console.log("vvv updating", { existingIndex, editedContract });
        setErrors({});
        handleChange(existingIndex, editedContract);
        return handleCloseHireDialog();
      })
      .catch((errors) => {
        const validationErrors = {};
        console.log("errors here", errors);
        for (let i = 0; i < errors.inner.length; i++) {
          const error = errors.inner[i];
          validationErrors[error.path] = error.message;
        }
        console.log("validationErrors", validationErrors);
        setErrors(validationErrors);
      });
  };

  const handleOpenHireDialog = freelancerId =>
    setSelectedFreelancerId(freelancerId)

  const handleCloseHireDialog = () => 
    setSelectedFreelancerId(null)

  console.log("hireContracts here", hireContracts)
  const hiresContractsIds = hireContracts
    .map(({freelancerId}) => freelancerId);

  const members = Object.keys(team.members)
    .map(userId => team.members[userId]);
  console.log("jjj members", members)
  const teamProfiles = members
    .map(m => ({
      ...m,
      isSelected: hiresContractsIds.includes(m.userId),
      isTeamLead: team.teamLeadId === m.userId,
    }));
  console.log("jjj teamProfiles", teamProfiles)


  const selectedHireContract = hireContracts
    .filter(({ freelancerId }) => freelancerId === selectedFreelancerId)[0];
  return (
    <div>
      {selectedHireContract && (
        <HireContractDialog 
          hireContract={selectedHireContract}
          freelancerId={selectedHireContract.freelancerId}
          handleClose={handleCloseHireDialog}
          errors={errors}
          currency={currency}
          handleUpdate={handleUpdateHires}
        />
      )}
      <SelectedHiresList
        teamId={team.uid}
        hiresContracts={hireContracts}
        teamProfiles={teamProfiles}
        handleOpenHireDialog={handleOpenHireDialog}
        onRemoveHires={handleRemoveContract}
        currency={currency}
        isEditing={isEditing}
      />
      <BottomToolbar 
        editing={isEditing}
        teamProfiles={teamProfiles}
        onAddHire={handleAddHire}
        loading={addingHire}
        currency={currency}
        totalBudget={
          hireContracts.length > 0 ? (
            hireContracts
              .map(({ monthlyBudget }) => (monthlyBudget || 0))
              .reduce(sumArray)
          ) : 0
        }
      />
    </div>
  );
}

TeamHiresField.propTypes = {
  team: PropTypes.object.isRequired,
};
