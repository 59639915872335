// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StatusLabel from "../../../../components/StatusLabel";

// MUI
import {
  TableRow,
  TableCell,
  Avatar,
  CardHeader,
} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';

// Utils
import {getCurrencyFormat} from "../../../../utils/formatting";


const useRowStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardHeaderRoot: {
    padding: 0
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}));

export default function MyContractRow(props) {
  const {status, clientName, clientProfilePhoto, businessName, teamName, teamProfilePhoto, onClick, monthlyBudget, currency} = props;
  const classes = useRowStyles(props);
  const theme = useTheme();

  return (
    <TableRow
      hover
      onClick={onClick}
      key={clientName}
      className={classes.root}
    >
      <TableCell component="th" id="clientName" scope="row">
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
          }}
          avatar={
            clientProfilePhoto ? (
              <Avatar
                src={clientProfilePhoto}
                className={classes.avatar}
              />
            ) : (
              <Avatar className={classes.avatar}>
                {clientName.substring(0, 1)}
              </Avatar>
            )
          }
          title={clientName}
          titleTypographyProps={{variant: "body2", component: "h1"}}
          subheader={businessName}
        />
      </TableCell>
      <TableCell id="teamName">
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
          }}
          avatar={
            teamProfilePhoto ? (
              <Avatar
                src={teamProfilePhoto}
                className={classes.avatar}
                variant="rounded"
              />
            ) : (
              <Avatar className={classes.avatar} variant="rounded">
                {teamName.substring(0, 1)}
              </Avatar>
            )
          }
          title={teamName}
          titleTypographyProps={{variant: "body2", component: "h1"}}
        />
      </TableCell>
      <TableCell id="status">
        <StatusLabel
          color={theme.palette.statusColors[status]}
          value={status}
          shape="square"
          clickable
        />
      </TableCell>
      <TableCell id="monthlyBudget">
        {getCurrencyFormat(monthlyBudget, currency)}
      </TableCell>
    </TableRow>
  );
}

MyContractRow.propTypes = {
  teams: PropTypes.array.isRequired,
  loadingTeams: PropTypes.bool.isRequired,
};
