// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import { Grid, Box, Typography } from "@material-ui/core";

// utils
import { getCurrencyFormat } from "../../../utils/formatting";

const useTermElementStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  label: {
    opacity: 0.65,
  },
  value: {
    marginTop: theme.spacing(0.5),
  },

}));

function TermElement(props) {
  const { label, value } = props;
  const classes = useTermElementStyles(props);
  return (
    <Box key={label} className={classes.root}>
      <Typography variant="caption" className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  )
}

function getSalesTaxText({option, rate, type}) {
  if (option === "setTax") {
    return `${rate}% ${type}`
  } else if (option === "reverseCharge") {
    return `${type} reverse charged`
  }
  return "None"
}

function createTermElements(props) {
  const {monthlyBudget, hourlyRate, salesTax, currency} = props;
  return [
    {
      label: "MONTHLY BUDGET",
      value: getCurrencyFormat(monthlyBudget, currency, { convertToDecimals: true }),
    },
    {
      label: "HOURLY RATE",
      value: getCurrencyFormat(hourlyRate, currency, { convertToDecimals: true }),
    },
    {
      label: "SALES TAX",
      value: getSalesTaxText(salesTax),
    },
  ]
}

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(3),
  },
}));

export default function HireContractTerms(props) {
  const classes = useStyles(props);
  const termElements = createTermElements(props);

  return (
    <Grid container spacing={6} className={classes.root}>
      {termElements.map(({ value, label }) => (
        <Grid item xs={12} sm={12} md={4} key={label}>
          <TermElement
            label={label}
            value={value}
          />
        </Grid>
      ))}
    </Grid>
  );
}

HireContractTerms.propTypes = {
  monthlyBudget: PropTypes.number.isRequired,
  hourlyRate: PropTypes.number.isRequired,
  salesTax: PropTypes.object.isRequired,
};
