// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BodyContainer from "../../components/BodyContainer";
import LoadingIndicator from "../../components/LoadingIndicator";
import HireContractHeader from "./HireContractHeader";
import HireContractVersions from "./HireContractVersions";
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";

// Material UI
import {Typography} from "@material-ui/core";
import { AuthUserContext } from "../../components/Session";

export default function FreelancerContractHirePage(props) {
  const {match} = props;
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  const [contract, loadingContract] = useFetchFirestore(
    firebase.contract(match.contractId)
  );
  const [business, loadingBusiness] = useFetchFirestore(
    contract ? firebase.business(contract.businessId) : null, {stopLoading: !loadingContract && !contract}
  );
  const [client, loadingClient] = useFetchFirestore(
    business ? firebase.user(business.ownerId) : null, {stopLoading: !loadingBusiness && !business}
  );
  const [hireContracts, loadingHireContracts] = useFetchFirestore(
    firebase.hireContracts()
      .where("freelancerId", "==", authUser.uid)
      .where("contractId", "==", match.contractId)
      .where("status", "in", ['active', 'stopped', 'replaced'])
      .orderBy("versionNumber", "desc"),
    { type: "collection" }
  )

  const teamRef = contract ? firebase.team(contract.teamId) : null;
  const [team, loadingTeam] = useFetchFirestore(
    teamRef, { stopLoading: !loadingContract && !contract }
  )

  if (loadingContract || loadingBusiness || loadingClient || loadingTeam || loadingHireContracts) {
    return (
      <BodyContainer bottomGutter topGutter>
        <LoadingIndicator 
          message="Loading contract..." 
          inheritHeight 
          topSpacing={10}
        />
      </BodyContainer>
    )
  } else if (!contract || !business || !client || !team) {
    return (
      <BodyContainer bottomGutter topGutter>
        <Typography>
          Something went wrong. Try refreshing your browser window. If the problem persists then please
          contact support
        </Typography>
      </BodyContainer>
    )
  }

  return (
    <BodyContainer bottomGutter topGutter>
      <HireContractHeader 
        contract={contract}
        business={business}
        client={client}
        team={team}
      />
      <HireContractVersions
        hireContracts={hireContracts}
        contract={contract}
        business={business}
        client={client}
      />
    </BodyContainer>
  )
}

FreelancerContractHirePage.propTypes = {
  match: PropTypes.object.isRequired,
};
