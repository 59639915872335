// React
import React from "react";
import PropTypes from "prop-types";

// Components
import HiresSelectorButton from "./HiresSelectorButton";
import TotalBudget from "./TotalBudget";

// Material UI
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: ({ editing }) => ({
    display: 'flex',
    justifyContent: editing ? 'space-between' : 'flex-end',
    marginTop: theme.spacing(1),
  }),
}));

export default function BottomToolbar(props) {
  const {editing, teamProfiles, loading, onAddHire, currency, totalBudget} = props;

  const classes = useStyles(props);

  const unselectedMembers = teamProfiles
    .filter(({isSelected}) => !isSelected);

  console.log("qqq editing", editing)

  return (
    <Box className={classes.root}>
      {editing && (
        <HiresSelectorButton
          unselectedMembers={unselectedMembers}
          onAddHire={onAddHire}
          loading={loading}
          currency={currency}
        />
      )}
      <TotalBudget 
        currency={currency}
        totalBudget={totalBudget}
      />
    </Box>
  );
}

BottomToolbar.propTypes = {
  editing: PropTypes.bool.isRequired,
  teamProfiles: PropTypes.array.isRequired,
};
