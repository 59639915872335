// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {makeStyles} from "@material-ui/core/styles";

// Utils
// import FilterBar from "../../components/FilterBar";
import Button from "@material-ui/core/Button";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    marginLeft: theme.spacing(5),
  },
  button: {
    marginTop: "auto",
    marginBottom: "auto",
    height: "fit-content",
  },
  leftBtn: {
    minWidth: 120,
    marginRight: theme.spacing(1),
  },
  rightBtn: {
    minWidth: 140,
  }
}));

export default function TeamsToolbar(props) {

  // handleSearch, filterMenuItems, filters, onSelectFilter,
  const { onStartTeam, onApplyToTeams, hasOwnTeam} = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {/* <FilterBar
        searchQueryPlaceholder="Search by team name"
        onSearch={handleSearch}
        filterOneOptions={filterMenuItems}
        filterOneValue={filters.statusFilter}
        onFilterOneChange={(value) => onSelectFilter("statusFilter", value)}
      /> */}
      <div className={classes.buttonContainer}>
        <Button
          variant="outlined"
          color="primary"
          className={classNames(classes.button, classes.leftBtn)}
          onClick={onStartTeam}
          disabled={hasOwnTeam}
        >
          Lead a team
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classNames(classes.button, classes.rightBtn)}
          onClick={onApplyToTeams}
        >
          Apply to teams
        </Button>
      </div>
    </div>
  );
}

TeamsToolbar.propTypes = {
  handleSearch: PropTypes.array.isRequired,
  filterMenuItems: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
};
