// React
import React from "react";
import PropTypes from "prop-types";

// other libs
import SessionHeader from "./SessionHeader";
import SessionEditor from "./SessionEditor";
import SessionActionButtons from "./SessionActionButtons";

export default function Session(props) {
  const {session, onChange, onSave, removable, onRemove, onCancel, isBillOwner} = props;
  const minutesBilled = session.minutes - session.minutesBreak;
  return (
    <div>
      <SessionHeader {...session} />
      <SessionEditor
        {...session}
        onChange={onChange}
        isBillOwner={isBillOwner}
      />
      {isBillOwner && (
        <SessionActionButtons
          onSave={onSave}
          saveDisabled={minutesBilled < 1}
          removable={removable}
          onRemove={() => onRemove(session.uid)}
          onCancel={onCancel}
        />
      )}
    </div>
  );
}

Session.propTypes = {
  session: PropTypes.object.isRequired,
};

