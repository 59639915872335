// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Button,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Add} from "@material-ui/icons";
import {FirebaseContext} from "../../../../Firebase";
import {countryToFlag, getCurrencyFormat} from "../../../../../utils/formatting";

const menuItemStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 120,
    // minHeight: 50,
  },
}));

const MemberMenuItem = React.forwardRef(function MemberMenuItem(props, ref) {
  const {member, onClick, currency} = props;
  const [memberProfile, setMemberProfile] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const classes = menuItemStyles();
  const firebase = React.useContext(FirebaseContext);

  React.useEffect(() => {
    firebase.user(member.userId)
      .get()
      .then((snap) => {
        setMemberProfile(firebase.formatSnapshot(snap));
        setLoading(false);
      });
  }, [firebase, member.userId]);

  console.log("memberProfile", memberProfile);

  return (
    <MenuItem
      onClick={onClick}
      disabled={loading}
      className={classes.root}
      dense
      disableGutters
    >
      {loading ? (
        <LinearProgress/>
      ) : (
        <ListItem dense>
          <ListItemAvatar>
            <Avatar src={memberProfile.profilePhoto}/>
          </ListItemAvatar>
          <ListItemText
            primary={`${memberProfile.firstName} ${memberProfile.lastName} ${countryToFlag(memberProfile.country)}`}
            secondary={`${getCurrencyFormat(memberProfile.hourlyRate, currency, {convertToDecimals: true})}/hour`}
          />
        </ListItem>
      )}
    </MenuItem>
  )
});

export default function HiresSelectorButton(props) {
  const {unselectedMembers, loading, onAddHire, currency} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddHire = member => {
    setAnchorEl(null);
    onAddHire(member)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        color="primary"
        startIcon={loading ? <CircularProgress size={16}/> : <Add/>}
        disabled={unselectedMembers.length === 0 || loading}
      >
        Add hire
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {unselectedMembers
          .map(member =>
            <MemberMenuItem
              key={member.userId}
              member={member}
              onClick={() => handleAddHire(member)}
              currency={currency}
            />
          )}
      </Menu>
    </div>
  );
}

HiresSelectorButton.propTypes = {
  onAddHire: PropTypes.func.isRequired,
  unselectedMembers: PropTypes.array.isRequired,
};
