// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: ({isEditable}) => ({
    display: "flex",
    borderBottom: isEditable ? `2px dotted ${theme.palette.borders}` : "none",
    width: "fit-content",
  }),
  editIconButton: {
    marginLeft: theme.spacing(1),
  },
}));

export default function TitleEditor(props) {
  const {title, fontVariant, fontComponent, isEditable, handleClick} = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {isEditable ? (
        <Tooltip title="Edit title" placement="right">
          <Typography
            variant={fontVariant || "h4"}
            component={fontComponent || "h1"}
            align="left"
            onClick={isEditable ? handleClick : undefined}
          >
            {title}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          variant={fontVariant || "h4"}
          component={fontComponent || "h1"}
          align="left"
          onClick={isEditable ? handleClick : undefined}
        >
          {title}
        </Typography>
      )}
    </div>
  );
}

TitleEditor.propTypes = {
  title: PropTypes.string.isRequired,
};
