// React
import React from "react";
import {useLocation} from "@reach/router";
import {navigate} from "gatsby";
import queryString from "query-string";

// Components
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";
import {
  BillingDetailsPanel,
  CurrencyPanel,
  HourlyRatePanel
} from "../../components/SettingsPanels";

// Services
import {authorizeStripeCode, createStripeLoginLink, updateStripeAccount} from "../../services/payments/connect";

// Material UI
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  text: {
    // marginBottom: 20,
  },
  stripeContainer: {
    marginBottom: theme.spacing(4),
    // padding: theme.spacing(3),
  },
  stripeCardContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  stripeLogo: {
    height: 30,
    marginRight: theme.spacing(5),
    marginTop: "auto",
    marginBottom: "auto",
  },
  cardActions: {
    float: "right",
  }
}));

export default function FinancialSettings(props) {
  const [expanded, setExpanded] = React.useState({
    billingDetails: false,
    currency: false,
    hourlyRate: false,
  });
  const firebase = React.useContext(FirebaseContext);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const authUser = React.useContext(AuthUserContext);
  const classes = useStyles();
  const [billingDetails] = useFetchFirestore(
    firebase.billingDetails().where("ownerId", "==", authUser.uid),
    {
      type: "collection",
      listen: true,
      transformResult: (res) => res[0]
    }
  );


  const handlePanelChange = (panel) => (event, value) => {
    setExpanded({
      ...expanded,
      [panel]: value,
    });
  };

  React.useEffect(() => {
    if (parsedQueries.code) {
      authorizeStripeCode(parsedQueries.code)
        .then(({stripe_user_id}) => {

          // Set payout schedule to manual so that a delay of 90 days can be used on payments
          // For auto payments the max delay is 30 days.
          // Also make sure stripe does not try to recoup negative balances (debit_negative_balances)
          const updates = {
            metadata: {
              freelancerId: authUser.uid,
            },
            // "settings[payouts][schedule][delay_days]": 7,
            // "settings[payouts][schedule][interval]": "daily",
            "settings[payouts][debit_negative_balances]": false,
          };
          updateStripeAccount(stripe_user_id, updates)
            .then(() =>
              // Set stripeAccountId on firestore user doc
              firebase.user(authUser.uid)
                .update({
                  lastUpdatedAt: firebase.createTimestamp(),
                  stripeAccountId: stripe_user_id
                })
            );
        });
    }
  }, [firebase, authUser.uid, parsedQueries.code]);

  const handleConnectStripe = () => {
    const baseUrl = "https://connect.stripe.com/express/oauth/authorize";
    const queries = {
      redirect_uri: location.pathname,
      client_id: process.env.GATSBY_STRIPE_CLIENT_ID,
      // 'suggested_capabilities[0]': "transfers",
      "stripe_user[country]": authUser.country,
      "stripe_user[email]": authUser.email,
      "stripe_user[product_description]": authUser.about,
      "stripe_user[first_name]": authUser.firstName,
      "stripe_user[last_name]": authUser.lastName,
      "stripe_user[business_type]": "company",
    };
    return navigate(
      queryString.stringifyUrl({url: baseUrl, query: queries}) +
      "&suggested_capabilities%50%5D=transfers"
    );
  };

  const handleVisitStripe = () =>
    createStripeLoginLink(authUser.stripeAccountId)
      .then(({url}) => window.location.assign(url));

  return (
    <div>
      <Card className={classes.stripeContainer}>
        <CardContent className={classes.stripeCardContent}>
          <img
            alt="Stripe logo slate"
            src="https://images.ctfassets.net/h6w739vsk2p5/ZKtAD8FsqQaIolLQkbilL/eb03f266df7f8434300f2a824f3a9191/Stripe_wordmark_-_slate.svg"
            className={classes.stripeLogo}
          />
          <div>
            <Typography variant="body1">
              {authUser.stripeAccountId
                ? "Your Stripe account has been configured and you can edit settings in your dashboard."
                : "We use Stripe to make sure you get paid on time and to keep your personal details secure."
              }
            </Typography>
            {!authUser.stripeAccountId && !billingDetails && (
              <Typography variant="caption" component="p">
                You first have to save your billing details in order to connect Stripe.
              </Typography>
            )}
          </div>
        </CardContent>
        <Divider/>
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            className={classes.mainCtaButton}
            onClick={
              authUser.stripeAccountId ? handleVisitStripe : handleConnectStripe
            }
            disabled={!billingDetails}
          >
            {authUser.stripeAccountId
              ? "Visit Stripe dashboard"
              : "Connect Stripe"
            }
          </Button>
        </CardActions>
      </Card>
      <BillingDetailsPanel
        billingDetails={billingDetails}
        handlePanelChange={handlePanelChange("billingDetails")}
        isExpanded={expanded.billingDetails}
        taxNumberRequired
      />
      <CurrencyPanel
        isExpanded={Boolean(expanded.currency)}
        handlePanelChange={handlePanelChange("currency")}
        canMakeEdits={false}
        value={authUser.currency}
        firebaseRef={firebase.user(authUser.uid)}
      />
      {/* Only show freelancer hourly rate after currency has been chosen */}
      <HourlyRatePanel
        isExpanded={Boolean(expanded.hourlyRate)}
        handlePanelChange={handlePanelChange("hourlyRate")}
        canMakeEdits={Boolean(authUser.currency)}
      />
    </div>
  );
}

