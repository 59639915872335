// React
import React from "react";
import PropTypes from "prop-types";

// Components
import Row from "./Row";

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function TableList(props) {
  const classes = useStyles();
  const {rows, totalsRow, headCells} = props;

  return (
    <TableBody className={classes.root}>
      {rows.map((rowData) => (
        <Row key={rowData.id} rowData={rowData} headCells={headCells}/>
      ))}
      {totalsRow && (
        <Row
          key={totalsRow.id}
          rowData={totalsRow}
          headCells={headCells}
          isTotalsRow={true}
        />
      )}
    </TableBody>
  );
}

TableList.propTypes = {
  rows: PropTypes.array.isRequired,
};
