// React
import React from "react";
import {navigate} from "gatsby";

// Constants
import * as routes from "../../constants/routes";

// Components
import BodyContainer from "../../components/BodyContainer";
import PersonalSettingsTab from "./PersonalSettingsTab";
import ExperienceSettingsTab from "./ExperienceSettingsTab";
import FinancialSettingsTab from "./FinancialSettingsTab";

// Material UI
import {Tab, Tabs, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';

// Section to add later: "Account access"
// Account access should allow a user to update email and password
const tabs = [
  {
    label: "Personal info",
    value: "personal",
    route: routes.freelancerSettingsPersonal,
    component: <PersonalSettingsTab/>
  },
  {
    label: "Experience",
    value: "experience",
    route: routes.freelancerSettingsExperience,
    component: <ExperienceSettingsTab/>
  },
  {
    label: "Financial",
    value: "financial",
    route: routes.freelancerSettingsFinancial,
    component: <FinancialSettingsTab/>
  },
  // {
  //   label: "Notifications",
  //   description: "Name, profile photo",
  //   destRoute: routes.freelancerSettingsNotifications,
  // }
];

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  settingsHeaderText: {},
  tabs: {
    // width: "100%",
    // display: "flex",
    // justifyContent: "space-between",
    // padding: "0px 30px 0px 30px",
    // borderBottom: `1px solid ${theme.palette.borders}`,
  },
  tabRoot: {
    width: 120,
    minWidth: 120,
  },
  wrapper: {
    // height: 45,
    width: 100,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function FreelancerSettingsPage(props) {

  const {match} = props;
  const [activeTabValue, setActiveTabValue] = React.useState(match.tab);
  const classes = useStyles(props);

  React.useEffect(() => setActiveTabValue(match.tab), [match.tab]);

  const handleToggleTab = (event, value) => navigate(
    routes.freelancerSettingsTab
      .replace(":tab", value)
  );

  const activeTab = tabs.filter(({value}) => value === activeTabValue)[0];
  return (
    <BodyContainer topGutterSpacing={4} bottomGutter>
      <Typography variant="h6" component="h1">Settings</Typography>
      <Tabs
        value={activeTabValue}
        onChange={handleToggleTab}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.indicator,
          tabs: classes.tabs,
        }}
      >
        {tabs.map(({label, value, disabled}) =>
          <Tab
            key={value}
            value={value}
            label={label}
            color="primary"
            classes={{
              root: classes.tabRoot,
              wrapper: classes.wrapper,
            }}
            className={classes.tab}
            disabled={disabled}
          />
        )}
      </Tabs>
      {activeTab ? (
        activeTab.component
      ) : (
        <Typography>Something went wrong</Typography>
      )}
    </BodyContainer>
  );
}

FreelancerSettingsPage.propTypes = {};
