// React
import React from "react";
import PropTypes from 'prop-types';

// Components
import {AuthUserContext} from "../../../components/Session";
import {FirebaseContext} from "../../../components/Firebase";
import SelectorTextfield from "../../../components/SelectorTextfield";

// Material UI
import {Typography, Button} from "@material-ui/core";

const messageCharLimit = 500;

export default function TeamMemberApply(props) {
  const {team} = props;
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  const [message, setMessage] = React.useState("");
  const [isApplying, setIsApplying] = React.useState(false);

  const handleChange = (prop, value) => {
    const messageValue = value ? value.substring(0, messageCharLimit) : value;
    setMessage(messageValue);
  };

  const handleSubmitApplication = () => {
    setIsApplying(true);
    firebase.teamApplications()
      .add({
        createdAt: firebase.createTimestamp(),
        lastUpdatedAt: firebase.createTimestamp(),
        ownerId: authUser.uid,
        teamId: team.uid,
        message,
        status: "pending",
      })
      .then(() => setIsApplying(false));
  };

  return (
    <div>
      <Typography>{`Increase your chances of getting accepted by leaving a personal message`}</Typography>
      <SelectorTextfield
        id='apply-cover-letter'
        label="Personal message"
        value={message}
        handleChange={handleChange}
        multiline={true}
        rowsMax={20}
        nrOfRows={5}
        characterLimit={messageCharLimit}
        topGutterSpacing={3}
        bottomGutterSpacing={3}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmitApplication}
        disabled={isApplying}
      >
        Submit application
      </Button>
    </div>
  );
}

TeamMemberApply.propTypes = {
  team: PropTypes.object.isRequired,
};
