// React
import React from "react";

// Components
import EmptyStateView from "../../../components/EmptyStateView";

// MUI
import {Build} from "@material-ui/icons";

export default function ClientInvitesTab(props) {

  return (
    <EmptyStateView
      text="Soon you can invite your own clients here"
      icon={<Build/>}
      topSpacing={5}
    />
  );
}

ClientInvitesTab.propTypes = {
  // team: PropTypes.object.isRequired,
};
