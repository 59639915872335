// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";
import SessionsList from "./SessionsList";
import Session from "./Session";
import LoadingIndicator from "../../components/LoadingIndicator";
import PageNotFound from "../../components/PageNotFound";
import BodyContainer from "../../components/BodyContainer";
import Link from "../../components/Link";

// Utils
import {convertUnixTsToDate, getMinutesBetweenTimestamps} from "../../utils/time";
import BillHeader from "./BillHeader";
import Divider from "@material-ui/core/Divider";
import {minBillAmountExclEur} from "../../constants/settings";
import {formatCentsToDisplayPrice, formatPriceToCents, getCurrencyFormat} from "../../utils/formatting";
import * as routes from "../../constants/routes";
import {trackEvent} from "../../services/analytics";

function getPlaceholderDate(minusMinutes) {
  const date = new Date();
  date.setSeconds("00", "00");
  date.setMinutes(date.getMinutes() - minusMinutes);
  return date;
}

function getMinutesSpend(startTime, endTime) {
  const startedAtTs = new Date(startTime) / 1000;
  const endedAtTs = new Date(endTime) / 1000;
  return getMinutesBetweenTimestamps(startedAtTs, endedAtTs);
}

function getAmount(minutes, hourlyRate) {
  return Math.round((minutes / 60) * hourlyRate);
}

function getErrorTitle(error) {
  if (error === "belowBillMinimum") {
    return `Billing amount too low`
  } else if (error === "missingStripeConnection") {
    return `Create a Stripe account to receive payouts`
  }
  return "An unknown error occured"
}

function getErrorMessage(error) {
  if (error === "belowBillMinimum") {
    return <span>{`The minimum bill amount (excluding tax) is ${getCurrencyFormat(minBillAmountExclEur, "eur", {convertToDecimals: true})}`}</span>
  } else if (error === "missingStripeConnection") {
    return <span>{`You can connect Stripe in your `}<Link to={routes.freelancerSettingsFinancial} color="primary">financial settings</Link></span>
  }
  return <span>Contact support if the issue persists.</span>
}

export default function FreelancerBillPage(props) {
  const {match} = props;
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);
  const [preProcessing, setPreProcessing] = React.useState(false);

  const [bill, loadingBill] = useFetchFirestore(
    firebase.bill(match.billId),
    {
      listen: true,
      transformResult: (r) => r ? ({ 
        ...r, 
        monthlyBudget: formatCentsToDisplayPrice(r.monthlyBudget),
        hourlyRate: formatCentsToDisplayPrice(r.hourlyRate),
      }) : null,
    }
  );

  const billIsProcessing = bill ? bill.processing : false;
  React.useEffect(() => {
    if (billIsProcessing) {
      setPreProcessing(false);
    }
  }, [billIsProcessing])

  const hireContractsRef = bill ? (
    firebase.hireContracts()
      .where("contractId", "==", bill.contractId)
      .where("freelancerId", "==", bill.ownerId)
      .where("status", "in", ['active', 'replaced', 'stopped'])
  ) : null;
  const [hireContracts, loadingHireContracts] = useFetchFirestore(
    hireContractsRef,
    {
      type: "collection", 
      listen: true, 
      stopLoading: !bill && !loadingBill,
      transformResult: (r) => r
        .map(ahc => ({ 
          ...ahc, 
          monthlyBudget: formatCentsToDisplayPrice(ahc.monthlyBudget),
          hourlyRate: formatCentsToDisplayPrice(ahc.hourlyRate),
        }))
    }
  );
  const activeHireContract = hireContracts
    .filter(({ status }) => status === "active")[0]

  const sessionsRef = bill ? (
    firebase.sessions()
      .where("ownerId", "==", bill.ownerId)
      .where("billId", "==", bill.uid)
      .where("teamId", "==", bill.teamId)
      .where("excluded", "==", false)
  ) : null;
  const [sessions, loadingSessions] = useFetchFirestore(
    sessionsRef,
    {
      type: "collection", 
      listen: true, 
      stopLoading: !bill && !loadingBill,
      transformResult: r => r.map(s => ({
        ...s,
        startedDate: convertUnixTsToDate(s.startedAt.seconds),
        endedDate: convertUnixTsToDate(s.endedAt.seconds),
        amountExcl: formatCentsToDisplayPrice(s.amountExcl),
      }))
    }
  );

  const [team, loadingTeam] = useFetchFirestore(
    bill ? firebase.team(bill.teamId) : null,
    { stopLoading: !bill && !loadingBill }
  );

  const [selectedSession, setSelectedSession] = React.useState(null);
  const [error, setError] = React.useState(null);

  const handleAddSession = () => {
    const startedDate = getPlaceholderDate(15);
    const endedDate = getPlaceholderDate(0);
    const minutes = getMinutesSpend(startedDate, endedDate);
    const newSession = {
      startedDate,
      endedDate,
      description: "",
      minutes,
      minutesBreak: 0,
      amountExcl: getAmount(minutes, bill.hourlyRate),
    };
    setSelectedSession(newSession)
  };

  const handleSelectSession = (s) => setSelectedSession(s);

  const handleChangeSession = (prop, value) => {
    if (prop === "description") {
      setSelectedSession(s => ({ 
        ...s, 
        description: value ? value.substring(0, 500) : ""
      }))
    } else if (prop === "minutesBreak") {
      setSelectedSession(s => ({ 
        ...s, 
        minutesBreak: value < 0 ? null : parseInt(value)
      }))
    } else {
      const updatedSession = {
        ...selectedSession,
        [prop]: new Date(value),
      }
      const minutes = getMinutesSpend(updatedSession["startedDate"], updatedSession["endedDate"]);
      const minutesBilled = minutes - updatedSession.minutesBreak;
      const amountExcl = activeHireContract 
        ? getAmount(minutesBilled, activeHireContract.hourlyRate)
        : updatedSession.amountExcl
      setSelectedSession(s => ({ 
        ...updatedSession, 
        minutes,
        amountExcl
      }))
    }
  };

  const handleRemoveSession = (sessionId) => {
    setPreProcessing(true)
    const ts = firebase.createTimestamp();
    firebase.session(sessionId)
      .update({
        lastUpdatedAt: ts,
        excluded: true,
        excludedAt: ts,
        excludedBy: authUser.uid,
        excludedReason: "removed",
      })
      .then(() => {
        setSelectedSession(null)
      })
  };

  const handleSaveSession = () => {
    const ts = firebase.createTimestamp();
    if (selectedSession.uid) {
      setPreProcessing(true)
      firebase.session(selectedSession.uid)
        .update({
          lastUpdatedAt: ts,
          description: selectedSession.description || "",
        })
        .then(() => setSelectedSession(null))
    } else if (activeHireContract) {
      setPreProcessing(true)
      firebase.contract(activeHireContract.contractId)
        .get()
        .then(snap => {
          const contract = firebase.formatSnapshot(snap);
          return firebase.sessions()
            .add({
              createdAt: ts,
              excluded: false,
              ownerId: authUser.uid,
              lastUpdatedAt: ts,
              billId: bill.uid,
              // Store hireContractId and contractVersionId on new sessions
              // Used for efficiently fetching billing history on contracts pages
              teamId: contract.teamId,
              contractId: contract.uid,
              hireContractId: activeHireContract.uid,
              contractVersionId: contract.applicableVersionId,
              businessId: bill.businessId,
              ...selectedSession,
              startedAt: selectedSession.startedDate,
              endedAt: selectedSession.endedDate,
              amountExcl: formatPriceToCents(selectedSession.amountExcl)
            })
        })
        .then((docRef) => {

          trackEvent('session_added', { 
            sessionId: docRef.id,
            minutes: selectedSession.minutes,
            amountExcl: formatCentsToDisplayPrice(selectedSession.amountExcl),
          })

          setSelectedSession(null)
        })
    }
  };

  const handleCancelChanges = () => setSelectedSession(null);

  const handleSubmitBill = () => {
    const ts = firebase.createTimestamp();
    if (!authUser.stripeAccountId) {
      setError("missingStripeConnection")
    }
    else if (bill.amountExcl < minBillAmountExclEur) {
      setError("belowBillMinimum")
    }
    else {
      
      // Fetch all contract sessions by the freelancer in current month
      const billCreatedDate = convertUnixTsToDate(bill.createdAt.seconds);
      const firstDayBillCreationMonth = new Date(billCreatedDate.getFullYear(), billCreatedDate.getMonth() + 1, 1);
      firebase.sessions()
        .where("ownerId", "==", bill.ownerId)
        .where("contractId", "==", bill.contractId)
        // .where("billId", "!=", bill.uid)
        .where("createdAt", ">=", firstDayBillCreationMonth)
        .where("excluded", "==", false)
        .orderBy("createdAt", "asc")
        .get()
        .then(snap => {

          // Filter out current bill sessions and format
          const otherContractSessions = firebase.formatSnapshot(snap)
            .filter(({ billId }) => billId !== bill.uid)
            .map(ocs => ({
              ...ocs,
              startedAt: convertUnixTsToDate(ocs.startedAt.seconds),
              endedAt: convertUnixTsToDate(ocs.endedAt.seconds),
              amountExcl: formatCentsToDisplayPrice(ocs.amountExcl),
            }));

          // Get monthly budget left for the months otherContractSessions spans
          const budgetLeftPerMonth = {};
          for (let i = 0; i < otherContractSessions.length; i++) {
            const ocs = otherContractSessions[i];
            const createdDate = convertUnixTsToDate(ocs.createdAt.seconds);
            const createdMonthIso = `${createdDate.getFullYear()}${createdDate.getMonth() + 1}`;
            const applicableHireContract = hireContracts
              .filter(({ uid }) => uid === ocs.hireContractId)[0];
            // Set the monthly budget for each month to highest active hireContract in that month
            const applicableMonthlyBudget = applicableHireContract ? applicableHireContract.monthlyBudget : 0;
            if (!budgetLeftPerMonth[createdMonthIso] || budgetLeftPerMonth[createdMonthIso] < applicableMonthlyBudget) {
              budgetLeftPerMonth[createdMonthIso] = applicableHireContract.monthlyBudget;
            } 
            budgetLeftPerMonth[createdMonthIso] -= ocs.amountExcl;
          }
          
          // Iterate bill sessions and add to within or outside budget 
          const sessionsWithinBudget = [];
          const sessionsOutsideBudget = [];
          for (let i = 0; i < sessions.length; i++) {
            const s = sessions[i];
            const createdDate = convertUnixTsToDate(s.createdAt.seconds);
            const createdMonthIso = `${createdDate.getFullYear()}${createdDate.getMonth() + 1}`;
            // If no budget exists for session month then that means it's the first bill
            // (so set applicableHireContract monthlyBudget)
            if (!budgetLeftPerMonth[createdMonthIso]) {
              const applicableHireContract = hireContracts
                .filter(({ uid }) => uid === s.hireContractId)[0];
              budgetLeftPerMonth[createdMonthIso] = applicableHireContract ? applicableHireContract.monthlyBudget : 0;
            } 
            // Add session to outside or inside budget
            budgetLeftPerMonth[createdMonthIso] -= s.amountExcl;
            if (budgetLeftPerMonth[createdMonthIso] < 0) {
              sessionsOutsideBudget.push(s.uid)
            } else {
              sessionsWithinBudget.push(s.uid)
            }
          }

          return { sessionsWithinBudget, sessionsOutsideBudget }
        })
        .then(({ sessionsWithinBudget, sessionsOutsideBudget }) => {
          return firebase.bill(bill.uid)
            .update({
              sessionsWithinBudget,
              sessionsOutsideBudget,
              lastUpdatedAt: ts,
              becameStatusPaymentRequestedAt: ts,
              status: "paymentRequested",
            })
        })
        .then(() => {
          trackEvent('bill_submitted', { 
            billId: bill.uid,
            minutes: bill.minutes,
            amountExcl: formatCentsToDisplayPrice(bill.amountExcl),
            serviceFeeExcl: formatCentsToDisplayPrice(bill.serviceFee.amountExcl),
          })
        })
    } 
  };

  if (loadingBill || loadingHireContracts || loadingSessions || loadingTeam) {
    return (
      <LoadingIndicator 
        message="Loading bill..." 
        inheritHeight 
        topSpacing={10}
      />
    )
  } else if (!bill || !team) {
    return <PageNotFound/>;
  }

  const isBillOwner = bill.ownerId === authUser.uid;
  return (
    <BodyContainer 
      topGutterSpacing={4} 
      bottomGutter
      errorTitle={error ? getErrorTitle(error) : null}
      errorMessage={error ? getErrorMessage(error) : null}
      handleResetError={() => setError(null)}
    >
      <BillHeader 
        bill={bill}
        sessions={sessions}
        hideRemoveBtn={Boolean(selectedSession)}
        isBillOwner={isBillOwner}
      />
      <Divider style={{marginBottom: 24, marginTop: 24}}/>
      {selectedSession ? (
        <Session
          session={selectedSession}
          onChange={handleChangeSession}
          onSave={handleSaveSession}
          removable={(bill.status === "draft" || bill.status === "pending") && selectedSession.uid}
          onRemove={handleRemoveSession}
          onCancel={handleCancelChanges}
          isBillOwner={isBillOwner}
        />
      ) : (
        <SessionsList
          sessions={sessions}
          bill={{
            ...bill,
            processing: Boolean(preProcessing || bill.processing)
          }}
          activeHireContract={activeHireContract}
          handleAddSession={handleAddSession}
          handleSelectSession={handleSelectSession}
          handleSubmitBill={handleSubmitBill}
          isBillOwner={isBillOwner}
        />
      )}
    </BodyContainer>
  );
}

FreelancerBillPage.propTypes = {
  match: PropTypes.object.isRequired,
};
