// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Components
import {FirebaseContext, useFetchFirestore} from "../../../components/Firebase";
import EnhancedTableHead from "../../../components/EnhancedTableHead";
import StatusLabel from "../../../components/StatusLabel";
import LoadingIndicator from "../../../components/LoadingIndicator";
import EmptyStateView from "../../../components/EmptyStateView";

// MUI
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Avatar,
  CardHeader,
  Typography
} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Star} from "@material-ui/icons";

// Utils
import {convertUnixTsToDate} from "../../../utils/time";
import {stableSort, getSorting} from "../../../utils/sorting";

// Constants
import * as routes from "../../../constants/routes";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    width: '100%',
    marginTop: topGutterSpacing ? theme.spacing(topGutterSpacing) : 0,
    marginBottom: bottomGutterSpacing ? theme.spacing(bottomGutterSpacing) : 0,
  }),
  table: {
    minWidth: 750,
  },
  tableRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardHeaderRoot: {
    padding: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}));

const headCells = [
  {
    id: "client",
    label: "Client",
  },
  {
    id: "dateSubmitted",
    label: "Received at",
  },
  {
    id: "requestStatus",
    label: "Status",
  },
];

function createRequestRows({requests, profiles, leadingTeamId }) {
  return requests
    .map(({uid, status, ownerId, businessName, becameStatusOpenAt, teamOptions}) => {
      // Build and return the row
      const profile = profiles
        .filter(user => user.uid === ownerId)[0];
      return ({
        requestId: uid,
        status,
        fullName: profile ? `${profile.firstName} ${profile.lastName}` : null,
        businessName,
        profilePhoto: profile ? profile.profilePhoto : null,
        dateSubmitted: convertUnixTsToDate(becameStatusOpenAt.seconds),
        teamId: leadingTeamId,
        teamStatus: teamOptions[leadingTeamId].status,
      });
    });
}

const rowsPerPage = 10;

export default function ClientRequestsTab(props) {
  const {leadingTeam, loadingTeams} = props;
  const firebase = React.useContext(FirebaseContext);

  const leadingTeamId = leadingTeam ? leadingTeam.uid : null;
  const [requests, loadingRequests] = useFetchFirestore(
    leadingTeamId ? (
      firebase
        .requests()
        .where(`teamOptions.${leadingTeamId}.teamId`, "==", leadingTeamId)
        .limit(10)
    ) : null,
    {type: "collection", stopLoading: Boolean(!loadingTeams && !leadingTeamId)}
  );

  const requestOwnerIds = requests
    .map(({ownerId}) => ownerId);
  const [profiles, loadingProfiles] = useFetchFirestore(
    requestOwnerIds.length > 0 ? (
      firebase.users()
        .where("uid", "in", requestOwnerIds)
    ) : null,
    {type: "collection", stopLoading: !loadingRequests && requestOwnerIds.length === 0}
  );

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);

  const classes = useStyles(props);
  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = ({requestId, teamId}) => {
    const url = queryString.stringifyUrl({
      url: routes.freelancerRequest.replace(":requestId", requestId),
      query: {team: teamId}
    });
    return navigate(url);
  };

  if (loadingTeams || loadingRequests || loadingProfiles) {
    return (
      <LoadingIndicator
        message="Loading members..."
        topSpacing={10}
        inheritHeight
      />
    )
  } else if (!leadingTeamId) {
    return <EmptyStateView text="Only team leads can receive requests from clients" icon={<Star/>} topSpacing={5}/>
  } else if (requests.length === 0) {
    return <EmptyStateView text="No requests found" icon={<Star/>} topSpacing={5}/>
  }

  const rows = createRequestRows({ requests, profiles, leadingTeamId });

  return (
    <div>
      <TableContainer>
        <Table
          className={classes.table}
          size='medium'
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const {fullName, teamStatus, profilePhoto, dateSubmitted, businessName} = row;
                return (
                  <TableRow
                    hover
                    onClick={() => handleRowClick(row)}
                    key={index}
                    className={classes.tableRow}
                  >
                    <TableCell component="th" id="name" scope="row">
                      <CardHeader
                        classes={{
                          root: classes.cardHeaderRoot,
                        }}
                        avatar={
                          profilePhoto ? (
                            <Avatar
                              src={profilePhoto}
                              className={classes.avatar}
                            />
                          ) : (
                            <Avatar className={classes.avatar}>
                              {fullName.substring(0, 1)}
                            </Avatar>
                          )
                        }
                        title={fullName}
                        titleTypographyProps={{variant: "body2", component: "h1"}}
                        subheader={businessName}
                      />
                    </TableCell>
                    <TableCell id="dateSubmitted">
                      <Typography>{dateSubmitted.toLocaleDateString()}</Typography>
                    </TableCell>
                    <TableCell id="requestStatus">
                      <StatusLabel
                        color={theme.palette.statusColors[teamStatus]}
                        value={teamStatus}
                        shape="square"
                        clickable
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
    </div>
  );
}

ClientRequestsTab.propTypes = {
  leadingTeam: PropTypes.object,
  loadingTeams: PropTypes.bool.isRequired,
};
