// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {FirebaseContext, useFetchFirestore} from "../../../components/Firebase";
import LoadingIndicator from "../../../components/LoadingIndicator";
import FreelancerCard from "../../../components/FreelancerCard";
import EmptyStateView from "../../../components/EmptyStateView";
import FilterBar from "../../../components/FilterBar";
import FreelancerDialog from "../../../components/FreelancerDialog";
import FreelancerProfile from "../../../components/FreelancerProfile";

// MUI
import {NotInterested, PauseCircleOutline, People, PlayCircleOutline, MailOutline} from "@material-ui/icons";
import {amber, green, red} from "@material-ui/core/colors";

const startIconStyleProps = {
  fontSize: 14,
  marginTop: "auto",
  marginBottom: "auto",
  marginRight: 8,
};

const statusOptions = [
  {
    value: "active",
    text: "Active",
    startIcon: <PlayCircleOutline style={{color: green[500], ...startIconStyleProps}}/>,
  },
  {
    value: "nonActive",
    text: "Non-active",
    startIcon: <PauseCircleOutline style={{color: amber[500], ...startIconStyleProps}}/>,
  },
  {
    value: "left",
    text: "Left",
    startIcon: <NotInterested style={{color: red[500], ...startIconStyleProps}}/>,
  },
];

export default function TeamMembers(props) {
  const {team} = props;
  const firebase = React.useContext(FirebaseContext);

  const [filters, setFilters] = React.useState({
    queryFilter: "",
    statusFilter: "active",
  });

  const teamMemberIds = Object.keys(team.members)
    .map(memberId => team.members[memberId])
    .filter(({status}) => status === filters.statusFilter)
    .slice(0, 10)
    .map(({userId}) => userId);

  console.log("teamMemberIds", teamMemberIds);
  const membersRef = teamMemberIds.length > 0 ? firebase.users().where("uid", "in", teamMemberIds) : null;
  const [memberProfiles, loadingMemberProfiles] = useFetchFirestore(
    membersRef,
    {type: "collection", stopLoading: teamMemberIds.length === 0}
  );
  const [selectedMemberId, setSelectedMemberId] = React.useState(null);

  console.log("memberProfiles", memberProfiles);

  const handleOpenMemberDialog = memberId => setSelectedMemberId(memberId);
  const handleCloseMemberDialog = () => setSelectedMemberId(null);

  const handleSearch = () => {

  };

  const handleSelectFilter = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  const selectedMemberProfile = selectedMemberId ? memberProfiles.filter(({uid}) => uid === selectedMemberId)[0] : null;
  return (
    <div>
      {selectedMemberProfile && (
        <FreelancerDialog
          freelancer={selectedMemberProfile}
          handleClose={handleCloseMemberDialog}
          DialogBodyContent={
            <FreelancerProfile
              freelancer={selectedMemberProfile}
              team={team}
            />
          }
          DialogActionsContent={
            <div style={{display: "flex"}}>
              <MailOutline style={{marginRight: 8, marginTop: "auto", marginBottom: "auto"}}/>
              <a style={{marginTop: "auto", marginBottom: "auto"}}
                 href={`mailto:${selectedMemberProfile.email}`}>{selectedMemberProfile.email}</a>
            </div>
          }
          // DialogActionsContent={
          //   selectedApplicant.status === "pending" ? (
          //     <div style={{display: "flex"}}>
          //       <Button
          //         disabled={isRejecting}
          //         startIcon={isRejecting ? <CircularProgress/> : <Cancel/>}
          //         onClick={() => handleRejectApplicant(selectedApplicant)}
          //         variant="outlined"
          //         color="primary"
          //       >
          //         Reject
          //       </Button>
          //       <Button
          //         disabled={isAccepting}
          //         startIcon={isAccepting ? <CircularProgress/> : <Done/>}
          //         onClick={() => handleAcceptApplicant(selectedApplicant)}
          //         style={{marginLeft: 8}}
          //         variant="contained"
          //         color="primary"
          //       >
          //         Accept
          //       </Button>
          //     </div>
          //   ) : undefined
          // }
        />
      )}
      <FilterBar
        searchQueryPlaceholder="Search for members"
        onSearch={handleSearch}
        filterOneLabel="Status"
        filterOneOptions={statusOptions}
        filterOneValue={filters.statusFilter}
        onFilterOneChange={(value) => handleSelectFilter("statusFilter", value)}
        bottomGutterSpacing={3}
      />
      {memberProfiles.length > 0 ? (
        memberProfiles
          // .filter(({uid}) => uid !== team.teamLeadId)
          .map(member =>
            <FreelancerCard
              freelancer={member}
              handleActionAreaClick={() => handleOpenMemberDialog(member.uid)}
              topGutterSpacing={2}
              hideAbout
            />
          )
      ) : loadingMemberProfiles ? (
        <LoadingIndicator
          message="Loading members..."
          topSpacing={10}
          inheritHeight
        />
      ) : (
        <EmptyStateView
          text="No members match your filters"
          icon={<People/>}
          topSpacing={5}
        />
      )}
    </div>
  );
}

TeamMembers.propTypes = {
  team: PropTypes.object.isRequired,
};
