// React
import React from "react";

// Components
import {FirebaseContext, useFetchFirestore} from "../../../components/Firebase";
import EmptyStateView from "../../../components/EmptyStateView";
import LoadingIndicator from "../../../components/LoadingIndicator";
import FreelancerCard from "../../../components/FreelancerCard";
// import StatusLabel from "../../../components/StatusLabel";
import FreelancerDialog from "../../../components/FreelancerDialog";
import FilterBar from "../../../components/FilterBar";

// Material UI
import {Button, CircularProgress} from "@material-ui/core";
import {Cancel, Done, People, PersonAdd} from "@material-ui/icons";
import {amber, red, green} from "@material-ui/core/colors";
import FreelancerProfile from "../../../components/FreelancerProfile";

const startIconStyleProps = {
  fontSize: 14,
  marginTop: "auto",
  marginBottom: "auto",
  marginRight: 8,
};

const statusOptions = [
  {
    value: "pending",
    text: "Pending",
    startIcon: <PersonAdd style={{color: amber[700], ...startIconStyleProps}}/>,
  },
  {
    value: "accepted",
    text: "Accepted",
    startIcon: <Done style={{color: green[500], ...startIconStyleProps}}/>,
  },
  {
    value: "rejected",
    text: "Rejected",
    startIcon: <Cancel style={{color: red[500], ...startIconStyleProps}}/>,
  },
];

export default function TeamApplicants(props) {

  const {team} = props;
  const firebase = React.useContext(FirebaseContext);

  const [filters, setFilters] = React.useState({
    queryFilter: "",
    statusFilter: "pending",
  });

  const [applicants, loadingApplicants] = useFetchFirestore(
    firebase.teamApplications()
      .where("teamId", "==", team.uid)
      .where("status", "==", filters.statusFilter)
      .limit(10),
    {"type": "collection", listen: true}
  );
  const applicantIds = applicants.map(({ownerId}) => ownerId);

  const [applicantProfiles, loadingApplicantProfiles] = useFetchFirestore(
    applicants.length > 0 ? firebase.users().where("uid", "in", applicantIds) : null,
    {type: "collection", stopLoading: !loadingApplicants && applicants.length === 0}
  );
  const [selectedApplicantId, setSelectedApplicantId] = React.useState(null);
  const [isAccepting, setIsAccepting] = React.useState(false);
  const [isRejecting, setIsRejecting] = React.useState(false);


  // const theme = useTheme();

  const handleClickApplicant = applicantUserId => setSelectedApplicantId(applicantUserId);
  const handleCloseApplicantDialog = () => setSelectedApplicantId(null);

  const handleSearch = () => {

  };

  const handleSelectFilter = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  const handleAcceptApplicant = application => {
    setIsAccepting(true);
    firebase.teamApplication(application.uid)
      .update({
        lastUpdatedAt: firebase.createTimestamp(),
        becameStatusAcceptedAt: firebase.createTimestamp(),
        status: "accepted",
      })
      .then(() =>
        firebase.team(team.uid)
          .update({
            lastUpdatedAt: firebase.createTimestamp(),
            members: {
              ...team.members,
              [application.ownerId]: {
                userId: application.ownerId,
                createdAt: firebase.createTimestamp(),
                becameStatusActiveAt: firebase.createTimestamp(),
                status: "active",
              },
              noActiveMembers: team.noActiveMembers + 1,
            }
          })
      )
      .then(() => {
        setIsAccepting(false);
        return handleCloseApplicantDialog()
      })
  };

  const handleRejectApplicant = application => {
    setIsRejecting(true);
    firebase.teamApplication(application.uid)
      .update({
        lastUpdatedAt: firebase.createTimestamp(),
        becameStatusRejectedAt: firebase.createTimestamp(),
        status: "rejected",
      })
      .then(() => {
        setIsRejecting(false);
        return handleCloseApplicantDialog()
      })
  };

  // const rows = createApplicantRows(team.members, memberProfiles);
  const selectedApplicant = selectedApplicantId ? applicants.filter(({ownerId}) => ownerId === selectedApplicantId)[0] : null;
  const selectedApplicantProfile = selectedApplicant ? applicantProfiles.filter(({uid}) => uid === selectedApplicant.ownerId)[0] : null;
  return (
    <div>
      {(selectedApplicant && selectedApplicantProfile) && (
        <FreelancerDialog
          freelancer={selectedApplicantProfile}
          handleClose={handleCloseApplicantDialog}
          DialogBodyContent={
            <FreelancerProfile
              freelancer={selectedApplicantProfile}
              team={team}
            />
          }
          DialogActionsContent={
            selectedApplicant.status === "pending" ? (
              <div style={{display: "flex"}}>
                <Button
                  disabled={isRejecting}
                  startIcon={isRejecting ? <CircularProgress/> : <Cancel/>}
                  onClick={() => handleRejectApplicant(selectedApplicant)}
                  variant="outlined"
                  color="primary"
                >
                  Reject
                </Button>
                <Button
                  disabled={isAccepting}
                  startIcon={isAccepting ? <CircularProgress/> : <Done/>}
                  onClick={() => handleAcceptApplicant(selectedApplicant)}
                  style={{marginLeft: 8}}
                  variant="contained"
                  color="primary"
                >
                  Accept
                </Button>
              </div>
            ) : undefined
          }
        />
      )}
      <FilterBar
        searchQueryPlaceholder="Search for applicants"
        onSearch={handleSearch}
        filterOneLabel="Status"
        filterOneOptions={statusOptions}
        filterOneValue={filters.statusFilter}
        onFilterOneChange={(value) => handleSelectFilter("statusFilter", value)}
        bottomGutterSpacing={3}
      />
      {applicants.length > 0 ? (
        applicants
          .filter(applicant => applicantProfiles.filter(({uid}) => uid === applicant.ownerId)[0])
          .map(applicant => {
            const freelancer = applicantProfiles.filter(({uid}) => uid === applicant.ownerId)[0];
            return (
              <FreelancerCard
                freelancer={freelancer}
                // CardActionsContent={
                //   <StatusLabel
                //     color={theme.palette.statusColors[applicant.status]}
                //     value={applicant.status}
                //     size="small"
                //     shape="square"
                //   />
                // }
                handleActionAreaClick={() => handleClickApplicant(freelancer.uid)}
                topGutterSpacing={2}
              />
            )
          })
      ) : (loadingApplicants || loadingApplicantProfiles) ? (
        <LoadingIndicator
          message="Loading applicants..."
          topSpacing={10}
          inheritHeight
        />
      ) : (
        <EmptyStateView
          text="No applications match your filters"
          icon={<People/>}
          topSpacing={5}
        />
      )}
    </div>
  );
}

TeamApplicants.propTypes = {};
