// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import {LinearProgress, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    width: "100%",
  },
  progressBar: {
    height: theme.spacing(1),
    width: "100%",
    marginLeft: theme.spacing(3),
    borderRadius: 100,
    maxWidth: theme.spacing(15),
    marginTop: "auto",
    marginBottom: "auto",
  }
}));

export default function CreateBusinessHeader(props) {
  const {stepIdx, nrOfSteps, text} = props;
  const progressValue = (stepIdx / nrOfSteps) * 100;
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h1">{text}</Typography>
      <LinearProgress
        variant="determinate"
        value={progressValue}
        className={classes.progressBar}
      />
    </div>
  )
}

CreateBusinessHeader.propTypes = {
  stepIdx: PropTypes.number,
  nrOfSteps: PropTypes.number,
};
