// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  checkboxTextContainer: {
    // position: "relative",
    marginLeft: 5,
    marginTop: "auto",
    marginBottom: "auto",
  },
  checkbox: {
    // position: "relative",
    marginLeft: -10,
    color: theme.palette.primary.main,
  },
  checkboxMargin: {
    margin: 10,
  },
  checkboxTextErrorContainer: {
    display: "block",
  },
  checkboxError: {
    margin: 10,
    color: theme.palette.error.main,
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

export default function TermsCheckbox(props) {
  const {
    termsAccepted,
    onToggleAcceptTerms,
    gutter,
    errorMessage,
    text,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Checkbox
        id="checkbox"
        checked={termsAccepted}
        onClick={onToggleAcceptTerms}
        value="termsAccepted"
        color="primary"
        className={classNames(
          errorMessage ? classes.checkboxError : classes.checkbox,
          gutter ? classes.checkboxMargin : undefined
        )}
      />
      <div
        className={classNames(
          classes.checkboxTextContainer,
          errorMessage ? classes.checkboxTextErrorContainer : null
        )}
      >
        {text}
        <Typography className={classes.errorText} variant="caption">
          {errorMessage}
        </Typography>
      </div>
    </div>
  );
}

TermsCheckbox.propTypes = {
  termsAccepted: PropTypes.bool.isRequired,
  onToggleAcceptTerms: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  text: PropTypes.any.isRequired,
};
