// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: ({hideTopPadding}) => ({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: hideTopPadding ? 0 : theme.spacing(4),
    width: "100%",
  }),
  progressBar: {
    height: theme.spacing(1),
    width: "100%",
    maxWidth: theme.spacing(15),
    marginLeft: theme.spacing(3),
    borderRadius: 100,
    marginTop: "auto",
    marginBottom: "auto",
  }
}));

export default function StepperProgress(props) {
  const {stepIdx, nrOfSteps} = props;
  const progressValue = (stepIdx / nrOfSteps) * 100;
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Typography variant="h6" component="h1">
        Define your marketing requirements
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progressValue}
        className={classes.progressBar}
      />
    </div>

  )
}

StepperProgress.propTypes = {
  stepIdx: PropTypes.number,
  nrOfSteps: PropTypes.number,
};
