// React
import React from "react";
import {Box, InputAdornment, TextField} from "@material-ui/core";

import FieldBlock from "../../FieldBlock";
import {getCurrencySymbol} from "../../../../../utils/formatting";
import {FormControl, FormControlLabel, RadioGroup, Radio} from "@material-ui/core";

export default function HireContractEditor(props) {
  const {monthlyBudget, salesTax, hourlyRate, currency, handleChange, errors} = props;

  return (
    <Box>
      <FieldBlock title="Monthly budget">
        <TextField
          id="monthlyBudget-field"
          variant="outlined"
          fullWidth
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {getCurrencySymbol(currency)}
              </InputAdornment>
            ),
          }}
          value={monthlyBudget || null}
          onChange={e => handleChange("monthlyBudget", parseInt(e.target.value))}
          error={Boolean(errors['monthlyBudget'])}
          helperText={errors['monthlyBudget'] || null}
        />
      </FieldBlock>
      <FieldBlock title="Hourly rate">
        <TextField
          id="hourlyRate-selector"
          variant="outlined"
          fullWidth
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {getCurrencySymbol(currency)}
              </InputAdornment>
            ),
          }}
          value={hourlyRate || ""}
          onChange={e => handleChange("hourlyRate", e.target.value)}
          helperText={errors['hourlyRate'] || null}
          error={Boolean(errors['hourlyRate'])}
        />
      </FieldBlock>
      <FieldBlock title="Sales tax">
        <FormControl component="fieldset">
          <RadioGroup
            name="salesTax-selector"
            value={salesTax ? salesTax.option : null}
            onChange={e => handleChange("salesTax", {...salesTax, option: e.target.value})}
          >
            <FormControlLabel value="none" control={<Radio/>} label="None"/>
            <FormControlLabel value="setTax" control={<Radio/>} label="Add sales tax"/>
            <FormControlLabel value="reverseCharge" control={<Radio/>} label="Reverse charge"/>
            {/*<FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />*/}
          </RadioGroup>
        </FormControl>
      </FieldBlock>
      {(salesTax && salesTax.option === "setTax") && (
        <FieldBlock title="Applicable Sales tax rate">
          <TextField
            id="salesTax-rate-selector"
            variant="outlined"
            fullWidth
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  %
                </InputAdornment>
              ),
            }}
            value={salesTax.rate || ""}
            onChange={e => handleChange("salesTax", {...salesTax, rate: e.target.value})}
            error={Boolean(errors['salesTax.rate'])}
            helperText={Boolean(errors['salesTax.rate']) ? errors['salesTax.rate'] : "Automatically added to all bills"}
          />
        </FieldBlock>
      )}
      {(salesTax && (salesTax.option === "setTax" || salesTax.option === "reverseCharge")) && (
        <FieldBlock title="Sales tax description">
          <TextField
            id="salesTax-type-selection"
            variant="outlined"
            value={salesTax.type || ""}
            onChange={e => handleChange("salesTax", {...salesTax, type: e.target.value})}
            fullWidth
            error={Boolean(errors['salesTax.type'])}
            helperText={Boolean(errors['salesTax.type']) ? errors['salesTax.type'] : 'E.g. "VAT", "GST", "PST"'}
          />
        </FieldBlock>
      )}
    </Box>
  );
}

HireContractEditor.propTypes = {};
