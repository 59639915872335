// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import grey from "@material-ui/core/colors/grey";
import {convertUnixTsToDate} from "../../utils/time";
import StatusLabel from "../../components/StatusLabel";
import {getCurrencyFormat} from "../../utils/formatting";
import {Typography, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  bottomGutter: {
    marginBottom: theme.spacing(2),
  },
  rowLabel: {
    opacity: 0.7,
    paddingBottom: theme.spacing(1),
  },
  skillsContainer: {
    display: 'flex',
  }
}));

export default function RequestTable(props) {
  const {request} = props;
  const classes = useStyles(props);
  return (
    <Grid container spacing={1} className={classes.root}>
      {/*<Grid container spacing={theme.spacing(0.5)}>*/}
      {/*  <Grid item xs={12} sm={12} md={6}>*/}
      <Grid item xs={12} sm={12} md={6} className={classNames(classes.row, classes.bottomGutter)}>
        <Typography className={classes.rowLabel}>Date received</Typography>
        <Typography>{convertUnixTsToDate(request.becameStatusOpenAt.seconds).toLocaleDateString()}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classNames(classes.row, classes.bottomGutter)}>
        <Typography className={classes.rowLabel}>Monthly budget</Typography>
        <Typography>{getCurrencyFormat(request.monthlyBudget, request.currency, {convertToDecimals: true})}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.row}>
        <Typography className={classes.rowLabel}>Skills required</Typography>
        <div className={classes.skillsContainer}>
          {request.skills
            .map(skill =>
              <StatusLabel
                color={grey[700]}
                value={skill}
                bottomGutterSpacing={1}
                rightGutterSpacing={0.5}
                size="small"
              />
            )
          }
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.row}>
        <Typography className={classes.rowLabel}>Additional requirements</Typography>
        <Typography variant="body2">{request.additionalRequirements}</Typography>
      </Grid>
    </Grid>
  )
}

RequestTable.propTypes = {
  request: PropTypes.string.isRequired,
};
