// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {makeStyles, useTheme} from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

// Other libs
import NumberFormat from "react-number-format";
import classNames from "classnames";

// Utils
import {
  getCurrencyFormat,
  getCurrencySymbol,
} from "../../../../utils/formatting";
import Checkbox from "@material-ui/core/Checkbox";
import StatusLabel from "../../../StatusLabel";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "white",
  },
  clickableRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  firstCell: {
    paddingLeft: 30,
  },
  lastCell: {
    paddingRight: 30,
  },
}));

function getClassName(headCells, idx) {
  return idx === 0
    ? "firstCell"
    : idx === headCells.length - 1
      ? "lastCell"
      : "middleCell";
}

function getCellContent(headCell, rowData, isTotalsRow, theme) {
  const {statusColors} = theme.palette;
  if (headCell.isStatusLabel) {
    return (
      <StatusLabel
        value={rowData[headCell.id]}
        color={statusColors[rowData[headCell.id]] || "#696969"}
        size="small"
      />
    );
  }
  if (headCell.isCheckbox) {
    return <Checkbox checked={rowData.isSelected}/>;
  }
  if (headCell.isDate) {
    return (
      <Typography variant={headCell.fontVariant || "caption"}>
        {headCell.showTimeOnly
          ? rowData[headCell.id].toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
          : rowData[headCell.id].toLocaleDateString()}
      </Typography>
    );
  }
  const cellProgressData =
    rowData.cellsWithProgressBar &&
    rowData.cellsWithProgressBar.filter(({id}) => id === headCell.id)[0];
  if (cellProgressData) {
    return (
      <div>
        <div style={{marginBottom: 5}}>
          <LinearProgress
            //className={classes.progress}
            variant="determinate"
            color="primary"
            value={
              cellProgressData.progress > 100 ? 100 : cellProgressData.progress
            }
            style={{maxWidth: 100, minWidth: 100}}
          />
        </div>
        <Typography variant={headCell.fontVariant || "caption"}>
          {/*<NumberFormat*/}
          {/*  decimalScale={0}*/}
          {/*  value={rowData[headCell.id]}*/}
          {/*  displayType={'text'}*/}
          {/*  thousandSeparator={true}*/}
          {/*  prefix={headCell.currency ? getCurrencySymbol(headCell.currency, true) : ''}*/}
          {/*  suffix={headCell.isPercentage ? '%' : ''}*/}
          {/*/>*/}
          {cellProgressData.label}
        </Typography>
      </div>
    );
  }
  return (
    <Typography
      variant={headCell.fontVariant || "caption"}
      noWrap={headCell.noWrap}
    >
      {headCell.isNumeric ? (
        <NumberFormat
          value={
            headCell.currency
              ? getCurrencyFormat(rowData[headCell.id], headCell.currency, {
                convertToDecimals: headCell.convertToDecimals,
              })
              : rowData[headCell.id]
          }
          displayType={"text"}
          thousandSeparator={true}
          prefix={
            headCell.currency ? getCurrencySymbol(headCell.currency, true) : ""
          }
          suffix={headCell.isPercentage ? "%" : ""}
        />
      ) : (
        rowData[headCell.id]
      )}
    </Typography>
  );
}

export default function Row(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {rowData, isTotalsRow, headCells} = props;
  return (
    <TableRow
      className={classNames(
        classes.root,
        rowData.onClick ? classes.clickableRow : undefined
      )}
      key={rowData.id}
      onClick={(event) =>
        rowData.onClick ? rowData.onClick(event, rowData) : undefined
      }
      hover={Boolean(rowData.onClick)}
    >
      {headCells.map((headCell, idx) => (
        <TableCell
          key={headCell.id}
          align={isTotalsRow && idx === 0 ? "right" : headCell.align || "left"}
          className={classes[getClassName(headCells, idx)]}
        >
          {getCellContent(headCell, rowData, isTotalsRow, theme)}
        </TableCell>
      ))}
    </TableRow>
  );
}

Row.propTypes = {
  rowData: PropTypes.object.isRequired,
  headCells: PropTypes.array.isRequired,
  isTotalsRow: PropTypes.bool,
};
