// React
import React from "react";
import PropTypes from "prop-types";

// Components
import SelectorTextfield from "../SelectorTextfield";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(3),
    width: "100%",
    display: "flex",
  },
  iconButtons: {
    // position: "relative",
    display: "flex",
    height: "fit-content",
    marginTop: "auto",
    marginBottom: "auto",
    // top: 5,
  },
  iconButtonClear: {
    marginLeft: 10,
    background: theme.palette.background.light,
  },
  iconButtonCheck: {
    marginLeft: 10,
    background: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
}));

export default function TitleEditor(props) {
  const {
    defaultTitle,
    titlePath,
    titleMaxChar,
    onEditTitleClick,
    editTitleRef,
  } = props;

  const [title, setTitle] = React.useState(defaultTitle || "");
  const [lastSavedTitle, setLastSavedTitle] = React.useState(
    defaultTitle || ""
  );
  const classes = useStyles();

  const handleChange = (prop, value) => {
    setTitle(value ? value.substring(0, titleMaxChar) : "");
  };

  const handleUpdateTitle = () => {
    editTitleRef.update({[titlePath || "title"]: title}).then(() => {
      setLastSavedTitle(title);
      onEditTitleClick();
    });
  };

  const handleResetTitle = () => {
    setLastSavedTitle(lastSavedTitle);
    onEditTitleClick();
  };

  return (
    <div className={classes.root}>
      <SelectorTextfield
        id="title"
        // label="Name"
        value={title}
        variant="standard"
        handleChange={handleChange}
        multiline={false}
        rowsMax={1}
        nrOfRows={1}
        characterLimit={titleMaxChar}
      />
      <div className={classes.iconButtons}>
        <Tooltip title="Save">
          <IconButton
            className={classes.iconButtonCheck}
            onClick={handleUpdateTitle}
            size="small"
          >
            <CheckIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton
            className={classes.iconButtonClear}
            onClick={handleResetTitle}
            size="small"
          >
            <ClearIcon/>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

TitleEditor.propTypes = {
  defaultTitle: PropTypes.string,
  titleMaxChar: PropTypes.number.isRequired,
};
