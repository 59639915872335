//React
import React from "react";
import PropTypes from "prop-types";

// Components
import SettingsPanel from "../SettingsPanel";
import SelectorTextfield from "../SelectorTextfield";

// Other libs
// import {cioTrack} from "../../services/analytics";

export default function AdditionalRequirements(props) {
  const {request, firebaseRef, onPanelChange, isExpanded, draftMode} = props;
  const [additionalRequirements, setAdditionalRequirements] = React.useState(request.additionalRequirements || "");
  const [isSaving, setIsSaving] = React.useState(false);

  const handleChange = (prop, value) => setAdditionalRequirements(value);

  const handleCancel = () => {
    setAdditionalRequirements(request.additionalRequirements);
    onPanelChange && onPanelChange();
  };

  const handleSave = () => {
    setIsSaving(true);
    firebaseRef
      .update({additionalRequirements: additionalRequirements || "None"})
      .then(() => {
        // if (draftMode) {
        //   handleSubmit()
        // }
        // else {
        //   setIsSaving(false);
        //   setLastSavedRequirements(additionalRequirements);
        //   onPanelChange && onPanelChange();
        // }
      });
  };

  return (
    <SettingsPanel
      id="additional-requirements-panel"
      expanded={isExpanded}
      handlePanelChange={onPanelChange}
      label="Additional requirements"
      expandedText="[Optional] Enter any additional freelancer requirements"
      collapsedText={additionalRequirements}
      onCancel={handleCancel}
      onSave={handleSave}
      isSaving={isSaving}
      unsavedChanges={additionalRequirements !== request.additionalRequirements}
      draftMode={draftMode}
      skippable
    >
      <SelectorTextfield
        id="skill-requirements-textfield"
        label="Additional requirements"
        value={additionalRequirements}
        formHelperText="Number of freelancers required, country of residence, maximum hourly rates, etc."
        handleChange={handleChange}
        multiline={true}
        rowsMax={10}
        nrOfRows={5}
        characterLimit={1000}
      />
    </SettingsPanel>
  );
}

AdditionalRequirements.propTypes = {
  request: PropTypes.object.isRequired,
  firebaseRef: PropTypes.object.isRequired,
  onPanelChange: PropTypes.func,
  isExpanded: PropTypes.bool.isRequired,
  draftMode: PropTypes.bool,
};
