// React
import React from "react";
import PropTypes from "prop-types";

// MUI
import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import {Receipt} from "@material-ui/icons";
import {useTheme, makeStyles} from "@material-ui/core/styles";
import {getCurrencyFormat} from "../../utils/formatting";
import EmptyStateView from "../EmptyStateView";
import StatusLabel from "../StatusLabel";
import InvoiceDownloadButton from "../Invoice/InvoiceDownloadButton";
import * as routes from "../../constants/routes";
import Link from "../Link";
import {maxerBillingDetails} from "../../constants/settings";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    width: '100%',
    marginTop: topGutterSpacing ? theme.spacing(topGutterSpacing) : 0,
    marginBottom: bottomGutterSpacing ? theme.spacing(bottomGutterSpacing) : 0,
  }),
}));

export default function InvoicesTable(props) {
  const {rows} = props;
  const classes = useStyles(props);
  const theme = useTheme();

  if (rows.length === 0) {
    return (
      <EmptyStateView
        text="No invoices created yet"
        icon={<Receipt/>}
        topSpacing={10}
      />
    )
  }

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {["Bill", "Type", "Date", "Amount", "Invoice"]
              .map(label => <TableCell key={label} className={classes.tableCell}>{label}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {
            const {billId, type, paymentRequestedDate, currency, totalAmount, bill, sessions} = row;
            return (
              <TableRow
                key={`session-${idx}`}
                className={classes.tableRow}
              >
                <TableCell component="th" scope="row">
                  <Link
                    color="primary"
                    to={routes.freelancerBill.replace(":billId", bill.uid)}
                  >
                    {bill.title || billId}
                  </Link>
                </TableCell>
                <TableCell>
                  <StatusLabel
                    shape="square"
                    color={theme.palette.statusColors[type]}
                    value={type}
                  />
                </TableCell>
                <TableCell>
                  {paymentRequestedDate.toLocaleDateString()}
                </TableCell>
                <TableCell>{getCurrencyFormat(totalAmount, currency)}</TableCell>
                <TableCell>
                  <InvoiceDownloadButton
                    issuedFromDetails={type === "earnings" ? bill.freelancerBillingDetails : maxerBillingDetails}
                    issuedToDetails={type === "earnings" ? bill.clientBillingDetails : bill.freelancerBillingDetails}
                    bill={bill}
                    sessions={sessions}
                    feeType={type}
                    buttonText="Download"
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

InvoicesTable.propTypes = {
  rows: PropTypes.array.isRequired,
};
