import axios from "axios";
import {adminApi} from "../constants/apiRoutes";

export function createAuthToken(ownerId, idToken) {
  return new Promise((resolve, reject) => {
    const payload = {
      auth_token_uid: ownerId,
      id_token: idToken,
    };
    return axios
      .post(`${adminApi}/create-auth-token`, payload)
      .then((result) => resolve(result.data))
      .catch((error) => reject(error));
  });
}
