// React
import React from "react";
import {navigate} from "gatsby";
import {useLocation} from "@reach/router";

// Components
import {AuthUserContext} from "../../components/Session";
import LoadingIndicator from "../../components/LoadingIndicator";

// Constants
import * as routes from "../../constants/routes";

// Other libs
const queryString = require("query-string");

// This route is the starting point for any session after logging in
// It redirects to the right starting page depending on conditions

export default function Start() {

  const authUser = React.useContext(AuthUserContext);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  React.useEffect(() => {
    if (authUser.role === "admin") {
      return navigate(routes.adminUsers)
    }
    if (authUser.role === "freelancer") {
      const freelancerStartRoute = Boolean(authUser.status === "profileIncomplete")
        ? routes.freelancerProfileSetup
        : routes.freelancerTeams;
      return navigate(freelancerStartRoute);
    }
    if (authUser.lastVisitedBusiness) {
      parsedQueries.business = authUser.lastVisitedBusiness;
      const redirectUrl = queryString.stringifyUrl({
        url: routes.clientBills,
        query: parsedQueries,
      });
      return navigate(redirectUrl)
    }
    return navigate(routes.clientCreateBusiness)
  }, [authUser, parsedQueries]);

  return <LoadingIndicator message="Signing in..."/>;
}
