// React
import React from "react";
import PropTypes from "prop-types";

// Components
import EnhancedHead from "./EnhancedHead";
import EnhancedToolbar from "./EnhancedToolbar";
import TableList from "./TableList";
import EmptyStateView from "../EmptyStateView";

// Utils
import {stableSort, getSorting} from "../../utils/sorting";

// Material UI
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import List from "@material-ui/icons/List";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableContainer: ({hasTopBorder}) => ({
    // overflowX: 'auto',
    borderTop: hasTopBorder ? `1px solid ${theme.palette.borders}` : "none",
  }),
  emptyStateContainer: {
    background: "white",
    borderBottom: `1px solid ${theme.palette.borders}`,
  },
}));

export default function MainTable(props) {
  const {
    headCells,
    rows,
    totalsRow,
    defaultRowsPerPage,
    emptyStateText,
    defaultOrder,
    defaultOrderBy,
    inactiveToolbarComp,
    toolbarActionButton,
    numSelected,
    hideRowsPerPage
  } = props;
  const [order, setOrder] = React.useState(defaultOrder || "desc");
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy || null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage || 5);
  const classes = useStyles(props);

  const handleSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowsToCreate = stableSort(rows, getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className={classes.root}>
      {numSelected >= 0 && (
        <EnhancedToolbar
          numSelected={numSelected}
          inactiveComp={inactiveToolbarComp}
          actionButton={toolbarActionButton}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table size="medium">
          <EnhancedHead
            cells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleSort}
            rowCount={rows.length}
            numSelected={numSelected}
          />
          <TableList
            rows={rowsToCreate}
            totalsRow={totalsRow}
            headCells={headCells}
          />
        </Table>
      </TableContainer>
      {rowsToCreate.length === 0 && (
        <div className={classes.emptyStateContainer}>
          <EmptyStateView text={emptyStateText} icon={<List/>} size="small"/>
        </div>
      )}
      {!hideRowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

MainTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  emptyStateText: PropTypes.string.isRequired,
  numSelected: PropTypes.number,
  handleSelectAllClick: PropTypes.func,
};
