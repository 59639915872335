// React
import React from "react";
import PropTypes from "prop-types";

// Context
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {
  AuthUserContext
} from "../../components/Session";

// Services
// import {cioTrack} from "../../services/analytics";

// Components
import BodyContainer from "../../components/BodyContainer";
import CreateBusinessHeader from "./CreateBusinessHeader";

// Constants
// import * as routes from "../../../constants/routes";

// Other libs
// import queryString from "query-string";
import {BusinessNamePanel, CurrencyPanel} from "../../components/SettingsPanels";
import CountryPanel from "../../components/SettingsPanels/CountryPanel";
import {ArrowForward} from "@material-ui/icons";
// import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import * as routes from "../../constants/routes";
import queryString from "query-string";
import {Redirect} from "@reach/router";
import LoadingIndicator from "../../components/LoadingIndicator";
import PageNotFound from "../../components/PageNotFound";

function getStepIdx(business) {
  const {name, country, currency} = business;
  if (!name) {
    return 0;
  }
  if (!country) {
    return 1
  }
  if (!currency) {
    return 2;
  }
  return 3;
}

export default function CreateBusiness(props) {
  const [creatingBusiness, setCreatingBusiness] = React.useState(false);
  const [expanded, setExpanded] = React.useState({
    name: false,
    country: false,
    currency: false,
  });

  const [stepIdx, setStepIdx] = React.useState(0);
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  const businessesRef = firebase.businesses()
    .where(`ownerId`, "==", authUser.uid)
  const [business, loadingBusiness] = useFetchFirestore(
    businessesRef,
    { 
      type: 'collection', 
      listen: true, 
      transformResult: r => r[0]
    }
  )
  
  React.useEffect(() => {
    if (business) {
      setStepIdx(getStepIdx(business));
    }
  }, [business]);

  React.useEffect(() => {
    // authUser.role should be there (if not then user doc is not yet created)
    if (authUser.role && !business && !loadingBusiness && !creatingBusiness) {
      setCreatingBusiness(true);
      const ts = firebase.createTimestamp();
      const payload = {
        name: "",
        status: "draft",
        ownerId: authUser.uid,
        createdAt: ts,
        members: {
          [authUser.uid]: {
            createdAt: ts,
            userId: authUser.uid,
            permissionLevel: "admin",
          }
        }
      };
      firebase
        .businesses()
        .add(payload)
        .then(() => {
          setCreatingBusiness(false);
        });
    }
  }, [firebase, loadingBusiness, business, authUser.role, authUser.uid, creatingBusiness]);

  const handleComplete = () => {
    const ts = firebase.createTimestamp();
    firebase
      .business(business.uid)
      .update({
        uid: business.uid,
        lastUpdatedAt: ts,
        status: "notBillable",
      })
    // .then(() =>
    //   cioTrack({
    //     name: "project_saved",
    //     ...businessUpdates,
    //   })
    // );x
  };

  const handlePanelChange = (panel) => (event, value) => {
    setExpanded({
      ...expanded,
      [panel]: value,
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BusinessNamePanel
            firebaseRef={firebase.business(business.uid)}
            value={business.name || ""}
            isExpanded
            draftMode
          />
        );
      case 1:
        return (
          <CountryPanel
            firebaseRef={firebase.business(business.uid)}
            value={business.country}
            isExpanded
            canMakeEdits
            draftMode
          />
        );
      case 2:
        return (
          <CurrencyPanel
            firebaseRef={firebase.business(business.uid)}
            value={business.currency}
            isExpanded
            canMakeEdits
            draftMode
          />
        );
      case 3:
        return (
          <div>
            <BusinessNamePanel
              firebaseRef={firebase.business(business.uid)}
              value={business.name || ""}
              isExpanded={expanded.name}
              handlePanelChange={handlePanelChange("name")}
            />
            <CountryPanel
              firebaseRef={firebase.business(business.uid)}
              value={business.country}
              isExpanded={expanded.country}
              handlePanelChange={handlePanelChange("country")}
              canMakeEdits
            />
            <CurrencyPanel
              firebaseRef={firebase.business(business.uid)}
              value={business.currency}
              isExpanded={expanded.currency}
              handlePanelChange={handlePanelChange("currency")}
              canMakeEdits
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleComplete}
              style={{
                float: "right",
                marginTop: "32px",
              }}
              endIcon={<ArrowForward/>}
            >
              Continue
            </Button>
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  if (business && business.status !== "draft") {
    const query = {business: business.uid};
    const businessUrl = queryString.stringifyUrl({
      url: routes.clientTeamsRequest.replace(":requestId", "new"),
      query,
    });
    return <Redirect to={businessUrl} noThrow/>;
  }

  if (loadingBusiness || creatingBusiness) {
    return <LoadingIndicator message="Starting business creator..." inheritHeight topSpacing={10}/>
  } else if (!business) {
    return <PageNotFound/>
  }

  let nrOfSteps = 4;
  const isLastStep = nrOfSteps === stepIdx + 1;
  return (
    <BodyContainer topGutterSpacing={4} bottomGutter>
      <CreateBusinessHeader
        stepIdx={stepIdx}
        nrOfSteps={nrOfSteps}
        text={isLastStep ? "Business overview" : "Add business details"}
      />
      {getStepContent(stepIdx)}
    </BodyContainer>
  );
}

CreateBusiness.propTypes = {
  uid: PropTypes.string,
};