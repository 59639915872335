// React
import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

// Components
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import {AuthUserContext} from "../../components/Session";
import LoadingIndicator from "../../components/LoadingIndicator";
import BodyContainer from "../../components/BodyContainer";
import ContractCardItem from "./ContractCardItem";
import EmptyStateView from "../../components/EmptyStateView";

// Material UI
import {Grid, Typography, Button, CircularProgress} from "@material-ui/core";
import {ArrowForward, Receipt} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

// Other
import queryString from "query-string";
import * as routes from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  continueBtn: {
    marginTop: theme.spacing(3),
  },
}));

export default function FreelancerBillCreateNewPage() {
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const classes = useStyles();

  const [creatingBill, setCreatingBill] = React.useState(Boolean(parsedQueries.hirecontract));

  const hireContractsRef = parsedQueries.hirecontract ? null : (
    firebase.hireContracts()
      .where(`freelancerId`, "==", authUser.uid)
      .where("status", "==", "active")
  )
  const [hireContracts, loadingHireContracts] = useFetchFirestore(
    hireContractsRef,
    {type: "collection"}
  );
  const selectedHireContractRef = parsedQueries.hirecontract 
    ? firebase.hireContract(parsedQueries.hirecontract)
    : null;
  const [selectedHireContract, loadingSelectedHireContract] = useFetchFirestore(selectedHireContractRef);
  const [selectedCard, setSelectedCard] = React.useState(null);

  React.useEffect(() => {
    if (selectedHireContract && !creatingBill) {
      const isHcFreelancer = authUser.uid === selectedHireContract.freelancerId;
      const isActiveHc = selectedHireContract.status === "active";

      // Only allow new bills originating from:
      //  1. Hire contracts owned by authUser;
      //  2. Where the status of the hireContract is active
      if (isHcFreelancer && isActiveHc) {
        setCreatingBill(true);

        firebase.bills()
          .where("ownerId", "==", authUser.uid)
          .where("status", "==", "draft")
          .where("hireContractId", "==", selectedHireContract.uid)
          .get()
          .then(snap => {
            const existingDraftHc = firebase.formatSnapshot(snap)[0];
            if (existingDraftHc) {
              return navigate(routes.freelancerBill.replace(":billId", existingDraftHc.uid))
            } else {
              const ts = firebase.createTimestamp();
              const payload = {
                createdAt: ts,
                lastUpdatedAt: ts,
                status: "draft",
                contractId: selectedHireContract.contractId,
                hireContractId: selectedHireContract.uid,
                teamId: selectedHireContract.teamId,
                businessId: selectedHireContract.businessId,
                currency: selectedHireContract.currency,
                ownerId: authUser.uid,
                sessionsExcluded: [],
                amountExcl: 0,
                hourlyRate: selectedHireContract.hourlyRate,
                salesTax: selectedHireContract.salesTax,
                serviceFee: selectedHireContract.serviceFee,
                transactionFee: selectedHireContract.transactionFee,
                totalAmount: 0,
              };
              firebase
                .bills()
                .add(payload)
                .then((docRef) => 
                  navigate(routes.freelancerBill.replace(":billId", docRef.id))
                );
            }
          })
        
      } else {
        // Set error
      }
    } else if (!selectedHireContract && !loadingSelectedHireContract) {
      setCreatingBill(false);
      // Set error 
    }
  }, [firebase, authUser.uid, loadingSelectedHireContract, selectedHireContract, parsedQueries.hirecontract, creatingBill])

  const handleChange = (hireContractId) => setSelectedCard(hireContractId);

  const handleContinueClick = () => {
    parsedQueries.hirecontract = selectedCard;
    const createBillUrl = queryString.stringifyUrl({
      url: routes.freelancerBillCreateNew,
      query: parsedQueries,
    })
    return navigate(createBillUrl)
  };

  if (loadingHireContracts) {
    return (
      <LoadingIndicator
        message="Loading active contracts..."
        inheritHeight
        topSpacing={10}
      />
    );
  } else if (hireContracts.length === 0) {
    return (
      <EmptyStateView
        text="You need an active hire contract to add bills"
        icon={<Receipt/>}
        topSpacing={10}
      />
    )
  }

  return (
    <BodyContainer topGutterSpacing={4} bottomGutter>
      <Typography
        variant="h6"
        component="h1"
        style={{marginBottom: 24}}
      >
        Select the contract applicable to your bill
      </Typography>
      <Grid container spacing={6}>
        {hireContracts.map((hc) => (
          <Grid item xs={12} sm={12} md={6} key={hc.uid}>
            <ContractCardItem
              handleClick={() => handleChange(hc.uid)}
              isSelected={selectedCard === hc.uid}
              disabled={creatingBill}
              {...hc}
            />
          </Grid>
        ))}
      </Grid>
      <Button
        endIcon={creatingBill ? <CircularProgress size={15} /> : <ArrowForward/>}
        variant="contained"
        color="primary"
        disabled={!selectedCard || creatingBill}
        size="large"
        onClick={handleContinueClick}
        className={classes.continueBtn}
      >
        Continue
      </Button>
    </BodyContainer>
  );
}

FreelancerBillCreateNewPage.propTypes = {
  match: PropTypes.object,
};
