// React
import React from "react";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useFieldBlockStyles = makeStyles((theme) => ({
  root: ({ topGutterSpacing }) => ({
    marginTop: theme.spacing(topGutterSpacing || 0),
    marginBottom: theme.spacing(2),
  }),
  title: {
    opacity: 0.65,
    marginBottom: theme.spacing(1),
  },
}));

export default function FieldBlock(props) {
  const {children, title} = props;
  const classes = useFieldBlockStyles(props);
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </Box>
  )
}

FieldBlock.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};