import {paymentsApi} from "../../constants/apiRoutes";

const defaultOptions = {
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
  referrer: "no-referrer",
};

export function authorizeStripeCode(code) {
  return new Promise((resolve, reject) => {
    fetch(`${paymentsApi}/connect/authorize/${code}`, {
      ...defaultOptions,
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => resolve(result));
  });
}

export function getStripeAccount(stripeAccountId) {
  return new Promise((resolve, reject) => {
    fetch(`${paymentsApi}/connect/accounts/${stripeAccountId}`, {
      ...defaultOptions,
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => resolve(result));
  });
}

export function updateStripeAccount(stripeAccountId, data) {
  return new Promise((resolve, reject) => {
    fetch(`${paymentsApi}/connect/accounts/${stripeAccountId}`, {
      ...defaultOptions,
      method: "POST",
      body: JSON.stringify({
        id: stripeAccountId,
        update: data,
      }),
    })
      .then((response) => response.json())
      .then((result) => resolve(result));
  });
}

export function createStripeLoginLink(stripeAccountId) {
  return new Promise((resolve, reject) => {
    fetch(`${paymentsApi}/connect/accounts/${stripeAccountId}/login-link`, {
      ...defaultOptions,
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => resolve(result));
  });
}
