// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ConfirmToolbar from "../../../components/ConfirmToolbar";

const useStyles = makeStyles((theme) => ({
  root: ({removable}) => ({
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: removable ? "space-between" : "flex-end",
    width: "100%",
    minHeight: 48,
  }),
  alignVertically: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  deleteButton: {
    color: theme.palette.error.main,
  }
}));

export default function SessionActionButtons(props) {
  const {
    onSave,
    saveDisabled,
    removable,
    onRemove,
    onCancel,
  } = props;
  const [confirmRemoval, setConfirmRemoval] = React.useState(false);

  const classes = useStyles(props);

  const handleConfirmRemoval = () => setConfirmRemoval(true);
  const handleCancelRemoval = () => setConfirmRemoval(false);

  return (
    <div className={classes.root}>
      {removable && !confirmRemoval && (
        <div className={classes.alignVertically}>
          <Button
            className={classes.deleteButton}
            onClick={handleConfirmRemoval}
            size="large"
          >
            Delete session
          </Button>
        </div>
      )}
      {confirmRemoval ? (
        <ConfirmToolbar
          onConfirm={onRemove}
          confirmButtonText="Delete"
          onCancel={handleCancelRemoval}
          variant="dense"
          disableGutters
        />
      ) : (
        <div className={classes.alignVertically}>
          <Button
            className={classes.button}
            onClick={onCancel}
            size="large"
          >
            Cancel
          </Button>
          <Button
            className={classes.button}
            color="primary"
            onClick={onSave}
            size="large"
            disabled={saveDisabled}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

SessionActionButtons.propTypes = {
  onSave: PropTypes.func.isRequired,
  amount: PropTypes.number,
};
