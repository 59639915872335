// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {Tab, Tabs} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

function getTabs() {
  return [
    {
      label: "My contracts",
      value: "hire",
    },
    {
      label: "Team contracts",
      value: "team",
    },
  ];
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  tabs: {
    // width: "100%",
    // display: "flex",
    // justifyContent: "space-between",
    // padding: "0px 30px 0px 30px",
    // borderBottom: `1px solid ${theme.palette.borders}`,
  },
  tabRoot: {
    width: 150,
    minWidth: 150,
  },
  wrapper: {
    // height: 45,
    width: 125,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function ContractsTabs(props) {
  const {activeTab, onToggleTab} = props;
  const classes = useStyles(props);

  return (
    <Tabs
      value={activeTab}
      onChange={onToggleTab}
      classes={{
        root: classes.root,
        indicator: classes.indicator,
        tabs: classes.tabs,
      }}
    >
      {getTabs()
        .map(({label, value}) =>
          <Tab
            key={value}
            value={value}
            label={label}
            color="primary"
            classes={{
              root: classes.tabRoot,
              wrapper: classes.wrapper,
            }}
            className={classes.tab}
          />
        )
      }
    </Tabs>
  );
}

ContractsTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onToggleTab: PropTypes.func.isRequired,
};
