// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import StatusLabel from "../../components/StatusLabel";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: ({hideTopPadding}) => ({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: hideTopPadding ? 0 : theme.spacing(4),
    width: "100%",
  }),
  progressBar: {
    height: theme.spacing(1),
    width: "100%",
    maxWidth: theme.spacing(15),
    marginLeft: theme.spacing(3),
    borderRadius: 100,
    marginTop: "auto",
    marginBottom: "auto",
  }
}));

export default function StatusHeader(props) {
  const {status, headerText} = props;
  const classes = useStyles(props);
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <Typography variant="h6" component="h1">
        {headerText}
      </Typography>
      <StatusLabel
        value={status}
        shape="square"
        color={theme.palette.statusColors[status]}
      />
    </div>
  )
}

StatusHeader.propTypes = {
  status: PropTypes.string.isRequired,
};
