// React
import React from "react";
import FreelancerDialog from "../../../../FreelancerDialog";
import HireContractEditor from "./HireContractEditor";
import {Box, Button} from "@material-ui/core";

export default function HireContractDialog(props) {
  const {hireContract, freelancerId, handleClose, errors, currency, handleUpdate } = props;


  console.log("HireContractDialog hireContract", hireContract);


  const [editedContract, setEditedContract] = React.useState(hireContract);

  const handleEditContract = (prop, value) => {
    console.log("vvv handleEditContract",  { prop, value })
    setEditedContract(ec => ({
      ...ec,
      [prop]: value
    }));
  }

  console.log("vvv editedContract", editedContract);

  const handleCancel = () => {
    setEditedContract(hireContract);
    handleClose()
  };

  return (
    <FreelancerDialog
      freelancer={freelancerId}
      handleClose={handleClose}
      DialogBodyContent={
        <HireContractEditor
          {...editedContract}
          errors={errors}
          currency={currency}
          handleChange={(prop, value) => handleEditContract(prop, value)}
        />
      }
      DialogActionsContent={
        <Box>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button 
            color="primary" 
            onClick={() => handleUpdate(editedContract)}
          >
            Update contract
          </Button>
        </Box>
      }
    />
  );
}

HireContractDialog.propTypes = {};
