// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BodyContainer from "../../components/BodyContainer";
import LoadingIndicator from "../../components/LoadingIndicator";
import Contract from "../../components/Contract";

import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";

// Material UI
import {Typography} from "@material-ui/core";

export default function FreelancerContractTeamPage(props) {
  const {match} = props;
  const firebase = React.useContext(FirebaseContext);

  const [contract, loadingContract] = useFetchFirestore(
    match.contractId ? firebase.contract(match.contractId) : null, {listen: true, stopLoading: !match.contractId}
  );
  const [business, loadingBusiness] = useFetchFirestore(
    contract ? firebase.business(contract.businessId) : null, {stopLoading: !loadingContract && !contract}
  );
  const [client, loadingClient] = useFetchFirestore(
    business ? firebase.user(business.ownerId) : null, {stopLoading: !loadingBusiness && !business}
  );

  if (loadingContract || loadingBusiness || loadingClient) {
    return (
      <BodyContainer bottomGutter topGutter>
        <LoadingIndicator 
          message="Loading contract..." 
          inheritHeight 
          topSpacing={10}
        />
      </BodyContainer>
    )
  } else if (!contract || !business || !client) {
    return (
      <BodyContainer bottomGutter topGutter>
        <Typography>
          Something went wrong. Try refreshing your browser window. If the problem persists then please
          contact support
        </Typography>
      </BodyContainer>
    )
  }

  return (
    <BodyContainer bottomGutter topGutter>
      <Contract
        contractId={match.contractId}
        business={business}
        client={client}
      />
    </BodyContainer>
  )
}

FreelancerContractTeamPage.propTypes = {
  match: PropTypes.object.isRequired,
};
